

/********************************************************************************************************
    공통으로 사용되는 Element
********************************************************************************************************/

/* 변수 선언 */
:root {
    --mobile-icon-active-bg: #eeeeee90; /* 아이콘 버튼 선택 시 배경*/
    --mobile-box-border:#ccc;
    --mobile-box-inner-border:#eee;

    /********************************************* 
        폰트 사이즈 
    *********************************************/

    --m-f-size-header-logo: 25px; /* 해더 로고 */
    --m-f-size-header-sub-logo: 20px; /* 해더 서브 로고 */
    --m-f-size-header-sub-title: 18px; /* 해더 서브 타이틀 */

    /** End - 폰트 사이즈 
    *********************************************/

    /********************************************* 
        크기&마진
    *********************************************/
    --m-margin-side: 20px; /* 내용 박스 타이틀 왼쪽 마진 */
}
.icon_btn{position: relative; }
.icon_btn:active{background-color: var(--mobile-icon-active-bg); border-radius: 70px;}
.icon_btn > .icon { position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%); width: 30px; height: 30px; }

.mobile_body_div{ width: 100%; height: 100%; background-color: #eee;}
.mobile_body_div > .mobile_middle{ width: 100%; height: fit-content;}


.mobile_bottom_company_info { width: 100%; color: #000; background-color: #fff; padding-bottom: 20px;}
.mobile_bottom_company_info > .divider { width: 100%; height: 1px; background-color: #ccc; margin-top: 10px;}
.mobile_bottom_company_info > .top{ width: 100%; text-align: center; font-size: 13px;margin-top: 20px;}
.mobile_bottom_company_info > .top > .link{ font-weight: 600;}
.mobile_bottom_company_info > .top > .link:active{ background-color: #fafafa}
.mobile_bottom_company_info > .top > .dot{ margin-left: 5px; margin-right: 5px;}
.mobile_bottom_company_info > .info{ width: 100%; text-align: center; font-size: 13px;margin-top: 20px;}
.mobile_bottom_company_info > .copyright{ text-align: center; font-size: 13px;margin-top: 20px;}
.mobile_bottom_company_info > .copyright > .red{color: #f00;}


.mobile__post_page_area { width: 100%; text-align: center; height: 60px;  position: relative; background-color: #fff;}
.mobile__post_page_area > div { display: inline-block; padding-left: 10px; padding-right: 10px;}
.mobile__post_page_area > div:active{background-color: #fafafa;}
.mobile__post_page_area > div > span { color: #000; font-size: 15px; line-height: 60px;}
.mobile__post_page_area > .before {float: left;}
.mobile__post_page_area > .before > .icon{float: left; width: 20px; height: 20px; margin-top: 20px; margin-right: 5px;}
.mobile__post_page_area > .next {float: right;}
.mobile__post_page_area > .next > .icon{float: right; width: 20px; height: 20px; margin-top: 20px; margin-left: 5px;}
.mobile__post_page_area > .disable > span{color: #ccc;}
.mobile__post_page_area > .disable > .icon{color: #ccc;}

.mobile__post_top_page_area {float: right;}
.mobile__post_top_page_area > div{float: right; height: 40px; width: 40px;}
.mobile__post_top_page_area > div:active{background-color: #fafafa;}
.mobile__post_top_page_area > div > .icon{margin-top: 10px; margin-left: 10px; width: 20px; height: 20px;}
.mobile__post_top_page_area > .disable {color: #aaa;}


.mobile_category_box {width: 100%; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); background-color: #fff; margin-top: 10px;}
.mobile_category_box > .title {height: 50px; line-height: 50px; padding-left: var(--m-margin-side); font-size: 15px; font-weight: 600; border-bottom: 1px solid var(--mobile-box-inner-border)}
.mobile_category_box > .item { width: 100%; font-size: 13px;border-spacing: 0;}
.mobile_category_box > .item > tr { height: 40px; width: 100%;}
.mobile_category_box > .item > tr > td {border-right: 1px solid var(--mobile-box-inner-border); border-bottom: 1px solid var(--mobile-box-inner-border); width: 25%; height: 40px; text-align: center; padding-left: 5px; padding-right: 5px; }
.mobile_category_box > .item > tr > td:active{background-color: var(--mobile-icon-active-bg);}
.mobile_category_box > .item > tr > .select {color: #f00;}
.mobile_category_box > .item > tr > .empty {color: #ccc;}

.mobile_notice_box { margin-bottom: 10px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_notice_box > .title { height: 50px; border-bottom: 1px solid var(--mobile-box-inner-border); line-height: 50px;}
.mobile_notice_box > .title > span { margin-left: var(--m-margin-side); font-weight: 600; font-size: 15px; color: #000;}
.mobile_notice_box > .title > .right_area {float: right; margin-right: 20px; text-decoration: underline; }
.mobile_notice_box > ul > li{ height: 50px; line-height: 50px; border-bottom: 1px solid var(--mobile-box-inner-border); padding-left: 20px;}
.mobile_notice_box > ul > li:active{ background-color: #fafafa;}
.mobile_notice_box > ul > li > div{width: 100%; position: relative;}
.mobile_notice_box > ul > li > div > .title{font-size: 15px; width: calc(100% - 95px);}
.mobile_notice_box > ul > li > div > .date{font-size: 14px; position: absolute;top: 0; right: 20px;}
/**
    End - 공통으로 사용되는 Element
********************************************************************************************************/


/********************************************************************************************************
    헤더 탭 부분
********************************************************************************************************/
.mobile_body_div > .mobile_header{ width: 100%; height: 70px; background-color: #fff; border-bottom: 1px solid var(--mobile-box-border); position: relative;}
.mobile_body_div > .mobile_header > .logo { width: 100%; font-family: "LogoFont"; height: 70px;line-height: 70px; font-size: var(--m-f-size-header-logo); text-align: center; }
.mobile_body_div > .mobile_header > .logo > span {height: 70px;line-height: 70px;}
.mobile_body_div > .mobile_header > .logo_left { height: 70px;line-height: 70px; position: relative; }
.mobile_body_div > .mobile_header > .logo_left > .logo { font-family: "LogoFont"; font-size: var(--m-f-size-header-sub-logo); margin-left: 20px; top:2px; position: absolute;}
.mobile_body_div > .mobile_header > .logo_left > .sub { font-size: var(--m-f-size-header-sub-title); margin-left: 95px;  position: absolute; margin-top: 1px;}
.mobile_body_div > .mobile_header > .user {position: absolute; width: 70px; height: 69px; top:0; right: 0; color: #f00;}
.mobile_body_div > .mobile_header > .user_disable { color: #000;}
.mobile_body_div > .mobile_tab{ height: 60px; border-bottom: 1px solid var(--mobile-box-border); background-color: #fff; padding-left: 15px; }
.mobile_body_div > .mobile_tab > .item{ float: left; height: 60px; line-height: 60px; text-align: center; color: #000; font-size: 17px; padding-left: 5px; padding-right: 5px; margin-left: 5px; margin-right: 5px;}
.mobile_body_div > .mobile_tab > .item:active{background-color: var(--mobile-icon-active-bg); border-radius: 70px;}
.mobile_body_div > .mobile_tab > .item > .icon { position: relative; height: 100%; width: 30px; text-align: center; }
.mobile_body_div > .mobile_tab > .item > .icon > svg { position: absolute; top:17px; left:50%; transform: translate(-50%, 0);}

.mobile_body_div > .mobile_tab > .select{color: #f00; font-weight: 600; border-bottom: 5px solid #f00;}
.mobile_body_div > .mobile_tab > .select:active{background-color: #00000000; border-radius: 0;}
.mobile_body_div > .mobile_tab > .write_btn{ float: right; height: 60px; width: 40px; }
.mobile_body_div > .mobile_tab > .search_btn{ float: right; height: 60px; width: 40px; margin-right: 10px; }

.mobile_body_div > .mobile_tab > .header_search_area{height: 61px; line-height: 60px; width:calc(100% + 15px); margin-left: -15px; padding-left: 10px; position: relative; border-bottom: 2px solid #f00; }
.mobile_body_div > .mobile_tab > .header_search_area > .select{float: right; width: 70px; height: 100%; font-size: 14px; border:0; color:#000; background-color: #ffffff00; outline: none; padding-left: 5px; }
.mobile_body_div > .mobile_tab > .header_search_area > .divider{float: right; height: 20px; width: 1px; margin-top: 20px; margin-right: 8px; background-color: var(--mobile-box-inner-border);}
.mobile_body_div > .mobile_tab > .header_search_area > .search_input{ float: left; border: 0; font-size: 17px; height: 100%; width:calc(100% - 60px - 125px); top:0; background-color: #00000000; left:80px;outline: none;}
.mobile_body_div > .mobile_tab > .header_search_area > .search_input::placeholder{color: #ccc;}
.mobile_body_div > .mobile_tab > .header_search_area > .search_btn_area{ float: right; height: 60px; width: 40px; margin-right: 10px;}
.mobile_body_div > .mobile_tab > .header_search_area > .search_btn_area > .icon {color: #F00; margin-top: -10px;}
.mobile_body_div > .mobile_tab > .header_search_area > .close_btn_area{ float: left; height: 60px; width: 40px; }
.mobile_body_div > .mobile_tab > .header_search_area > .close_btn_area > .icon {color: #F00; margin-top: -10px;}

/**
    End - 헤더 탭 부분
********************************************************************************************************/

/********************************************************************************************************
    탑 배너
********************************************************************************************************/
.mobile_middle > .top_banner{ width: 100%; position: relative; margin-bottom: 10px; margin-top: 10px;}
.mobile_middle > .top_banner > img{ width: 100%; position:absolute; border-radius: 5px; }
.mobile_middle > .top_banner > div{ width: 100%; border: 1px solid #ddd; border-radius: 5px; position:absolute; cursor: pointer;}
.mobile_middle > .top_banner > div:hover{background-color: #00000010;}


.mobile_search_top {height: 50px; line-height: 50px; background-color: #fff; margin-top: 10px; border-top: 1px solid var(--mobile-box-border); font-size: 15px; padding-left: 10px;}

/********************************************************************************************************
    메인화면
********************************************************************************************************/
.mobile_middle > .main_best { margin-bottom: 10px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_middle > .main_best > .title { height: 50px; border-bottom: 1px solid var(--mobile-box-inner-border); line-height: 50px;}
.mobile_middle > .main_best > .title > span { margin-left: var(--m-margin-side); font-weight: 600; font-size: 15px; color: #000;}
.mobile_middle > .main_best > ul > li{ height: 60px; border-bottom: 1px solid var(--mobile-box-inner-border); padding-left: 20px;}
.mobile_middle > .main_best > ul > li:active{ background-color: #fafafa;}
.mobile_middle > .main_best > ul > li > div {float: left; line-height: 60px; font-size: 15px;}
.mobile_middle > .main_best > ul > li > .body_image{ float: left; width: 40px; height: 40px; margin-top: 10px; margin-right: 10px;}
.mobile_middle > .main_best > ul > li > .choice_image{ float: left; width: 20px; height: 20px; margin-top: 20px; margin-left: 5px;}
.mobile_middle > .main_best > ul > li > .best_body_div{ overflow:hidden; white-space:nowrap; text-overflow:ellipsis; margin-right: 5px; }
.mobile_middle > .main_best > ul > li > .best_body_div > .cate_name{ color: #777;}
.mobile_middle > .main_best > ul > li > .best_body_div > .title{ color:#000}
.mobile_middle > .main_best > ul > li > .reply{color: #f00;}
.mobile_middle > .main_best > .empty{ height: 50px; line-height: 50px; text-align: center; background-color: #fafafa; font-size: 15px; color: #999;}




.mobile_home__post_area{ width: 100%; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); background-color: #fff;}
.mobile_home__post_area > .tab_box{width: 100%; height: 40px; border-bottom: 1px solid var(--mobile-box-border);}
.mobile_home__post_area > .tab_box > .tab_item{height: 40px; line-height: 40px; display: inline-block; color:#000; padding-left: 20px; padding-right: 20px; font-weight: 600; font-size: 15px;}
.mobile_home__post_area > .tab_box > .tab_item:hover{color:var(--text_hover-color)}
.mobile_home__post_area > .tab_box > .select{background-color: #f00; color:#fff;}
.mobile_home__post_area > .tab_box > .select:hover{color:#fff;}

.mobile_home__post_area > .post_box { width: 100%; min-height: 90px;}
.mobile_home__post_area > .post_box > .empty{ height: 80px; line-height: 80px; text-align: center; border-bottom: 1px solid var(--mobile-box-inner-border); font-size: 18px; color: #999;}
.mobile_home__post_area > .post_box > ul > li { height: 80px; border-bottom: 1px solid var(--mobile-box-inner-border); position: relative;}
.mobile_home__post_area > .post_box > ul > li:active{background-color: #fafafa;}
.mobile_home__post_area > .post_box > ul > li > div{height: 80px; margin-left: 20px; width:calc(100% - 40px); overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
.mobile_home__post_area > .post_box > ul > li > div > .body_image{width: 50px; height: 50px; float: left; margin-right: 10px; margin-top: 15px;}
.mobile_home__post_area > .post_box > ul > li > div > .choice_image{width: 50px; height: 50px; float: left; margin-right: 10px; margin-top: 15px;}
.mobile_home__post_area > .post_box > ul > li > .choice_image_small{width: 30px; height: 30px; position: absolute; left: 10px; top: 5px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body{ float: left; height: 100%;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_title{ height: 25px; margin-top: 15px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .best_icon{ float: left; height: 20px; width:40px; margin-top: 2px; margin-right: 5px; background-color: #f00; position: relative; border-radius: 5px; }
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .best_icon > .best_span{ height: 20px; width: 40px; text-align: center; color: #fff; position: absolute; font-weight: 600; font-size: 13px; line-height: 20px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .title{float: left; font-size: 15px; height: 25px; line-height: 25px; display: block; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .reply{ color:#f00; font-size: 15px; margin-left: 5px; height: 25px; line-height: 25px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom{ margin-top: 0px; height:25px; line-height: 25px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > div {float: left;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > div > span{ font-size: 12px; color:#999; height:20px; line-height: 20px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > div > .category{ color:#f00;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > div > .divider{ margin-left: 2px; margin-right: 2px;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > .right {float: right;}
.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > .cate_nick{overflow:hidden; white-space:nowrap; text-overflow:ellipsis}

.mobile_home__post_area > .post_box > ul > li > div > .text_body > .text_body_delete{ font-size: 15px; color: #999; height: 30px; line-height: 30px; margin-top: 15px;}




.mobile_middle > .mobile_home__category_area { border:0; border-top: solid 1px var(--mobile-box-border); border-bottom: solid 1px var(--mobile-box-border); border-spacing: 0px;  border-collapse : collapse; background-color: #fff; width: 100%; padding: 0; margin-top: 10px; border-style: none;}
.mobile_middle > .mobile_home__category_area > tr {border-bottom: 1px solid var(--mobile-box-border);border-style: none; padding: 0;}
.mobile_middle > .mobile_home__category_area > tr:last-child{border: 0;}
.mobile_middle > .mobile_home__category_area > tr > th { width: 50%; height: 50px; text-align: center; font-size: 15px; border-right: 1px solid var(--mobile-box-border); }
.mobile_middle > .mobile_home__category_area > tr > th:last-child {border-right: 0;}
.mobile_middle > .mobile_home__category_area > tr > td { width: 50%; border-right: 1px solid var(--mobile-box-border); border-spacing: 0px; padding: 0;}
.mobile_middle > .mobile_home__category_area > tr > td:last-child {border-right: 0;}
.mobile_middle > .mobile_home__category_area > tr > td > table { width: 100%; border-spacing: 0;}
.mobile_middle > .mobile_home__category_area > tr > td > table > tr { height: 40px; }
.mobile_middle > .mobile_home__category_area > tr > td > table > tr > td { width:50%; height: 40px; border-right: 1px solid var(--mobile-box-inner-border); border-bottom: 1px solid var(--mobile-box-inner-border); text-align: center; font-size: 13px; color: #242222; padding-left: 5px; padding-right: 5px; }
.mobile_middle > .mobile_home__category_area > tr > td > table > tr > td:active{color:var(--text_hover-color); background-color: #fafafa;}
.mobile_middle > .mobile_home__category_area > tr > td > table > tr > .empty { color:#ccc}
/**
    End - 메인화면
********************************************************************************************************/





/********************************************************************************************************
    사연 커뮤니티 공퉁
********************************************************************************************************/


.mobile_post_home__best_area{ width: 100%; vertical-align: top; border-collapse : collapse; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); outline: none; }
.mobile_post_home__best_area > .title { height: 50px; border-bottom: 1px solid var(--mobile-box-inner-border); line-height: 50px; position: relative;}
.mobile_post_home__best_area > .title > span { margin-left: 20px; font-weight: 600; font-size: 15px; color: #000;}
.mobile_post_home__best_area > .title > .right_area {position: absolute; top:0; right: 0; height: 50px; }
.mobile_post_home__best_area > .title > .right_area > div {height: 50px; float: right; margin-top: -1px;}
.mobile_post_home__best_area > .title > .right_area > div > .select{width: 70px; height: 35px; padding-left: 5px; top: -10px;  border: 1px solid var(--mobile-box-border); border-radius: 5px; outline: none; }
.mobile_post_home__best_area > .title > .right_area > .sort { margin-right: 5px;}
.mobile_post_home__best_area > .title > .right_area > .period { margin-right: 5px;}
.mobile_post_home__best_area > .title > .right_area > .period > .select{width: 60px;}
.mobile_post_home__best_area > .title > .right_area > .time_box { height: 50px; margin-top: 1px; }
.mobile_post_home__best_area > .title > .right_area > .time_box > div{float: right; }
.mobile_post_home__best_area > .title > .right_area > .time_box > .time{ height: 50px; line-height: 50px; font-size: 13px; color: #000; }
.mobile_post_home__best_area > .title > .right_area > .time_box > .left_btn{ height: 50px; width: 30px; line-height: 52px; text-align: center; font-size: 15px; color: #000; margin-right: -5px; }
.mobile_post_home__best_area > .title > .right_area > .time_box > .right_btn{ height: 50px; width: 30px; line-height: 52px; text-align: center; font-size: 15px; color: #000; margin-left: -5px; }
.mobile_post_home__best_area > .title > .right_area > .time_box > .left_btn:active{color: #a00}
.mobile_post_home__best_area > .title > .right_area > .time_box > .right_btn:active{color: #a00}
.mobile_post_home__best_area > .title > .right_area > .time_box > .disable_btn {color:#ccc}
.mobile_post_home__best_area > .title > .right_area > .time_box > .disable_btn:active {color:#ccc}




.mobile_post_home__best_area > .post_box { width: 100%; }
.mobile_post_home__best_area > .post_box > ul > li { height: 50px;}
.mobile_post_home__best_area > .post_box > ul > li > div {height: 50px;}
.mobile_post_home__best_area > .post_box > ul > li > div:active{background-color: #fafafa;}
.mobile_post_home__best_area > .post_box > ul > li > .divider { width: 40px; }
.mobile_post_home__best_area > .post_box > ul > li > .post { width: 100%; }
.mobile_post_home__best_area > .post_box > ul > li > .border_bottom { border-bottom: 1px solid var(--mobile-box-inner-border); }
.mobile_post_home__best_area > .post_box > ul > li > .post:hover{background-color: #fafafa;}
.mobile_post_home__best_area > .post_box > ul > li > .post:active{background-color: #eee;}

.mobile_post_home__best_area > .post_box > ul > li > .post > div {padding-left: var(--m-margin-side);}
.mobile_post_home__best_area > .post_box > ul > li > .post > div > div { float: left; overflow:hidden;}
.mobile_post_home__best_area > .post_box > ul > li > .post > div > .ranking{ width: 20px; height: 50px; line-height: 50px; text-align: center;}
.mobile_post_home__best_area > .post_box > ul > li > .post > div > .ranking > span{ font-size: 18px; font-weight: 600; color: #000; }
.mobile_post_home__best_area > .post_box > ul > li > .post > div > .body{ max-width:calc(100% - 40px - 20px - 10px - 40px); font-size: 15px;height: 50px; margin-left: 10px;; line-height: 50px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.mobile_post_home__best_area > .post_box > ul > li > .post > div > .body > .category{color: #777; height: 50px; line-height: 50px;}
.mobile_post_home__best_area > .post_box > ul > li > .post > div > .body > .title{color: #000; width: 100px; margin-left: 5px; height: 50px; line-height: 50px;}
.mobile_post_home__best_area > .post_box > ul > li > .post > div > .reply{color: #f00; margin-left: 5px; height: 50px; line-height: 50px;}

.mobile_post_home__best_area > .post_box > .empty { width: 100%; height: 50px; background-color: #fafafa; text-align: center; }
.mobile_post_home__best_area > .post_box > .empty > span { width: 100%; height: 50px; line-height: 50px; font-size: 15px; color: #999;}


.mobile_post_home__list_area { width: 100%; margin-top: 10px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_post_home__list_area > .title { height: 50px; width: 100%;  border-bottom: 1px solid var(--mobile-box-inner-border);}
.mobile_post_home__list_area > .title > .category{ float: left; height: 50px; line-height: 50px; color: #000; font-size: 15px; padding-left: var(--m-margin-side); font-weight: 600;}
.mobile_post_home__list_area > .title > .best{color: #f00;}

.mobile_post_home__list_area > ul > li { width: 100%; height: 60px; border-bottom: 1px solid var(--mobile-box-inner-border); display: block; }
.mobile_post_home__list_area > ul > li:active{background-color: #fafafa;}
.mobile_post_home__list_area > ul > li > div {width:calc(100% - 40px); display: block; margin-left: var(--m-margin-side)}
.mobile_post_home__list_area > ul > li > .top_area { height: 30px; font-size: 15px; float: left;}
.mobile_post_home__list_area > ul > li > .top_area > div{ float: left; margin-top: 10px;}
.mobile_post_home__list_area > ul > li > .top_area > .title_body {  height: 20px; line-height: 20px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.mobile_post_home__list_area > ul > li > .top_area > .title_body > .category{color: #555; margin-right: 5px; font-size: 14px;}
.mobile_post_home__list_area > ul > li > .top_area > .title_body > .title{color: #000; }
.mobile_post_home__list_area > ul > li > .top_area > .title_body > .delete{color: #ccc;}
.mobile_post_home__list_area > ul > li > .top_area > .title_body > .delete_line{color: #ccc; text-decoration:line-through;}
.mobile_post_home__list_area > ul > li > .top_area > .title_body_delete { color:#ccc; text-decoration:line-through; }
.mobile_post_home__list_area > ul > li > .top_area > .title_body_delete > .category{color:#ccc}
.mobile_post_home__list_area > ul > li > .top_area > .title_body_delete > .title{color:#ccc}
.mobile_post_home__list_area > ul > li > .top_area > .reply{color: #f00;}
.mobile_post_home__list_area > ul > li > .top_area > .reply_delete{color: #ccc; text-decoration:line-through;}
.mobile_post_home__list_area > ul > li > .top_area > .picture{width: 20px; height: 20px; margin-left: 5px; margin-top: 10px;}
.mobile_post_home__list_area > ul > li > .top_area > .picture_delete{color: #ccc; text-decoration:line-through;}

.mobile_post_home__list_area > ul > li > .top_area > .notice { background-color: #00a; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.mobile_post_home__list_area > ul > li > .top_area > .notice > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.mobile_post_home__list_area > ul > li > .top_area > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.mobile_post_home__list_area > ul > li > .top_area > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.mobile_post_home__list_area > ul > li > .top_area > .best_delete {background-color: #ccc; }

.mobile_post_home__list_area > ul > li > .top_area > .choice_image {float: left; width: 20px; height: 20px; margin-left: 5px; margin-top: 10px;}
.mobile_post_home__list_area > ul > li > .top_area > .choice_image_delete {opacity: 0.3;}

.mobile_post_home__list_area > ul > li > .top_area > .new { float: left; background-color: #f00; width: 16px; height: 16px; line-height: 14px; border-radius: 5px; text-align: center; margin-left: 5px; margin-top: 12px;}
.mobile_post_home__list_area > ul > li > .top_area > .new > span { font-size: 10px; color: #fff; width: 16px; font-weight: 600;}

.mobile_post_home__list_area > ul > li > .bottom { height: 30px; font-size: 13px; float: left; }
.mobile_post_home__list_area > ul > li > .bottom > div{ float: left; height: 30px; line-height: 30px; color: #999;}
.mobile_post_home__list_area > ul > li > .bottom > .delete{ color: #ccc; }
.mobile_post_home__list_area > ul > li > .bottom > .insert_time{ float: right;}
.mobile_post_home__list_area > ul > li > .bottom > .writer{ margin-right: 5px; color: #555;}
.mobile_post_home__list_area > ul > li > .bottom > .hits{ margin-right: 5px;}
.mobile_post_home__list_area > ul > li > .bottom > .likes{}
.mobile_post_home__list_area > ul > .notice{ background-color: rgb(245, 245, 250) }
.mobile_post_home__list_area > ul > .notice:active{ background-color: rgb(231, 231, 250) }
.mobile_post_home__list_area > .empty {height: 90px; line-height: 90px; text-align: center; font-size: 15px; color: #aaa; border-bottom: 1px solid var(--mobile-box-inner-border); background-color: #fafafa;}





/********************************************************************************************************
    개인정보 처리방침 화면
********************************************************************************************************/

.mobile_personal_top {width: 100%; padding-left: 20px; padding-right: 20px;}
.mobile_personal_top > .title{ color: #000; font-size: 20px; font-weight: 600; padding-top: 20px; white-space:pre-line;}
.mobile_personal_top > .box{ border: 5px solid #ccc; padding: 10px; font-size: 15px; color: #777; margin-top: 20px; white-space:pre-line;}
.mobile_personal_top > .information{ margin-top: 20px; font-size: 15px; color: #555; white-space:pre-line; }

.mobile_personal_provision_box {border: 1px solid #ccc; border-radius: 10px; margin-top: 20px;padding-left: 20px; padding-right: 20px; margin-left: 20px; margin-right: 20px;}
.mobile_personal_provision_box > .title { padding-top: 15px; padding-bottom: 15px; border-bottom: 1px solid #ccc; color: #000; font-size: 15px; white-space:pre-line;}
.mobile_personal_provision_box > .item{padding-top: 10px; padding-bottom: 15px; color: #555; font-size: 15px; white-space:pre-line;}

/** End - 개인정보 처리방침 화면
********************************************************************************************************/



/********************************************************************************************************
    차단한 사용자 화면
********************************************************************************************************/

.mobile_block_user__list_area { width: 100%; margin-top: 10px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}

.mobile_block_user__list_area > ul > li { width: 100%; height: 60px; border-bottom: 1px solid var(--mobile-box-inner-border); display: block; }
.mobile_block_user__list_area > ul > li > div { float: left; width:calc(100% - 80px); margin-left: var(--m-margin-side)}
.mobile_block_user__list_area > ul > li > div  > .top_area { height: 30px; font-size: 15px; }
.mobile_block_user__list_area > ul > li > div  > .top_area > div{ float: left; margin-top: 10px;}
.mobile_block_user__list_area > ul > li > div  > .top_area > .title_body {  height: 20px; line-height: 20px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.mobile_block_user__list_area > ul > li > div  > .top_area > .title_body > .title{color: #000; }

.mobile_block_user__list_area > ul > li > div  > .bottom { height: 30px; font-size: 13px; float: left; }
.mobile_block_user__list_area > ul > li > div  > .bottom > .writer{ margin-right: 5px; color: #999;}
.mobile_block_user__list_area > ul > li > .right {float: right; width:50px; height: 60px; margin-right:10px; margin-left: 0px; text-align: center;}
.mobile_block_user__list_area > ul > li > .right > svg {margin-top: 17.5px;}
.mobile_block_user__list_area > ul > li > .right:active {background-color: #fafafa;}
.mobile_block_user__list_area > .empty {height: 90px; line-height: 90px; text-align: center; font-size: 15px; color: #aaa; border-bottom: 1px solid var(--mobile-box-inner-border); background-color: #fafafa;}

/** End - 차단한 사용자 화면
********************************************************************************************************/



/********************************************************************************************************
    로그인 화면
********************************************************************************************************/
.mobile_login_box { width: 100%; margin-top: 10px; background-color: #fff; padding-left: 20px; padding-right: 20px; padding-top: 10px; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); padding-bottom: 20px;}
.mobile_login_box > .input_box{height: 60px; width: 100%; margin-top: 10px; border: 1px solid #ccc; background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px;}
.mobile_login_box > .input_box > input{ float: left; height: 56px; line-height: 56px; background-color: #00000000; border: 0; outline: none; font-size: 18px; color:#000; }
.mobile_login_box > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.mobile_login_box > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.mobile_login_box > .input_box > .right_text { float: right; height: 60px; line-height: 60px; width: 70px; text-align: end; font-size: 18px; color: #555; text-decoration: underline;}
.mobile_login_box > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.mobile_login_box > .input_box > .right_text:active{ color: var(--text_active-color);}
.mobile_login_box > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.mobile_login_box > .login_btn { width: 100; margin-top: 20px; background-color: #000; }
.mobile_login_box > .login_btn:active {background-color: #555;}
.mobile_login_box > .bottom_text{ margin-top: 10px; height: 40px;}
.mobile_login_box > .bottom_text > div{float: right; height: 40px; line-height: 40px; font-size: 15px; color: #000;}
.mobile_login_box > .bottom_text > div:active{background-color: #fafafa;}
.mobile_login_box > .bottom_text > .sign_up{float: left;}
.mobile_login_box > .bottom_text > .devider{margin-left: 8px; margin-right: 8px;}
.mobile_login_box > .bottom_text > .devider:active{background-color: #00000000;}
/**
    End - 로그인 화면
********************************************************************************************************/


/********************************************************************************************************
    로그인 - 유저정보 화면
********************************************************************************************************/
.mobile_user_box { width: 100%; border-top: 1px solid var(--mobile-box-border); padding: 20px;  border-bottom: 1px solid var(--mobile-box-border);background-color: #fff; margin-top: 10px; }
.mobile_user_box > .loader{ width: 20px; height: 20px; margin-left: calc(50% - 10px); margin-top: 10px; margin-bottom: 10px; }
.mobile_user_box > .info_area { width: 100%; position: relative; height: 120px;}
.mobile_user_box > .info_area > .left { float: left; width: 120px; height: 100%;}
.mobile_user_box > .info_area > .left > .level { width: 80px; height: 80px; margin-left: 50%; transform: translate(-50%, 0);}
.mobile_user_box > .info_area > .left > .level > img {width: 80px; height: 80px; position: absolute;}
.mobile_user_box > .info_area > .left > .level > span {width: 80px; height: 80px; line-height: 80px; text-align: center; position: absolute; font-size: 20px; font-weight: 600;}
.mobile_user_box > .info_area > .left > .name { width: 140px; font-size: 15px; text-align: center; margin-top: 10px; margin-left: -10px; color: #000; font-weight: 600;}
.mobile_user_box > .info_area > .right { float: left; width: calc(100% - 140px); height: 100%; margin-left: 20px; padding-top: 15px; border:1px solid #ccc; background-color: #fafafa; }
.mobile_user_box > .info_area > .right > div {height: 30px; line-height: 30px; color:#000}
.mobile_user_box > .info_area > .right > .post { font-size: 15px; position: relative; }
.mobile_user_box > .info_area > .right > .post > .title{ margin-left: 20px; }
.mobile_user_box > .info_area > .right > .post > .count{ right: 20px; position:absolute;}

.mobile_user_box > .logout_btn{ height: 45px; width: 100%; line-height: 50px; margin-top: 20px; color:#fff; font-size: 15px; text-align: center; align-items: center;}
.mobile_user_box > .logout_btn > .loader{ width: 20px; height: 20px; margin-left: 85px; margin-top: 10px; position: absolute;}

.mobile_user_info_box { width: 100%; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);background-color: #fff; margin-top: 10px; }
.mobile_user_info_box > div {height: 60px; border-bottom: 1px solid var(--mobile-box-inner-border); background-color: #fff; position: relative;}
.mobile_user_info_box > div:active{background-color: #fafafa;}
.mobile_user_info_box > div:last-child{border-bottom: 0;}
.mobile_user_info_box > div > span {height: 60px; line-height: 60px; font-size: 15px; margin-left: 20px;}
.mobile_user_info_box > div > .right_icon { position: absolute; top:20px; right: 20px; color: #555;}


.mobile_user_box > .divider { height: 1px; width: 230px; background-color: #bbb; margin-bottom: 10px; }
.mobile_user_box > .post_info_area { width: 210px; margin-left: 10px; padding-top: 5px; padding-bottom: 10px; border:1px solid #ccc; background-color: #fafafa;}
.mobile_user_box > .post_info_area > div { height: 20px; width: 210px; line-height: 20px; position:relative; margin-top: 5px;}
.mobile_user_box > .post_info_area > div > span{ font-size: 15px; color: #000;}
.mobile_user_box > .bottom_area{height: 20px; width: 210px; margin-left: 10px; color:#000; margin-top: 10px; margin-bottom: 5px;  position: relative; font-size: 13px;}
.mobile_user_box > .bottom_area> .my_post{height: 20px; line-height: 20px; position: absolute; text-decoration: underline;}
.mobile_user_box > .bottom_area> .my_post:hover{ color: var(--text_hover-color) }
.mobile_user_box > .bottom_area> .bottom_right_area{height: 20px; line-height: 20px; right: 0; position: absolute;}
.mobile_user_box > .bottom_area> .bottom_right_area >.inquiry{text-decoration: underline; }
.mobile_user_box > .bottom_area> .bottom_right_area >.inquiry:hover{ color: var(--text_hover-color)}
/**
    End - 로그인 - 유저정보 화면
********************************************************************************************************/



/********************************************************************************************************
    내가쓴글 화면
********************************************************************************************************/
.mobile_my_post__title_area{ width: 100%; vertical-align: top; border-collapse : collapse; background-color: #fff; outline: none; }
.mobile_my_post__title_area > .title { height: 50px; border-bottom: 1px solid var(--mobile-box-inner-border); position: relative;}
.mobile_my_post__title_area > .title > span { margin-left: 20px; font-weight: 600; font-size: 15px; color: #000;}
.mobile_my_post__title_area > .title > .left_area {position: absolute; top:0; line-height: 50px; left: 10px; height: 50px; }
.mobile_my_post__title_area > .title > .left_area > div {height: 50px; float: right; margin-top: -1px;}
.mobile_my_post__title_area > .title > .left_area > div > .select{width: 70px; height: 35px; padding-left: 5px; top: -10px;  border: 1px solid var(--mobile-box-border); border-radius: 5px; outline: none; }
.mobile_my_post__title_area > .title > .left_area > .sort { margin-right: 5px;}
.mobile_my_post__title_area > .title > .left_area > .period { margin-right: 5px;}
.mobile_my_post__title_area > .title > .left_area > .period > .select{width: 60px;}
.mobile_my_post__title_area > .title > .left_area > .time_box { height: 50px; margin-top: 1px; }
.mobile_my_post__title_area > .title > .left_area > .time_box > div{float: right; }
.mobile_my_post__title_area > .title > .left_area > .time_box > .time{ height: 50px; line-height: 50px; font-size: 13px; color: #000; }
.mobile_my_post__title_area > .title > .left_area > .time_box > .left_btn{ height: 50px; width: 30px; line-height: 52px; text-align: center; font-size: 15px; color: #000; margin-right: -5px; }
.mobile_my_post__title_area > .title > .left_area > .time_box > .right_btn{ height: 50px; width: 30px; line-height: 52px; text-align: center; font-size: 15px; color: #000; margin-left: -5px; }
.mobile_my_post__title_area > .title > .left_area > .time_box > .left_btn:active{color: #a00}
.mobile_my_post__title_area > .title > .left_area > .time_box > .right_btn:active{color: #a00}
.mobile_my_post__title_area > .title > .left_area > .time_box > .disable_btn {color:#ccc}
.mobile_my_post__title_area > .title > .left_area > .time_box > .disable_btn:active {color:#ccc}

.mobile_my_post__title_area > .title > .right_area {position: absolute; top:0; right: 0; height: 50px; }


.mobile_my_post__title_area > .post_box { width: 100%; }
.mobile_my_post__title_area > .post_box > ul > li { height: 50px;}
.mobile_my_post__title_area > .post_box > ul > li > div {height: 50px;}
.mobile_my_post__title_area > .post_box > ul > li > div:active{background-color: #fafafa;}
.mobile_my_post__title_area > .post_box > ul > li > .divider { width: 40px; }
.mobile_my_post__title_area > .post_box > ul > li > .post { width: 100%; }
.mobile_my_post__title_area > .post_box > ul > li > .border_bottom { border-bottom: 1px solid var(--mobile-box-inner-border); }
.mobile_my_post__title_area > .post_box > ul > li > .post:hover{background-color: #fafafa;}
.mobile_my_post__title_area > .post_box > ul > li > .post:active{background-color: #eee;}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > div { float: left; overflow:hidden;}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > .ranking{ width: 40px; height: 50px; line-height: 50px; text-align: center;}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > .ranking > span{ font-size: 18px; font-weight: 600; color: #000; margin-left: 20px;}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > .body{ max-width:calc(100% - 40px - 20px - 10px - 40px); font-size: 15px;height: 50px; margin-left: 10px;; line-height: 50px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > .body > .category{color: #777; height: 50px; line-height: 50px;}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > .body > .title{color: #000; width: 100px; margin-left: 5px; height: 50px; line-height: 50px;}
.mobile_my_post__title_area > .post_box > ul > li > .post > div > .reply{color: #f00; margin-left: 5px; height: 50px; line-height: 50px;}

.mobile_my_post__title_area > .post_box > .empty { width: 100%; height: 50px; background-color: #fafafa; text-align: center; }
.mobile_my_post__title_area > .post_box > .empty > span { width: 100%; height: 50px; line-height: 50px; font-size: 15px; color: #999;}

/**
    End - 내가쓴글 화면
********************************************************************************************************/



/********************************************************************************************************
    게시글화면
********************************************************************************************************/
.mobile_post_body { width: 100%;}
.mobile_post_body > .top{ width: 100%; height: 50px; position: relative; background-color: #fff;}
.mobile_post_body > .top > .category{ position: absolute; font-size: 14px; color: #000; font-weight: 600; height: 50px; margin-left: var(--m-margin-side); line-height: 50px;}
.mobile_post_body > .top > .right { position: absolute; right: 10px; height: 50px; }
.mobile_post_body > .top > .right > div {line-height: 50px; font-size: 13px; color: #000; display: inline-block; padding-left: 5px; padding-right: 5px;}
.mobile_post_body > .top > .right > div > div{float: left;}
.mobile_post_body > .top > .right > div > .icon{ margin-top: 3px;}
.mobile_post_body > .top > .right > .list { margin-right: 3px;}
.mobile_post_body > .top > .right > .list > .icon{ margin-right: 3px;}
.mobile_post_body > .top > .right > .before > .icon{ margin-right: 3px;}
.mobile_post_body > .top > .right > .next > .icon{ margin-left: 3px;}
.mobile_post_body > .top > .right > div:hover{color:var(--text_hover-color)}
.mobile_post_body > .top > .right > .disable { color:#aaa;}
.mobile_post_body > .top > .right > .disable:hover { color:#aaa;}

.mobile_post_body > .title_box { width: 100%; margin-top: 0px; min-height: 80px; background-color: #fff; border-top: 1px solid var(--mobile-box-inner-border); border-bottom: 1px solid var(--mobile-box-border); padding-top: 10px; padding-bottom: 10px; }
.mobile_post_body > .title_box > .choice {width: 50px;height: 50px; margin-left: 20px; float: left; }
.mobile_post_body > .title_box > .body_box {width: 100%;min-height: 60px; display: inline-block; margin-left: 20px; }
.mobile_post_body > .title_box > .body_box > div{width: 100%; }
.mobile_post_body > .title_box > .body_box > .top { min-height: 30px; }
.mobile_post_body > .title_box > .body_box > .top > .best { float: left; background-color: #f00; width: 35px; height: 20px; margin-top: 10px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 10px;}
.mobile_post_body > .title_box > .body_box > .top > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.mobile_post_body > .title_box > .body_box > .top > .title { display: inline-block; width: 100%; margin-top: 9px; font-size: 15px; color: #000; }
.mobile_post_body > .title_box > .body_box > .bottom { height: 30px; }
.mobile_post_body > .title_box > .body_box > .bottom > .level { margin-top: 7.5px; float: left; width: 29px;}
.mobile_post_body > .title_box > .body_box > .bottom > .level > .level { width: 22px; height: 22px; position: absolute; display: inline-block; }
.mobile_post_body > .title_box > .body_box > .bottom > .level > .level > img {width: 22px; height: 22px; position: absolute;}
.mobile_post_body > .title_box > .body_box > .bottom > .level > .level > span {width: 22px; height: 22px; line-height: 22px; text-align: center; position: absolute; font-size: 12px; font-weight: 600;}
.mobile_post_body > .title_box > .body_box > .bottom > .nickname { float: left; height: 40px; line-height: 40px; font-size: 15px; color: #999; }
.mobile_post_body > .title_box > .body_box > .bottom > .time { float: left; height: 40px; line-height: 40px; font-size: 15px; color: #999; margin-left: 10px; }
.mobile_post_body > .title_box > .body_box > .bottom > .hits_title{ float: right; height: 40px; line-height: 40px; font-size: 15px; color: #999; margin-right: 10px; }
.mobile_post_body > .title_box > .body_box > .bottom > .hits{ float: right; height: 40px; line-height: 40px; font-size: 15px; color: #f00; }

.mobile_post_body > .body_box { width: 100%;  background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); margin-top: 10px;}
.mobile_post_body > .body_box > .contents {min-height: 100px;}
.mobile_post_body > .body_box > .contents > .body_str { width: 100%; font-size: 15px; white-space: pre-line; word-break:break-word; padding: 20px; line-height: 25px;}
.mobile_post_body > .body_box > .contents > .image > img { max-width: 100%; display: block; margin-top: 20px; margin-left: 50%; transform: translate(-50%, 0);}
.mobile_post_body > .body_box > .contents > .delete_box{ width: calc(100% - 40px); margin-left: 20px; height: 80px; margin-top: 20px; border: 1px solid #ccc; border-radius: 20px; background-color: #fafafa; position: relative;}
.mobile_post_body > .body_box > .contents > .delete_box > div {display: inline-block; position: absolute;}
.mobile_post_body > .body_box > .contents > .delete_box > .guide { height: 50px; left: 20px; top: 15px; font-size: 14px; line-height: 25px; color:#555; width: 320px; }
.mobile_post_body > .body_box > .contents > .delete_box > .guide > span{  display: block; }
.mobile_post_body > .body_box > .contents > .delete_box > .delete_btn { right: 20px; top:20px; width: 80px; height: 40px; font-size: 13px; font-weight: 600; line-height: 40px; text-align: center; background-color: #f00; color: #fff;}
.mobile_post_body > .body_box > .contents > .delete_box > .delete_btn:hover {background-color: #e00;}
.mobile_post_body > .body_box > .contents > .delete_box > .delete_btn:active {background-color: #d00;}

.mobile_post_body > .body_box > .contents > .choice_box{ width: 100%; background-color: #CA1329;}
.mobile_post_body > .body_box > .contents > .choice_box > div { width:100% }

.mobile_post_body > .body_box > .contents > .choice_box > .youtube{ width: 100%; height: 300px; background-color: #fff;}
.mobile_post_body > .body_box > .contents > .choice_box > .top { width: 100%; height: 50px;}

.mobile_post_body > .body_box > .contents > .choice_box > .top > .choice_text{ float: left; font-family: "LogoFont";color: #fff; font-size: 20px; margin-left: 20px; height: 100%; line-height: 50px; }
.mobile_post_body > .body_box > .contents > .choice_box > .top > .choice_image{ float: left; width: 50px; height: 50px; margin-left: 10px; margin-top: 5px;}
.mobile_post_body > .body_box > .contents > .choice_box > .top > .choice_image > img {width: 100%; height:100%}
.mobile_post_body > .body_box > .contents > .choice_box > .top > .youtube_button{ height: 40px; margin-top: 5px; color: #fff; float: right; margin-right: 10px;}
.mobile_post_body > .body_box > .contents > .choice_box > .top > .youtube_button > .icon {float: left; height: 40px; padding-top: 11px; margin-left: 10px;}
.mobile_post_body > .body_box > .contents > .choice_box > .top > .youtube_button > .text {float: left; font-size: 16px; height: 40px; line-height: 40px; font-weight: 500; margin-left: 5px; padding-right: 10px;}
.mobile_post_body > .body_box > .contents > .choice_box > .top > .youtube_button:active {background-color: #e00;}

.mobile_post_body > .body_box > .contents > .choice_box > .bottom { padding-top: 15px; padding-bottom: 15px; margin-left: 10px; width: calc(100% - 20px); display: block;}
.mobile_post_body > .body_box > .contents > .choice_box > .bottom > .text_span{  width: 100%; font-size: 13px; color: #fff;}

.mobile_post_body > .body_box > .like_tate_box { width: 100%; text-align: center; margin-top: 50px; height: 50px; position: relative;}
.mobile_post_body > .body_box > .like_tate_box > div {position: absolute;}
.mobile_post_body > .body_box > .like_tate_box > .count {height: 50px; line-height: 50px; font-size: 20px; color: #555; font-weight: 600; }
.mobile_post_body > .body_box > .like_tate_box > .count_left { right:calc(50% + 90px)}
.mobile_post_body > .body_box > .like_tate_box > .count_right { left:calc(50% + 90px)}
.mobile_post_body > .body_box > .like_tate_box > .btn { height: 40px;width: 40px; margin-left: 10px; margin-right: 10px; }
.mobile_post_body > .body_box > .like_tate_box > .btn > span {display: block; height: 20px; margin-top: 2px; font-size: 13px; color: #777; font-weight: 600;}
.mobile_post_body > .body_box > .like_tate_box > .btn > .icon {display: block; color: #777; margin-left: 8px;}
.mobile_post_body > .body_box > .like_tate_box > .btn:active > span{color: #000}
.mobile_post_body > .body_box > .like_tate_box > .btn:active > .icon{color: #000}
.mobile_post_body > .body_box > .like_tate_box > .btn_left { right:calc(50% + 10px)}
.mobile_post_body > .body_box > .like_tate_box > .btn_right { left:calc(50% + 10px)}

.mobile_post_body > .body_box > .bottom{ width: 100%; margin-top: 30px; height: 50px; position: relative; }
.mobile_post_body > .body_box > .bottom > .share{ position: absolute; font-size: 13px; color: #000; height: 50px; margin-left: 10px; line-height: 50px;}
.mobile_post_body > .body_box > .bottom > .share > div{float: left;}
.mobile_post_body > .body_box > .bottom > .share > .icon{ margin-top: 3px; margin-right: 3px; }

.mobile_post_body > .body_box > .bottom > .right { position: absolute; right: 0; height: 50px; }
.mobile_post_body > .body_box > .bottom > .right > div {line-height: 50px; font-size: 13px; color: #000; display: inline-block; padding-left: 5px; padding-right: 5px;}
.mobile_post_body > .body_box > .bottom > .right > div > div{float: left;}
.mobile_post_body > .body_box > .bottom > .right > div > .icon{ margin-top: 3px;}
.mobile_post_body > .body_box > .bottom > .right > .report {margin-right: 10px; color:#f00}
.mobile_post_body > .body_box > .bottom > .right > .report > .icon{ margin-right: 3px;}
.mobile_post_body > .body_box > .bottom > .right > .before > .icon{ margin-right: 3px;}
.mobile_post_body > .body_box > .bottom > .right > .next > .icon{ margin-left: 3px;}
.mobile_post_body > .body_box > .bottom > .right > div:hover{color:var(--text_hover-color)}
.mobile_post_body > .body_box > .bottom > .right > .disable { color:#aaa;}
.mobile_post_body > .body_box > .bottom > .right > .disable:hover { color:#aaa;}
.mobile_post_body > .body_box > .bottom > .right > .report:hover{color:#c00}
.mobile_post_body > .body_box > .bottom > .right > .report:active{color:#a00}

.mobile_post_body > .btn_box { width: 100%; margin-top: 10px; height: 50px; padding-left: 10px; padding-right: 10px;}
.mobile_post_body > .btn_box > div {float: right; width: 60px; height:40px; font-size: 13px; font-weight: 400; background-color: #000; color: #fff; line-height: 40px; text-align: center;}
.mobile_post_body > .btn_box > .list {float: left; background-color: #555;}
.mobile_post_body > .btn_box > .rollback {background-color: #0000AA;}
.mobile_post_body > .btn_box > .choice {background-color: #CA1329; margin-left: 10px;}
.mobile_post_body > .btn_box > .delete {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 10px;}
.mobile_post_body > .btn_box > .edit {background-color: #555; margin-left: 10px;}

.mobile_post_body > .btn_box > .list:hover {background-color: #333;}
.mobile_post_body > .btn_box > .rollback:hover {background-color: #000088;}
.mobile_post_body > .btn_box > .choice:hover {background-color: #ad1022;}
.mobile_post_body > .btn_box > .delete:hover {background-color: #eee;}
.mobile_post_body > .btn_box > .edit:hover {background-color: #333;}

.mobile_post_body > .btn_box > .list:active {background-color: #111;}
.mobile_post_body > .btn_box > .rollback:active {background-color: #000066;}
.mobile_post_body > .btn_box > .choice:active {background-color: #830d1a;}
.mobile_post_body > .btn_box > .delete:active {background-color: #ddd;}
.mobile_post_body > .btn_box > .edit:active {background-color: #111;}



.mobile_post_bottom_list_body { background-color: #fff; margin-top: 10px; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); }
.mobile_post_bottom_list_body > ul > li { padding-left: var(--m-margin-side); padding-right: var(--m-margin-side); border-bottom: 1px solid var(--mobile-box-inner-border); }
.mobile_post_bottom_list_body > ul > li:last-child{ margin-bottom: 0;}
.mobile_post_bottom_list_body > ul > li > .top_area { height: 30px; font-size: 14px; }
.mobile_post_bottom_list_body > ul > li > .top_area > div{ float: left; margin-top: 10px;}
.mobile_post_bottom_list_body > ul > li > .top_area > .title_body {  overflow:hidden; height: 20px; line-height: 20px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.mobile_post_bottom_list_body > ul > li > .top_area > .title_body > .category{color: #555; margin-right: 5px; font-size: 14px;}
.mobile_post_bottom_list_body > ul > li > .top_area > .title_body > .title{color: #000;}
.mobile_post_bottom_list_body > ul > li > .top_area > .title_body > .delete{color: #ccc;}
.mobile_post_bottom_list_body > ul > li > .top_area > .title_body > .delete_line{color: #ccc; text-decoration:line-through;}
.mobile_post_bottom_list_body > ul > li > .top_area > .reply{color: #f00;}
.mobile_post_bottom_list_body > ul > li > .top_area > .picture{width: 20px; height: 20px; margin-left: 5px; margin-top: 10px;}

.mobile_post_bottom_list_body > ul > li > .top_area > .notice { background-color: #00a; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.mobile_post_bottom_list_body > ul > li > .top_area > .notice > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.mobile_post_bottom_list_body > ul > li > .top_area > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.mobile_post_bottom_list_body > ul > li > .top_area > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}

.mobile_post_bottom_list_body > ul > li > .top_area > .choice_image {float: left; width: 20px; height: 20px; margin-left: 5px; margin-top: 10px;}
.mobile_post_bottom_list_body > ul > li > .top_area > .choice_image_delete {opacity: 0.3;}

.mobile_post_bottom_list_body > ul > li > .top_area > .new { float: left; background-color: #f00; width: 16px; height: 16px; line-height: 14px; border-radius: 5px; text-align: center; margin-left: 5px; margin-top: 12px;}
.mobile_post_bottom_list_body > ul > li > .top_area > .new > span { font-size: 10px; color: #fff; width: 16px; font-weight: 600;}

.mobile_post_bottom_list_body > ul > li > .bottom { height: 30px; font-size: 13px; }
.mobile_post_bottom_list_body > ul > li > .bottom > div{ float: left; height: 30px; line-height: 30px; color: #999;}
.mobile_post_bottom_list_body > ul > li > .bottom > .delete{ color: #ccc; }
.mobile_post_bottom_list_body > ul > li > .bottom > .insert_time{ float: right;}
.mobile_post_bottom_list_body > ul > li > .bottom > .writer{ margin-right: 5px; color: #555;}
.mobile_post_bottom_list_body > ul > li > .bottom > .hits{ margin-right: 5px;}
.mobile_post_bottom_list_body > ul > li > .bottom > .likes{}

.mobile_post_bottom_list_body > ul > .focus{background-color: #0000AA10;}
.mobile_post_bottom_list_body > ul > .empty_item{ height: 150px; background-color: #fafafa; }
.mobile_post_bottom_list_body > ul > .empty_item > td { font-size: 15px; color: #aaa;}

.mobile_post_reply_body { width:100%; margin-top: 10px;}
.mobile_post_reply_body > .reply_list { width: 100%; background-color: #fff; border-top: 1px solid var(--mobile-box-border); }
.mobile_post_reply_body > .reply_list > .title {height: 50px; line-height: 50px; font-size: 15px; font-weight: 600; color: #000; padding-left: var(--m-margin-side); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_post_reply_body > .reply_list > .title > .count {color: #f00; margin-left: 5px;}
.mobile_post_reply_body > .reply_list > .empty_item{ height: 100px; line-height: 100px; background-color: #fafafa; text-align: center; font-size: 15px; color: #aaa; border-bottom: 1px solid var(--mobile-box-inner-border); }
.mobile_post_reply_body > .reply_list > .reply_item { width: 100%; padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid var(--mobile-box-inner-border); padding-left: var(--m-margin-side); padding-right: var(--m-margin-side);}
.mobile_post_reply_body > .reply_list > .reply_item > .title { width: 100%; height: 30px; line-height: 30px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > div {float: left;}

.mobile_post_reply_body > .reply_list > .reply_item > .title > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; margin-top: 5px; border-radius: 5px; text-align: center; margin-right: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .level { width: 20px; height: 20px; position: relative; margin-right: 3px; margin-top: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .level > img {width: 20px; height: 20px; position: absolute;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .level > span {width: 20px; height: 20px; line-height: 20px; text-align: center; position: absolute; font-size: 12px; font-weight: 600;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .nickname {font-size: 14px; color: #000; margin-right: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right {float: right; margin-left: 10px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right:active{color:#000}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right > div{float: right;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right > .icon{ width: 15px; height: 15px; margin-top: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right > .count{font-size: 14px; margin-left: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right > span {color: #555; font-weight: 600; font-size: 13px;}
.mobile_post_reply_body > .reply_list > .reply_item > .title > .right > span:active{color:var(--text_active-color)}
.mobile_post_reply_body > .reply_list > .reply_item > .reply_item_body {width: 100%; margin-top: 15px; margin-bottom: 20px;}
.mobile_post_reply_body > .reply_list > .reply_item > .reply_item_body > .body_str {font-size: 14px; color: #000; white-space:pre-line; word-break:break-word; line-height: 23px;}
.mobile_post_reply_body > .reply_list > .reply_item > .reply_item_body > img { max-width: 100%; margin-bottom: 15px; }

.mobile_post_reply_body > .reply_list > .reply_item_delete > .reply_item_body {margin-bottom: 0px;}
.mobile_post_reply_body > .reply_list > .reply_item_delete > .reply_item_body > .body_str {color:#999}

.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area { width: 100%;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom {height: 20px; width: 100%;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .count { height: 20px; line-height: 20px; float: left; }
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .count > div { float: left; font-size: 13px; color: #000;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .count > .count { font-size: 15px; color: #f00; margin-left: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .count > .icon { margin-left: 5px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .date {float: right; height: 20px; line-height: 20px; font-size: 14px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .right {float: right; height: 20px; line-height: 20px; padding-left: 5px; padding-right: 10px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .right > span {color: #000; font-weight: 600; font-size: 13px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .bottom > .right > span:active{color:var(--text_active-color)}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .enter_area {padding: 10px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list {margin-top: 30px; background-color: #efefef; border: 1px solid #ccc; outline: none;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item {display: block; padding-top: 20px; padding-bottom: 20px; padding-left: 10px; padding-right: 10px; border-top: 1px solid #ccc;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item:last-child{border-bottom: 0;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon{width: 100%; color: #555; height: 20px; margin-bottom: 10px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon > .icon{float: left; height: 20px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon > .date { float: right; height: 20px; line-height: 20px; font-size: 13px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon > .right {float: right; height: 20px; line-height: 20px; padding-left: 5px; padding-right: 10px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon > .right > span {color: #000; font-weight: 600; font-size: 13px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon > .right > span:active{color:var(--text_active-color)}

.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body{width: 100%; display: inline-block;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title{ width: 100%; height: 20px; line-height: 20px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > div {float: left;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .level { width: 20px; height: 20px; position: relative; margin-right: 3px; }
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .level > img {width: 20px; height: 20px; position: absolute;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .level > span {width: 20px; height: 20px; line-height: 20px; text-align: center; position: absolute; font-size: 12px; font-weight: 600;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .nickname {font-size: 14px; color: #000; margin-right: 10px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .date {font-size: 14px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right {float: right; margin-left: 15px; color: #999;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right:hover{color:#555}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right:active{color:#000}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > div{float: right;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > .icon{ width: 15px; height: 15px; }
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > .count{font-size: 14px; margin-left: 0px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > span {color: #555; font-size: 14px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > span:active{color:var(--text_active-color)}

.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .body_str {margin-top: 15px; width: 100%;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .body_str > .str {font-size: 14px; color: #000; white-space:pre-line; word-break:break-word;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .body_str > img { max-width: 100%; margin-bottom: 15px;}

.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item_delete > .right_body {margin-bottom: 0px;}
.mobile_post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item_delete > .right_body > .body_str > .str {color:#999}


.mobile_post_reply_body > .bottom_write_reply { width: 100%; margin-top: 10px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); margin-top: 10px; padding-top: 15px;}
.mobile_post_reply_body > .bottom_write_reply > span{ font-size: 15px; font-weight: 600; padding-left: var(--m-margin-side);}
.mobile_post_reply_body > .bottom_write_reply > .title_line{ width: 100%; height: 1px; background-color: var(--mobile-box-inner-border);}
.mobile_post_reply_body > .bottom_write_reply > .mobile_write_reply { padding-left: var(--m-margin-side); padding-right: var(--m-margin-side); margin-bottom: 30px; margin-top: 15px; padding-top: 15px; border-top: 1px solid var(--mobile-box-inner-border);}

.mobile_write_reply {width: 100%; height: 136px;}
.mobile_write_reply > .left {width: 100px; float: left; height: 100%; border:1px solid #ddd; outline: none; }
.mobile_write_reply > .left > .image { width: 100%; height: 100%; position: relative; align-items: center; display: flex; justify-content: center;}
.mobile_write_reply > .left > .image > img {max-height: 100%; max-width: 100%;}
.mobile_write_reply > .left > .image > .loader{ width: 20px; height: 20px; }
.mobile_write_reply > .left > .image > .close { position: absolute; display: flex; right:2px; top:2px; opacity: 0.7; background-color: #fff; border-radius: 20px; color: #000;}
.mobile_write_reply > .left > .image > .close:hover{background-color: #999;}
.mobile_write_reply > .left > .image > .close:active{background-color: #555;}

.mobile_write_reply > .show_image {width: calc(100% - 110px) !important; float: left; margin-left: 10px !important;}
.mobile_write_reply > .right {width: 100%; margin-left: 0px;}
.mobile_write_reply > .right > .first{width: 100%; height: 40px;}
.mobile_write_reply > .right > .first > div {float: left;}
.mobile_write_reply > .right > .first > .nickname { width: calc(100% - 115px); }
.mobile_write_reply > .right > .first > .nickname > input{ width: 100%; height: 40px; border: 1px solid #999; font-size:14px; color: #000; padding-left: 10px; padding-right: 10px; outline: none;}
.mobile_write_reply > .right > .first > .disable > input{ color: #aaa;}
.mobile_write_reply > .right > .first > .img { height: 40px; width: 80px; background-color: #555; margin-top: 2px; margin-left: 35px; text-align: center; }
.mobile_write_reply > .right > .first > .img:active { background-color: #000;}
.mobile_write_reply > .right > .first > .img > span {color: #fff; font-size: 13px; height: 40px; line-height: 40px; }

.mobile_write_reply > .right > .first > .img_area {width: 80px; float: left; height: 45px; margin-top: 0px; margin-left: 35px; border:1px solid #ddd; outline: none; }
.mobile_write_reply > .right > .first > .img_area > .image { width: 100%; height: 100%; position: relative; align-items: center; display: flex; justify-content: center;}
.mobile_write_reply > .right > .first > .img_area > .image > img {max-height: 100%; max-width: 100%;}
.mobile_write_reply > .right > .first > .img_area > .image > .loader{ width: 20px; height: 20px; }
.mobile_write_reply > .right > .first > .img_area > .image > .close { position: absolute; display: flex; right:-7px; top:-7px; opacity: 0.7; background-color: #fff; border-radius: 20px; color: #000;}
.mobile_write_reply > .right > .first > .img_area > .image > .close:hover{background-color: #999;}
.mobile_write_reply > .right > .first > .img_area > .image > .close:active{background-color: #555;}


.mobile_write_reply > .right > .second{width: 100%; height: 80px; margin-top: 15px;}
.mobile_write_reply > .right > .second > div {float: left;}

.mobile_write_reply > .right > .second > .input_body_div {width: calc(100% - 110px); height: 80px;}
.mobile_write_reply > .right > .second > .input_body_div > textarea{width: calc(100% - 4px); height: 58px; border: 1px solid #aaa; padding:10px; font-size:14px; color: #000; outline: none; resize: none;}
.mobile_write_reply > .right > .second > .enter_btn {width: 80px; height: 80px; background-color: #555; margin-left: 30px; border: 1px solid #999; font-size: 14px; font-weight: 400; color: #fff; text-align: center; line-height: 80px;}
.mobile_write_reply > .right > .second > .enter_btn:active{background-color: #000;}
.mobile_write_reply > .right > .second > .enter_btn > .loader{ width: 20px; height: 20px; margin-left: 30px; margin-top: 30px;}


.mobile_post__reply_page_area { width: 100%; text-align: center; height: 60px; line-height: 60px; position: relative; border-top: 1px solid #ccc;}
.mobile_post__reply_page_area > div { display: inline-block;}
.mobile_post__reply_page_area > div > span { font-size: 15px; color: #000; margin: 10px; }
.mobile_post__reply_page_area > div > span:hover{color:var(--text_hover-color)}
.mobile_post__reply_page_area > div > .dot { color:#999; margin:0}
.mobile_post__reply_page_area > div > .dot:hover{color:#999}

.mobile_post__reply_page_area > .next > .page { color:#000; font-size: 15px; }
.mobile_post__reply_page_area > .before > .page { color:#000; font-size: 15px; }
.mobile_post__reply_page_area > .before_year > .page { color:#aaa; font-size: 15px; }
.mobile_post__reply_page_area > .before_year > .page:hover{color:var(--text_hover-color)}
.mobile_post__reply_page_area > .next_year > .page { color:#aaa; font-size: 15px; }
.mobile_post__reply_page_area > .next_year > .page:hover{color:var(--text_hover-color)}
.mobile_post__reply_page_area > .select > .page { color:#f00; font-weight: 600; }

/** End - 게시글 화면
********************************************************************************************************/



/********************************************************************************************************
    공지사항 게시글화면
********************************************************************************************************/

.mobile_notice_post_body > .body_box { width: 100%;  background-color: #fff; border-top: 0; border-bottom: 1px solid var(--mobile-box-border); margin-top: 0; }
.mobile_notice_post_body > .body_box > .contents {min-height: 60px;}
.mobile_notice_post_body > .body_box > .contents > .body_str { width: 100%; font-size: 15px; white-space: pre-line; word-break:break-word; padding: 20px;}

/** End - 공지사항 게시글 화면
********************************************************************************************************/


/********************************************************************************************************
    문의 사항 보기 화면
********************************************************************************************************/

.mobile_inquiry_post {width: 100%; outline: none; padding-bottom: 20px; background-color: #fff; border-bottom: 1px solid var(--mobile-box-border);}
.mobile_inquiry_post > .title_box { border-bottom: 1px solid var(--mobile-box-inner-border); padding-top: 10px; padding-bottom: 10px; padding-left: 20px; padding-right: 20px;}
.mobile_inquiry_post > .title_box > .title { font-size: 15px; font-weight: 600;}
.mobile_inquiry_post > .title_box > .bottom { display: block; height: 20px; line-height: 20px; margin-top: 5px;}
.mobile_inquiry_post > .title_box > .bottom > div { font-size: 15px; color: #000; text-align: center;}
.mobile_inquiry_post > .title_box > .bottom > .type{ float: left; color: #999;}
.mobile_inquiry_post > .title_box > .bottom > .time{ float: right; color: #999;}
.mobile_inquiry_post > .body_box {  width: calc(100% - 40px); margin-left: 20px; margin-top: 20px; color:#000; font-size: 16px; }
.mobile_inquiry_post > .body_box > span { width: calc(100% - 40px); white-space:pre-line; }
.mobile_inquiry_post > .reply_box { background-color: #eee; width: calc(100% - 40px); margin-left: 20px; margin-top: 20px; padding: 20px;}
.mobile_inquiry_post > .reply_box > div {color: #555; font-size: 15px; line-height: 30px;}
.mobile_inquiry_post > .reply_box > div > span {white-space:pre-line;}
.mobile_inquiry_post > .reply_box > .top {font-size: 14px; margin-bottom: 5px;height: 20px; line-height: 20px; }

.mobile_write_reply_area { background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); margin-top: 10px; }
.mobile_write_reply_area > .title { height: 50px; line-height: 50px; font-size: 16px; font-weight: 600; padding-left: 20px;}
.mobile_write_reply_area > textarea{width: calc(100% - 40px); height: 240px; border: 0; border-top: 1px solid var(--mobile-box-inner-border); background-color: #fafafa; padding:20px; font-size:15px; color: #000; outline: none; resize: none;}

/** End - 문의 사항 보기 화면
********************************************************************************************************/


/********************************************************************************************************
    문의하기 작성 화면
********************************************************************************************************/

.mobile_inquiry_write_post {width: 100%; margin-top: 10px; outline: none; padding-bottom: 20px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_inquiry_write_post > .top{ width: 100%; border-bottom: 1px solid #ccc; padding-left: 10px; padding-right: 10px; padding-top: 10px; padding-bottom: 10px;}
.mobile_inquiry_write_post > .top > span{font-size: 20px; font-weight: 600;}
.mobile_inquiry_write_post > .top > .no_login { font-weight: 400; font-size: 15px; color: #f00;}

.mobile_inquiry_write_post > .title{ width: calc(100% - 20px); margin-left: 10px; margin-top: 20px;}
.mobile_inquiry_write_post > .title > div { height: 40px; margin-top: 10px;}
.mobile_inquiry_write_post > .title > div > div {height: 40px; float: left; line-height: 40px;}
.mobile_inquiry_write_post > .title > div > .left {width: 70px;font-size: 15px; color: #000;  font-weight: 600;}
.mobile_inquiry_write_post > .title > div > .right {width: calc(100% - 70px);}
.mobile_inquiry_write_post > .title > div > .right > .select {height: 40px; width: 85px; border: 1px solid #555; padding-left: 10px; font-size: 12px; color: #000;}
.mobile_inquiry_write_post > .title > div > .right > .select_second { width: calc(100% - 95px); margin-left: 10px;}
.mobile_inquiry_write_post > .title > div > .right > input {height: 40px; width: 180px; border: 1px solid #ccc; background-color: #fafafa; outline: none; padding-left: 10px; }
.mobile_inquiry_write_post > .title > div > .right > .input_nickname{width:calc(100% - 15px);}
.mobile_inquiry_write_post > .title > div > .right > .input_title{width:calc(100% - 15px);}
.mobile_inquiry_write_post > .title > div > .right > .disable { color:#ccc; border-color: #aaa;}
.mobile_inquiry_write_post > .title > div > .right > .disable:hover { color:#ccc; background-color: #fff;}

.mobile_inquiry_write_post > .body_textarea {width: calc(100% - 20px); display: block; margin-top: 20px; height: 340px; margin-left: 10px;}
.mobile_inquiry_write_post > .body_textarea > textarea{width: calc(100% - 30px); height: 300px; border: 0; border: 1px solid #ccc; background-color: #fafafa; padding:15px; font-size:15px; color: #000; outline: none; resize: none;}

.mobile_inquiry_write_post > .email { color: #000; background-color: #00000000; width: auto; font-size: 15px; font-weight: 400; margin-top: 10px; padding-left: 10px; padding-right: 10px;}

.mobile_inquiry_write_post_btn_box { width: 100%; margin-top: 10px; height: 50px; padding-left: 10px; padding-right: 10px;}
.mobile_inquiry_write_post_btn_box > div {float: right; width: 60px; height:40px; font-size: 13px; font-weight: 400; background-color: #000; color: #fff; line-height: 40px; text-align: center;}

.mobile_inquiry_write_post_btn_box > .cancel {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 10px;}
.mobile_inquiry_write_post_btn_box > .save {background-color: #555; margin-left: 10px;}
.mobile_inquiry_write_post_btn_box > .save > .loader{width: 20px; height: 20px; margin-top: 15px; margin-left: 10px;}

.mobile_inquiry_write_post_btn_box > .list:hover {background-color: #eee;}
.mobile_inquiry_write_post_btn_box > .cancel:hover {background-color: #eee;}
.mobile_inquiry_write_post_btn_box > .save:hover {background-color: #333;}

.mobile_inquiry_write_post_btn_box > .list:active {background-color: #ddd;}
.mobile_inquiry_write_post_btn_box > .cancel:active {background-color: #ddd;}
.mobile_inquiry_write_post_btn_box > .save:active {background-color: #111;}

/** End - 문의하기 작성 화면
********************************************************************************************************/



/********************************************************************************************************
    글(공지사항) 작성&수정 화면
********************************************************************************************************/

.mobile_write_post {width: 100%; margin-top: 10px; outline: none; padding-bottom: 30px; background-color: #fff; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_write_post > .title{ width: calc(100% - 20px); margin-left: 10px; margin-top: 20px;}
.mobile_write_post > .title > div { height: 40px; margin-top: 10px;}
.mobile_write_post > .title > div > div {height: 40px; float: left; line-height: 40px;}
.mobile_write_post > .title > div > .left {width: 70px;font-size: 15px; color: #000;  font-weight: 600;}
.mobile_write_post > .title > div > .right {width: calc(100% - 70px);}
.mobile_write_post > .title > div > .right > .select {height: 40px; width: 85px; border: 1px solid #555; padding-left: 10px; font-size: 12px; color: #000;}
.mobile_write_post > .title > div > .right > .select_second { width: calc(100% - 95px); margin-left: 10px;}
.mobile_write_post > .title > div > .right > input {height: 40px; width: 180px; border: 1px solid #ccc; background-color: #fafafa; outline: none; padding-left: 10px; }
.mobile_write_post > .title > div > .right > .input_nickname{width:calc(100% - 15px);}
.mobile_write_post > .title > div > .right > .input_title{width:calc(100% - 15px);}
.mobile_write_post > .title > div > .right > .disable { color:#ccc; border-color: #aaa;}
.mobile_write_post > .title > div > .right > .disable:hover { color:#ccc; background-color: #fff;}
.mobile_write_post > .title > .error_msg {margin-left: 75px; font-size: 13px; color: #f00; height: 20px;}

.mobile_write_post > .body_textarea {width: calc(100% - 20px); display: block; margin-top: 20px; height: 340px; margin-left: 10px;}
.mobile_write_post > .body_textarea > textarea{width: calc(100% - 30px); height: 300px; border: 0; border: 1px solid #ccc; background-color: #fafafa; padding:15px; font-size:15px; color: #000; outline: none; resize: none;}

.mobile_write_post > .image_area { width: 100; min-height: 160px; margin-top: 20px;}
.mobile_write_post > .image_area > .title{width: 100%; height: 40px; line-height: 40px; font-size: 15px; color: #000; padding-left: 10px; padding-right: 10px;}
.mobile_write_post > .image_area > .title > .title_str{float: left;  font-weight: 600;}
.mobile_write_post > .image_area > .title > .btn{float: right; text-decoration: underline; }
.mobile_write_post > .image_area > .title > .btn:hover{color: var(--text_hover-color);}
.mobile_write_post > .image_area > .title > .btn:active{color: var(--text_active-color);}
.mobile_write_post > .image_area > .image_box{width: 100%; min-height: 120px; border: 0; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; background-color: #fafafa; padding-top: 20px; padding-left: 20px;}
.mobile_write_post > .image_area > .image_box > div {width: 80px; height: 80px; margin-right: 10px; margin-bottom: 10px; background-color: #eee; border: 0; display: inline-block; position: relative;}
.mobile_write_post > .image_area > .image_box > div > img {width: 80px; height: 80px; border: 0; position: absolute;}
.mobile_write_post > .image_area > .image_box > div > .delete {width: 20px; height: 20px; position: absolute; background-color: #fff; border-radius: 20px; top:2px; right: 2px; opacity: 0.7; color: #000;}
.mobile_write_post > .image_area > .image_box > div > .delete:hover{color: #999;}
.mobile_write_post > .image_area > .image_box > div > .delete:active{color: #555;}
.mobile_write_post > .image_area > .image_box > div > .loader {  position: absolute; width: 20px; height: 20px; margin-left: 30px; margin-top: 30px;}

.mobile_write_post_btn_box { width: 100%; margin-top: 10px; height: 50px; padding-left: 10px; padding-right: 10px;}
.mobile_write_post_btn_box > div {float: right; width: 60px; height:40px; font-size: 13px; font-weight: 400; background-color: #000; color: #fff; line-height: 40px; text-align: center;}
.mobile_write_post_btn_box > .list { float: left; background-color: #fff; border: 1px solid #555; color: #000;}
.mobile_write_post_btn_box > .cancel {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 10px;}
.mobile_write_post_btn_box > .save {background-color: #555; margin-left: 10px;}
.mobile_write_post_btn_box > .save > .loader{width: 20px; height: 20px; margin-top: 10px; margin-left: 20px;}

.mobile_write_post_btn_box > .list:hover {background-color: #eee;}
.mobile_write_post_btn_box > .cancel:hover {background-color: #eee;}
.mobile_write_post_btn_box > .save:hover {background-color: #333;}

.mobile_write_post_btn_box > .list:active {background-color: #ddd;}
.mobile_write_post_btn_box > .cancel:active {background-color: #ddd;}
.mobile_write_post_btn_box > .save:active {background-color: #111;}

/** End - 글(공지사항) 작성&수정 화면
********************************************************************************************************/


/********************************************************************************************************
    정보수정 화면
********************************************************************************************************/

.mobile_user_info {width: 100%; padding-bottom: 30px; background-color: #fff; margin-top: 10px; margin-bottom: 20px; border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border);}
.mobile_user_info > .edit_box{ margin-top: 20px; width: 100%; margin-bottom: 20px;}
.mobile_user_info > .edit_box > .title{ height: 60px; line-height: 60px; font-size: 15px; color: #000; margin-left: 20px;}
.mobile_user_info > .edit_box > .button{height: 50px; width:calc(100% - 40px); line-height: 50px; margin-top: 20px; margin-left: 20px; background-color: #555; color: #fff; font-size: 15px; font-weight: 600; text-align: center;}
.mobile_user_info > .edit_box > .button:hover{ background-color: #666; }
.mobile_user_info > .edit_box > .button:active{ background-color: #777; }
.mobile_user_info > .edit_box > .button > .loader{ width: 20px; height: 20px; left: 50%; transform: translate(-50%, 0); margin-top: 15px; position: absolute;}
.mobile_user_info > .edit_box > .input_box{height: 60px; width:calc(100% - 40px); margin-left: 20px; border: 1px solid #ccc; background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px;}
.mobile_user_info > .edit_box > .input_box > input{ float: left; height: 56px; border: 0; outline: none; font-size: 18px; color:#000; }
.mobile_user_info > .edit_box > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.mobile_user_info > .edit_box > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.mobile_user_info > .edit_box > .input_box > .right_text { float: right; height: 60px; line-height: 60px; width: 70px; text-align: end; font-size: 18px; color: #555; text-decoration: underline;}
.mobile_user_info > .edit_box > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.mobile_user_info > .edit_box > .input_box > .right_text:active{ color: var(--text_active-color);}
.mobile_user_info > .edit_box > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.mobile_user_info > .edit_box > .input_top{border-end-start-radius: 0; border-end-end-radius: 0; border-bottom: 0;}
.mobile_user_info > .edit_box > .input_middle{border-radius: 0; border-bottom: 0;}
.mobile_user_info > .edit_box > .input_bottom{border-start-start-radius: 0; border-start-end-radius: 0;}
.mobile_user_info > .withdraw_box { width: 100%; margin-top: 50px; text-align: center; color: #555; text-decoration: underline;}

/** End - 정보수정 화면
********************************************************************************************************/


/********************************************************************************************************
    회원가입 화면
********************************************************************************************************/

.mobile_sign_up_agree{width: 100%; background-color: #fff; border-bottom: 1px solid var(--mobile-box-border);}
.mobile_sign_up_agree > .title{color: #000; font-size: 20px; height: 40px; line-height: 40px; margin-left: 20px; padding-top: 20px;}
.mobile_sign_up_agree > .agree_title{color: #000; height: 50px; margin-top: 10px; line-height: 50px; font-size: 15px; margin-left: 20px; font-weight: 600;}
.mobile_sign_up_agree > .divider{background-color: var(--mobile-box-inner-border); height: 1px; width: 100%;}
.mobile_sign_up_agree > .item_box {width: 100%; margin-top: 20px; position: relative;}
.mobile_sign_up_agree > .item_box > .top {width: fit-content; margin-left: 10px;}
.mobile_sign_up_agree > .item_box > .top >.ck_box{float: left; height: 20px; width: 20px; margin-top: 5px; color: #ccc;}
.mobile_sign_up_agree > .item_box > .top >.ck_select{color: #0a0;}
.mobile_sign_up_agree > .item_box > .top >.title{display: inline-block; height: 30px; line-height: 30px; font-size: 15px; color: #000; margin-left: 10px;}
.mobile_sign_up_agree > .item_box > .top:hover > .ck_box{color: #aaa;}
.mobile_sign_up_agree > .item_box > .top:hover > .ck_select{color: #090;}
.mobile_sign_up_agree > .item_box > .top:hover > .title{color:var(--text_hover-color)}
.mobile_sign_up_agree > .item_box > .top:active > .ck_box{color: #999;}
.mobile_sign_up_agree > .item_box > .top:active > .ck_select{color: #080;}
.mobile_sign_up_agree > .item_box > .top:active > .title{color:var(--text_active-color)}
.mobile_sign_up_agree > .item_box > .next_btn{position: absolute; top:-5px; right:10px; width: 80px; height: 40px; line-height: 40px; text-align: center; background-color: #555;color:#fff; font-size: 15px; font-weight: 600;}
.mobile_sign_up_agree > .item_box > .next_btn:hover{background-color: #333;}
.mobile_sign_up_agree > .item_box > .next_btn:active{background-color: #111;}

.mobile_sign_up_agree > .all_agree { height: 50px;}

.mobile_sign_up_agree > .item_box > .middle{ width: calc(100% - 20px); min-height: 100px; border: 1px solid #ccc; margin-top: 10px; margin-left: 10px;}
.mobile_sign_up_agree > .item_box > .middle > .use_agreement{ width: 100%; height: 250px; overflow-y: scroll; padding: 10px;}
.mobile_sign_up_agree > .item_box > .middle > .use_agreement > span{ width: 960px; color: #000; font-size: 13px; white-space:pre-line; }
.mobile_sign_up_agree > .item_box > .middle > .use_agreement_loading{ width: 100%; height: 145px;}
.mobile_sign_up_agree > .item_box > .middle > .use_agreement_loading > div{width: 40px; height: 40px; margin-left: 460px; margin-top: 105px;}

.mobile_sign_up_agree > .item_box > .middle > .personal > table { width: 100%; border-bottom: 1px solid #ccc; border-collapse : collapse;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > tr > td{text-align: center; color: #555; font-size: 13px;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > tr > .left { border-right: 1px solid #ccc; width: 20%; white-space:pre-line;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > tr > .right { width: 80%;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > .title {background-color: #f5f5f5; height: 60px;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > .title > td{color: #000; font-weight: 600;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > .item { border-top: 1px solid #ccc;}
.mobile_sign_up_agree > .item_box > .middle > .personal > table > .item > .right{ text-align: start; padding: 10px; padding-top: 20px; padding-bottom: 20px; white-space:pre-line;}
.mobile_sign_up_agree > .item_box > .middle > .personal > .bottom_text{ margin: 10px;}
.mobile_sign_up_agree > .item_box > .middle > .personal > .bottom_text > span{ font-size: 13px; color: #555; white-space:pre-line;}
.mobile_sign_up_agree > .item_box > .middle > .choice_agree{ padding: 10px;}
.mobile_sign_up_agree > .item_box > .middle > .choice_agree > div > span {color: #000; font-size: 13px;}
.mobile_sign_up_agree > .item_box > .middle > .choice_agree > .middle{margin-top: 20px; margin-bottom: 20px;}


.mobile_sign_up_input { width: 100%; margin-left: 0px;}
.mobile_sign_up_input > .box_title { height: 60px; color: #000; font-size: 15px; font-weight: 600; border-top: 1px solid var(--mobile-box-border); background-color: #fff; padding-left: 20px; line-height: 60px;margin-top: 10px; position: relative;}
.mobile_sign_up_input > .input_box{height: 60px; border-top: 1px solid var(--mobile-box-inner-border); border-bottom: 1px solid var(--mobile-box-inner-border); background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px;}
.mobile_sign_up_input > .input_box > input{ float: left; height: 56px; border: 0; outline: none; font-size: 15px; color:#000; background-color: #00000000; }
.mobile_sign_up_input > .input_box > input:disabled{background-color: #fafafa;}
.mobile_sign_up_input > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.mobile_sign_up_input > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.mobile_sign_up_input > .input_box > .right_text { float: right; height: 60px; line-height: 60px; font-weight: 600; width: 70px; text-align: end; font-size: 15px; color: #555;}
.mobile_sign_up_input > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.mobile_sign_up_input > .input_box > .right_text:active{ color: var(--text_active-color);}
.mobile_sign_up_input > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.mobile_sign_up_input > .input_box_focus { border: 1px solid #555; }
.mobile_sign_up_input > .disabled{background-color: #fafafa;}
.mobile_sign_up_input > .input_top{border-radius: 0; border-bottom: 0;}
.mobile_sign_up_input > .input_middle{border-radius: 0; border-bottom: 0;}
.mobile_sign_up_input > .input_bottom{border-radius: 0;}

.mobile_sign_up_input > .certification_box{ width: 100%; height: 60px; border-top: 1px solid var(--mobile-box-inner-border); border-bottom: 1px solid var(--mobile-box-border); background-color: #f9faff; padding-left: 20px; padding-right: 20px;}
.mobile_sign_up_input > .certification_box > .cer_button{ float: right; width: 70px; height: 40px; line-height: 40px; margin-top: 10px; background-color: #555; font-size: 13px; font-weight: 600; text-align: center; color: #fff; border-radius: 5px;}
.mobile_sign_up_input > .certification_box > .cer_button:hover{background-color: #333;}
.mobile_sign_up_input > .certification_box > .cer_button:active{background-color: #111;}

.mobile_sign_up_input > .certification_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.mobile_sign_up_input > .certification_box > .left {float: left; height: 60px; line-height: 60px; color:#000; font-size: 15px;}
.mobile_sign_up_input > .certification_box > .success { float: right; right: 0; height: 60px; top:0; color: #090; font-size: 15px; font-weight: 600; position: relative;}
.mobile_sign_up_input > .certification_box > .success > span {line-height: 60px; }
.mobile_sign_up_input > .certification_box > .success > div {width: 20px; height: 20px; position: absolute; top: 20px; right: 60px;}

.mobile_sign_up_input > .button_area { width: 200px; height: 50px; margin-top: 20px; margin-left: 50%; transform: translate(-50%, 0);}
.mobile_sign_up_input > .button_area > div {float: left; width: 80px; height:40px; font-size: 13px; margin-left: 10px; margin-right: 10px; font-weight: 600; background-color: #000; color: #fff; line-height: 40px; text-align: center;}
.mobile_sign_up_input > .button_area > .cancel {background-color: #555;}
.mobile_sign_up_input > .button_area > .sign_up {background-color: #0000AA;}
.mobile_sign_up_input > .button_area > .cancel:hover {background-color: #333;}
.mobile_sign_up_input > .button_area > .sign_up:hover {background-color: #000088;}
.mobile_sign_up_input > .button_area > .cancel:active {background-color: #111;}
.mobile_sign_up_input > .button_area > .sign_up:active {background-color: #000066;}

/** End - 회원가입 화면
********************************************************************************************************/


/********************************************************************************************************
    아이디 / 비밀번호 찾기 화면
********************************************************************************************************/

.mobile_find { width: 100%; background-color: #fff;}
.mobile_find > .title{color: #000; font-size: 15px; height: 40px; line-height: 40px; margin-top: 20px;}
.mobile_find > .box{border-top: 1px solid var(--mobile-box-border); border-bottom: 1px solid var(--mobile-box-border); width: 100%; padding-left: 20px; padding-right: 20px; margin-top: 10px; outline: none;}
.mobile_find > .box > .item{ }
.mobile_find > .box > .item > .tab { height: 60px;}
.mobile_find > .box > .item > .tab > .checkbox { float: left; width: 20px; height: 20px; margin-top: 20px; color: #aaa;}
.mobile_find > .box > .item > .tab > .tab_title { float: left; font-size: 15px; height: 60px; line-height: 60px; color: #aaa; margin-left: 10px;}
.mobile_find > .box > .item > .tab:hover { background-color: #00000005;}
.mobile_find > .box > .item > .tab:hover > .checkbox { color: #777; }
.mobile_find > .box > .item > .tab:hover > .tab_title { color: #777; }
.mobile_find > .box > .item > .item_body{ margin-top: 10px; margin-bottom: 30px; }
.mobile_find > .box > .item > .item_body > input{ height: 50px; width:495px; background-color: #00000000; padding-left: 20px; font-size: 15px; color: #000; padding-right: 20px; border: 1px solid #aaa; outline: none; border-radius: 5px; font-size: 18px; color:#000; background-color: #fff; margin-bottom: 20px; }
.mobile_find > .box > .item > .item_body > .input_disable {background-color: #eee; color: #777;}


.mobile_find > .box > .item > .item_body > .input_box{height: 60px; border: 1px solid #ccc; background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px; margin-bottom: 20px;}
.mobile_find > .box > .item > .item_body > .input_box > input{ float: left; height: 56px; border: 0; background-color: #00000000; outline: none; font-size: 15px; color:#000; }
.mobile_find > .box > .item > .item_body > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.mobile_find > .box > .item > .item_body > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.mobile_find > .box > .item > .item_body > .input_box > .right_text { float: right; height: 60px; line-height: 60px; width: 70px; text-align: end; font-size: 15px; color: #555; text-decoration: underline;}
.mobile_find > .box > .item > .item_body > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.mobile_find > .box > .item > .item_body > .input_box > .right_text:active{ color: var(--text_active-color);}
.mobile_find > .box > .item > .item_body > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.mobile_find > .box > .item > .item_body > .disabled{ background-color: #eee;}

.mobile_find > .box > .item > .tab_focus{ background-color: #fff;}
.mobile_find > .box > .item > .tab_focus > .checkbox {color: #0a0;}
.mobile_find > .box > .item > .tab_focus > .tab_title {color: #000;}
.mobile_find > .box > .item > .tab_focus:hover > .checkbox { color: #0a0; }
.mobile_find > .box > .item > .tab_focus:hover > .tab_title { color: #000; }
.mobile_find > .box > .item > .title{ height: 50px; text-align: center; line-height: 50px; font-size: 15px; margin-top: 10px;}
.mobile_find > .box > .second{margin-top: 0; border-top: 1px solid var(--mobile-box-inner-border);}

.mobile_find > .box > .id_result{text-align: center; margin:40px; font-size: 20px; margin-top: 30px;}
.mobile_find > .box > .id_result > .title{ display: block; color: #555; margin-bottom: 20px;}
.mobile_find > .box > .id_result > .id{ display: block; color: #000; font-weight: 600;}

/** End - 아이디 / 비밀번호 찾기 화면
********************************************************************************************************/