/********************************************************************************************************
    각종 폰트
    JalnanGothicTTF.ttf
    Jalnan2TTF.ttf
    Gothic.ttf
    GothicBold.ttf
********************************************************************************************************/
@font-face {
    font-family: "LogoFont";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Jalnan2TTF.ttf") format("woff");
}
@font-face {
    font-family: "DefaultFont";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Gothic.ttf") format("woff");
}

/********************************************************************************************************
    공통으로 사용되는 Element
********************************************************************************************************/

html, body { margin: 0; padding: 0; height: 100%; overscroll-behavior: contain; font-family: "DefaultFont"; } 
input {font-family: "DefaultFont";}
textarea {font-family: "DefaultFont";}
select {
    font-family: "DefaultFont"; 
    -o-appearance: none; -webkit-appearance: none; -moz-appearance: none; appearance: none;
    background: url('../arrow_bottom.png') calc(100% - 10px) center no-repeat;
    background-size: 10px;
    background-color: #fff; 
    cursor: pointer;
} /*-o-appearance: none; -webkit-appearance: none; -moz-appearance: none; appearance: none;*/
select:hover{background-color: #fafafa;}
/* IE 10, 11의 네이티브 화살표 숨기기 */
select::-ms-expand { display: none; }

/* 글자입력창 X 버튼 삭제 - IE의 경우 */
input::-ms-clear, 
input::-ms-reveal{ display:none; }
/* 글자입력창 X 버튼 삭제 - 크롬의 경우 */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration{ display:none; }

ul{list-style-type: none;list-style: none; padding: 0px; margin:0px}

/* 내부 테두리로 만드는 구문 */
div {
box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
}

.default_btn { background-color: #000;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */}
.default_btn:hover { background-color: #333;}
.default_btn:active { background-color: #555;}

.color_red {color: #f00 !important}
.color_blue {color: #00f !important}
.color_green {color: #0a0 !important}
.bold {font-weight: 600;}

/* 텍스트 선택 막음 */
.block_text_select {
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* 변수 선언 */
:root {
    --text_hover-color: #00c; /* 마우스 오버시 글자 색상*/
    --text_active-color: #00f; /* 클릭시 글자 색상*/
    --middle-width: 1000px; /* 중간영역 너비 */
    --admin-list-divide-bg:#a0b6ff20; /* 관리자 리스트 구분을 위한 배경색 */
    --admin-list-hover-bg:#fafafa; /* 관리자 리스트 구분을 위한 배경색 */
}

.button_default {font-size: 16px; color: #fff; background-color: #000; position: relative; font-weight: 600; cursor: pointer; height: 50px; line-height: 50px; text-align: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.button_default:hover{background-color: #333;}
.button_default:active{background-color: #111;}
.button_default > .loader{ position: absolute; width: 20px; height: 20px; left: 50%; top:50%; transform: translate(-50%, -50%); }

.ad_bg {background-color: #fafafa; border:1px solid #eee;}

.datepicker-custom-input-disabled { display:inline-block; height: 30px; font-size:13px; width:40px; cursor: pointer; text-align:center; color:#999;text-decoration:underline; }
/** End - 공통으로 사용되는 Element
********************************************************************************************************/


.header { border-bottom:solid 1px #CCCCCC; height: 100px;}
.header > .header_body { height: 100%; width: var(--middle-width); margin-left: calc(50% - 500px); position: relative;}
.header > .header_body > .header_title_area{ height: 100%; font-size: 35px; color: #000;}
.header > .header_body > .header_title_area > div { float: left; height: 100%; }
.header > .header_body > .header_title_area > div > span {line-height: 100px;}
.header > .header_body > .header_title_area > .logo { font-family: "LogoFont"; cursor: pointer; }
.header > .header_body > .header_title_area > .sub_title { font-size: 25px; margin-left: 20px; color: #333;}
.header > .header_body > .header_search_area{position: absolute; border: solid 1px #000000; right: 0; top: 25px; height: 50px; line-height: 50px; width: 300px;}
.header > .header_body > .header_search_area > .select{position: absolute; width: 70px; height: 48px; size:17px; border:0; top:0; color:#555; outline: none; padding-left: 5px; }
.header > .header_body > .header_search_area > .search_input{ position: absolute; border: 0; size:17px; height: 48px; width:180px; top:0; left:80px;outline: none;}
.header > .header_body > .header_search_area > .search_btn_area{ position: absolute; right: 0; height: 50px; width: 40px; cursor: pointer;}
.header > .header_body > .header_search_area > .search_btn_area > .search_btn{ position: absolute; right: 10px; top:15px;color: #777;}
.header > .header_body > .header_search_area > .search_btn_area > .search_btn:hover{color: #000;}

.top_tab { border-bottom:solid 1px #555; height: 80px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.top_tab > .top_tab_body { height: 100%; width: var(--middle-width); margin-left: calc(50% - 500px); position: relative;}
.top_tab > .top_tab_body > .tab_item { float: left; color: #000000; height: 100%; line-height: 80px; padding-left: 15px; padding-right: 15px; cursor: pointer;}
.top_tab > .top_tab_body > .tab_item > .icon { position: relative; padding-top: 25px; height: 100%; width: 30px; }
.top_tab > .top_tab_body > .tab_item > .icon > svg { position: absolute;}
.top_tab > .top_tab_body > .tab_item > span { font-size: 20px; }
.top_tab > .top_tab_body > .tab_item_select{ float: left; color: #ff0000; height: 80px; line-height: 80px; padding-left: 15px; padding-right: 15px; border-bottom: 4px solid #f00;}
.top_tab > .top_tab_body > .tab_item_select > span { font-size: 20px; font-weight: 600;}

.body_div {width: 100%; padding-bottom: 50px; position: relative;}
.body_div > table { width: 1540px; margin-top: 20px; margin-left: calc(50% - 770px); border-collapse : collapse;}
.body_div > table > tr { width: 1540px;}
.body_div > table > tr > .left { width:270px; }
.body_div > table > tr > .left > .left_ad{ width: 200px; height: 600px; margin-left: 40px; position: absolute; line-height: 300px; text-align: center;}
.body_div > table > tr > .left > .left_ad > span { font-size: 20px; }

.body_div > table > tr > .middle { width: var(--middle-width); }
.body_div > table > tr > .middle > .middle_area { width: var(--middle-width); }
.body_div > table > tr > .middle > .middle_area > .top_ad{ height: 100px; width: var(--middle-width); line-height: 100px; text-align: center;}
.body_div > table > tr > .middle > .middle_area > .top_ad > span { font-size: 20px; }
.body_div > table > tr > .middle > .middle_area > .middle_ad{ height: 100px; width: var(--middle-width); line-height: 100px; text-align: center;}
.body_div > table > tr > .middle > .middle_area > .middle_ad > span { font-size: 20px; }
.body_div > table > tr > .middle > .middle_area > .top_banner{ height: 100px; width: var(--middle-width); position: relative; margin-bottom: 20px;}
.body_div > table > tr > .middle > .middle_area > .top_banner > img{ height: 100px; width: var(--middle-width); position:absolute; border-radius: 5px; }
.body_div > table > tr > .middle > .middle_area > .top_banner > div{ height: 100px; width: var(--middle-width); border: 1px solid #ddd; border-radius: 5px; position:absolute; cursor: pointer;}
.body_div > table > tr > .middle > .middle_area > .top_banner > div:hover{background-color: #00000010;}

.body_div > table > tr > .right { width:270px; }
.body_div > table > tr > .right > .right_area { width: 250px; margin-left: 20px;}
.body_div > table > tr > .right > .right_area > span { font-size: 20px; }

.bottom_company_info { width: var(--middle-width); color: #000;}
.bottom_company_info > .divider { width: var(--middle-width); height: 5px; background-color: #000; margin-top: 50px;}
.bottom_company_info > .top{ text-align: center; font-size: 20px;margin-top: 20px;}
.bottom_company_info > .top > .link{ cursor: pointer;}
.bottom_company_info > .top > .link:hover{ color: var(--text_hover-color)}
.bottom_company_info > .top > .dot{ margin-left: 5px; margin-right: 5px;}
.bottom_company_info > .info{ text-align: center; font-size: 16px;margin-top: 20px;}
.bottom_company_info > .copyright{ text-align: center; font-size: 16px;margin-top: 20px;}
.bottom_company_info > .copyright > .red{color: #f00;}

/********************************************************************************************************
    화면 오른쪽 구역
********************************************************************************************************/

.body_div > table > tr > .right > .right_area > .login_box { height: 211px; width: 230px; border: 1px solid #000; }
.body_div > table > tr > .right > .right_area > .login_box > .input_id { height: 40px; width: 180px; border: 1px solid #000; margin-left: 20px; margin-top: 20px; padding-left: 10px; }
.body_div > table > tr > .right > .right_area > .login_box > .input_pw { height: 40px; width: 180px; border: 1px solid #000; margin-left: 20px; margin-top: 10px; padding-left: 10px; }
.body_div > table > tr > .right > .right_area > .login_box > .login_btn{ height: 40px; width: 190px; margin-left: 20px; margin-top: 10px; position: relative; cursor: pointer; color:#fff; font-size: 16px; text-align: center; align-items: center; line-height: 40px;}
.body_div > table > tr > .right > .right_area > .login_box > .login_btn > .loader{ width: 20px; height: 20px; margin-left: 85px; margin-top: 10px; position: absolute;}
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area{height: 20px; width: 190px; margin-left: 20px; color:#000; margin-top: 10px;  position: relative; font-size: 13px;}
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .sign_up_btn{height: 20px; line-height: 20px; cursor: pointer; position: absolute;}
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .sign_up_btn:hover{ color: var(--text_hover-color) }
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .bottom_right_area{height: 20px; line-height: 20px; right: 0; position: absolute;}
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .bottom_right_area >.find_id{cursor: pointer; }
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .bottom_right_area >.find_id:hover{ color: var(--text_hover-color)}
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .bottom_right_area >.find_divide{ margin-left: 5px; margin-right: 5px;}
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .bottom_right_area >.find_pw{cursor: pointer; }
.body_div > table > tr > .right > .right_area > .login_box > .bottom_area> .bottom_right_area >.find_pw:hover{ color: var(--text_hover-color)}

.body_div > table > tr > .right > .right_area > .user_box { width: 230px; border: 1px solid #000; }
.body_div > table > tr > .right > .right_area > .user_box > .loader{ width: 20px; height: 20px; margin-left: 105px; margin-top: 10px; margin-bottom: 10px; }
.body_div > table > tr > .right > .right_area > .user_box > .info_area { width: 210px; height: 50px; margin-left: 10px; margin-top: 10px; position: relative; }
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .level { width: 50px; height: 50px; position: absolute; display: inline-block; }
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .level > img {width: 50px; height: 50px; position: absolute;}
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .level > span {width: 50px; height: 50px; line-height: 50px; text-align: center; position: absolute; font-size: 15px; font-weight: 600;}
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info { width: 150px; height: 50px; margin-left: 60px; display: inline-block; position: absolute; }
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info > div {height: 20px; line-height: 20px; margin-top: 5px;}
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info > .name { font-size: 14px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info > .update_info { font-size: 13px; }
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info > .update_info > span {cursor: pointer; text-decoration: underline;}
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info > .update_info > span:hover{ color: var(--text_hover-color)}
.body_div > table > tr > .right > .right_area > .user_box > .info_area > .info > .update_info > .dot{ text-decoration: dotted; margin-left: 5px; cursor: default;}
.body_div > table > tr > .right > .right_area > .user_box > .logout_btn{ height: 30px; width: 210px; margin-left: 10px; margin-top: 10px; margin-bottom: 10px; position: relative; cursor: pointer; color:#fff; font-size: 13px; text-align: center; align-items: center; line-height: 30px;}
.body_div > table > tr > .right > .right_area > .user_box > .logout_btn > .loader{ width: 20px; height: 20px; margin-left: 85px; margin-top: 10px; position: absolute;}
.body_div > table > tr > .right > .right_area > .user_box > .divider { height: 1px; width: 230px; background-color: #bbb; margin-bottom: 10px; }
.body_div > table > tr > .right > .right_area > .user_box > .post_info_area { width: 210px; margin-left: 10px; padding-top: 5px; padding-bottom: 10px; border:1px solid #ccc; background-color: #fafafa;}
.body_div > table > tr > .right > .right_area > .user_box > .post_info_area > div { height: 20px; width: 210px; line-height: 20px; position:relative; margin-top: 5px;}
.body_div > table > tr > .right > .right_area > .user_box > .post_info_area > div > span{ font-size: 15px; color: #000;}
.body_div > table > tr > .right > .right_area > .user_box > .post_info_area > div > .title{ margin-left: 20px;}
.body_div > table > tr > .right > .right_area > .user_box > .post_info_area > div > .count{ right: 20px; position:absolute;}
.body_div > table > tr > .right > .right_area > .user_box > .bottom_area{height: 20px; width: 210px; margin-left: 10px; color:#000; margin-top: 10px; margin-bottom: 5px;  position: relative; font-size: 13px;}
.body_div > table > tr > .right > .right_area > .user_box > .bottom_area> .my_post{height: 20px; line-height: 20px; cursor: pointer; position: absolute; text-decoration: underline;}
.body_div > table > tr > .right > .right_area > .user_box > .bottom_area> .my_post:hover{ color: var(--text_hover-color) }
.body_div > table > tr > .right > .right_area > .user_box > .bottom_area> .bottom_right_area{height: 20px; line-height: 20px; right: 0; position: absolute;}
.body_div > table > tr > .right > .right_area > .user_box > .bottom_area> .bottom_right_area >.inquiry{cursor: pointer; text-decoration: underline; }
.body_div > table > tr > .right > .right_area > .user_box > .bottom_area> .bottom_right_area >.inquiry:hover{ color: var(--text_hover-color)}

.body_div > table > tr > .right > .right_area > .write_btn { height: 50px; width: 230px; line-height: 50px; margin-top: 20px; position: relative; cursor: pointer; color:#fff; font-size: 15px; text-align: center; align-items: center;}

.body_div > table > tr > .right > .right_area > .list_box { width: 230px; margin-top: 10px; }
.body_div > table > tr > .right > .right_area > .list_box > .title_box{ height: 45px; padding-left: 10px; position: relative;}
.body_div > table > tr > .right > .right_area > .list_box > .title_box > span { font-size: 16px; line-height: 45px; font-weight: 600; position: absolute; }
.body_div > table > tr > .right > .right_area > .list_box > .title_box > .right_area { font-size: 16px; position: absolute; right:10px; height: 45px;line-height: 45px;  }
.body_div > table > tr > .right > .right_area > .list_box > .title_box > .right_area > span { color: #000; font-size: 14px; font-weight: 400; cursor: pointer; text-decoration: underline;}
.body_div > table > tr > .right > .right_area > .list_box > .title_box > .right_area :hover{ color: var(--text_hover-color)}
.body_div > table > tr > .right > .right_area > .list_box > .list_area{border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; background-color: #fafafa; padding-top: 10px; padding-bottom: 10px;}
.body_div > table > tr > .right > .right_area > .list_box > .list_area > ul { margin-left: 10px; margin-right: 10px;}
.body_div > table > tr > .right > .right_area > .list_box > .list_area > ul > li { color: #000; font-size: 16px; height: 30px; width: 210px; line-height: 30px; cursor: pointer; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.body_div > table > tr > .right > .right_area > .list_box > .list_area > ul > li:hover{ color: var(--text_hover-color)}
.body_div > table > tr > .right > .right_area > .list_box > .list_area > ul > .focus { color: #f00;  cursor: default;}
.body_div > table > tr > .right > .right_area > .list_box > .list_area > ul > .focus:hover{ color: #f00 }

.body_div > table > tr > .right > .right_area > .right_bottom_ad{ height: 300px; width: 230px; margin-top: 20px; line-height: 600px; text-align: center;}
.body_div > table > tr > .right > .right_area > .right_bottom_ad > span { font-size: 20px; }

.body_div > table > tr > .right > .right_area > .visit { width: 230px; text-align: center; margin-top:5px; margin-bottom: 5px; }
.body_div > table > tr > .right > .right_area > .visit > span {font-size:13px; font-weight: 500; color:#555;}
.body_div > table > tr > .right > .right_area > .visit > .blue {font-weight: 600; color:#00f;}
.body_div > table > tr > .right > .right_area > .visit > .red {font-weight: 600; color:#f00;}

/** End - 화면 오른쪽 구역
********************************************************************************************************/



/********************************************************************************************************
    메인화면
********************************************************************************************************/
.home__best_area{ width: var(--middle-width); vertical-align: top; border-collapse : collapse; margin-bottom: 20px; }
.home__best_area > tr > .title { width: 480px; height: 50px; text-align: start; padding-left: 15px; font-size: 20px; font-weight: 600; }
.home__best_area > tr > .middle { width: 20px;}
.home__best_area > tr > .box { width: 480px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; background-color: #FAFAFA; padding-top: 5px; padding-bottom: 5px;}
.home__best_area > tr > .box > .list_area {width: 480px;}
.home__best_area > tr > .box > .list_area > ul { margin-left: 10px; margin-right: 10px;}
.home__best_area > tr > .box > .list_area > ul > li { color: #000; display: block; font-size: 15px; margin-top: 5px; margin-bottom: 5px; height: 30px; width: 475px; line-height: 30px; cursor: pointer; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.home__best_area > tr > .box > .list_area > ul > li > .best_body_div {max-width: 410px; float: left; overflow:hidden; text-overflow:ellipsis; vertical-align: middle;}
.home__best_area > tr > .box > .list_area > ul > li > .best_body_div > .cate_name { color:#4d3f3f}
.home__best_area > tr > .box > .list_area > ul > li > .best_body_div > .title { color:#000; margin-left: 3px;}
.home__best_area > tr > .box > .list_area > ul > li > .reply { color:#f00; margin-left: 5px;}
.home__best_area > tr > .box > .list_area > ul > li > .choice_image {width: 20px; height: 20px; float: left; margin-left: 5px; margin-top: 5px; }
.home__best_area > tr > .box > .list_area > ul > li:hover > .best_body_div > .cate_name{ color: var(--text_hover-color)}
.home__best_area > tr > .box > .list_area > ul > li:hover > .best_body_div > .title{ color: var(--text_hover-color)}
.home__best_area > tr > .box > .empty{ font-size: 18px; color: #999; text-align: center;}


.home__post_area{ width: var(--middle-width); border: 1px solid #ccc;}
.home__post_area > .tab_box{width: var(--middle-width); height: 50px; border-bottom: 1px solid #ccc;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.home__post_area > .tab_box > .tab_item{height: 50px; line-height: 50px; display: inline-block; color:#000; padding-left: 30px; padding-right: 30px; font-weight: 600; font-size: 20px; cursor: pointer;}
.home__post_area > .tab_box > .tab_item:hover{color:var(--text_hover-color)}
.home__post_area > .tab_box > .select{background-color: #f00; color:#fff; cursor: default;}
.home__post_area > .tab_box > .select:hover{color:#fff;}

.home__post_area > .post_box { width: var(--middle-width); min-height: 100px;}
.home__post_area > .post_box > .empty{ height: 100px; line-height: 100px; text-align: center; font-size: 18px; color: #999;}
.home__post_area > .post_box > ul > li { max-height: 140px; border-bottom: 1px solid #eee; padding-top: 20px; padding-bottom: 20px; cursor: pointer;}
.home__post_area > .post_box > ul > li:hover{background-color: #eee;}
.home__post_area > .post_box > ul > li:active{background-color: #ddd;}
.home__post_area > .post_box > ul > li:last-child{ border-bottom: 0;}
.home__post_area > .post_box > ul > li > div{max-height: 100px; margin-left: 30px; width: 940px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.home__post_area > .post_box > ul > li > div > .body_image{width: 100px; height: 100px; float: left; margin-right: 20px;}
.home__post_area > .post_box > ul > li > div > .choice_image{width: 100px; height: 100px; float: left; margin-left: 20px;}
.home__post_area > .post_box > ul > li > div > .text_body{ float: left;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_title{ height: 20px;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .best_icon{ float: left; height: 20px; width:50px; margin-right: 10px; background-color: #f00; position: relative; border-radius: 5px; }
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .best_icon > .best_span{ height: 20px; width: 50px; text-align: center; color: #fff; position: absolute; font-weight: 600; font-size: 15px; line-height: 20px;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .title{float: left; font-size: 16px; font-weight: 600; height: 20px; line-height: 20px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_title > .reply{ color:#f00; font-size: 16px; margin-left: 5px;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_body{ max-lines: 2; overflow:hidden; display: -webkit-box; white-space:pre-line; text-overflow:ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical; margin-top: 10px; }
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_body > span{ font-size: 16px; color: #000;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom{ margin-top: 5px; height:20px; line-height: 20px;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > span{ font-size: 14px; color:#999; height:20px; line-height: 20px;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > .category{ font-size: 13px; color:#f00;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_bottom > .divider{ margin-left: 5px; margin-right: 5px;}
.home__post_area > .post_box > ul > li > div > .text_body > .text_body_delete{ font-size: 16px; color: #999; }

.home__post_page_area { width: var(--middle-width); text-align: center; height: 100px; line-height: 100px; position: relative;}
.home__post_page_area > div { display: inline-block;}
.home__post_page_area > div > span { font-size: 16px; color: #000; margin: 10px; cursor: pointer; }
.home__post_page_area > div > span:hover{color:var(--text_hover-color)}
.home__post_page_area > div > .dot { color:#999; cursor: default; margin:0}
.home__post_page_area > div > .dot:hover{color:#999}

.home__post_page_area > .next > .page { color:#000; font-size: 16px; }
.home__post_page_area > .before > .page { color:#000; font-size: 16px; }
.home__post_page_area > .before_year > .page { color:#aaa; font-size: 16px; }
.home__post_page_area > .before_year > .page:hover{color:var(--text_hover-color)}
.home__post_page_area > .next_year > .page { color:#aaa; font-size: 16px; }
.home__post_page_area > .next_year > .page:hover{color:var(--text_hover-color)}
.home__post_page_area > .select > .page { color:#f00; font-weight: 600; cursor:default; }

.home__category_area { border: solid 1px #ccc;border-collapse : collapse; width: var(--middle-width); }
.home__category_area > tr > th { width: 500px; height: 50px; text-align: start; padding-left: 20px; background-color: #FAFAFA; font-size: 20px; }
.home__category_area > tr > td { width: 500px; padding-left: 10px; padding-right: 10px; padding-top: 15px;}
.home__category_area > tr > td > div { padding-left: 10px; font-size: 20px; color: #242222; padding-right: 10px; display: inline-block; cursor: pointer; margin-bottom: 15px;}
.home__category_area > tr > td > div:hover{color:var(--text_hover-color)}

.home_bottom_ad_area { width:var(--middle-width); margin-top: 20px; }
.home_bottom_ad_area > .ad{ height: 400px; width: 490px; line-height: 400px; text-align: center; display: inline-block;}
.home_bottom_ad_area > .ad > span { font-size: 20px; }
.home_bottom_ad_area > .right{ margin-left: 20px;}

.search_top {height: 50px; line-height: 50px; margin-bottom: 0px; font-size: 20px;}



.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area { height: 50px; line-height: 50px;}
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > div { display: inline-block;}
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > div > span { font-size: 14px; color: #000; margin: 5px; cursor: pointer; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > div > span:hover{color:var(--text_hover-color)}
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > div > .dot { color:#999; cursor: default; margin:0}
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > div > .dot:hover{color:#999}

.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .next > .page { color:#000; font-size: 14px; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .before > .page { color:#000; font-size: 14px; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .before_year > .page { color:#aaa; font-size: 14px; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .before_year > .page:hover{color:var(--text_hover-color)}
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .next_year > .page { color:#aaa; font-size: 14px; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .next_year > .page:hover{color:var(--text_hover-color)}
.admin_mobile_body_div > .middle_area > .admin_list_box > .home__post_page_area > .select > .page { color:#f00; font-weight: 600; cursor:default; }

/** End - 메인화면
********************************************************************************************************/


/********************************************************************************************************
    사연 & 커뮤니티 공통
********************************************************************************************************/

.post_home__best_area{ width: var(--middle-width); vertical-align: top; border-collapse : collapse; }
.post_home__best_area > .title_box{ width: var(--middle-width); height: 45px; vertical-align: top; border-collapse : collapse; border-bottom: 1px solid #555; }
.post_home__best_area > .title_box > .title{ height: 45px; font-size: 20px; font-weight: 600; line-height: 45px; float: left;}
.post_home__best_area > .title_box > .sort{ height: 45px; display: inline-block; margin-left: 15px; line-height: 45px; }
.post_home__best_area > .title_box > .sort > div{ display: inline-block; font-size: 16px; color:#999; height: 45px; cursor: pointer;  }
.post_home__best_area > .title_box > .sort > div:hover{color:var(--text_hover-color)}
.post_home__best_area > .title_box > .sort > div > div {display: inline-block; }
.post_home__best_area > .title_box > .sort > div > .span_div{ position: relative; width: 50px; height: 45px;}
.post_home__best_area > .title_box > .sort > div > .span_div > div{ position: absolute;}
.post_home__best_area > .title_box > .sort > div > .select > div{ color: #f00; font-weight: 600;}
.post_home__best_area > .title_box > .sort > div > .dot{ position: relative; width: 10px; height: 45px;}
.post_home__best_area > .title_box > .sort > div > .dot > div{ position: absolute; }
.post_home__best_area > .title_box > .sort > div > .check_div { width: 17px;height: 45px; position: relative;}
.post_home__best_area > .title_box > .sort > div > .check_div > .check{color:#f00; position: absolute; top:3px;  width: 15px; height: 15px;}

.post_home__best_area > .title_box > .right{ height: 45px; float: right; position: relative;}
.post_home__best_area > .title_box > .right > .time_box{ position: absolute; right: 210px; height: 45px; width: 200px; }
.post_home__best_area > .title_box > .right > .time_box > div{float: right; }
.post_home__best_area > .title_box > .right > .time_box > .time{ height: 45px; line-height: 45px; font-size: 15px; color: #000; }
.post_home__best_area > .title_box > .right > .time_box > .left_btn{ height: 45px; width: 40px; line-height: 45px; text-align: center; font-size: 16px; color: #000; cursor: pointer; }
.post_home__best_area > .title_box > .right > .time_box > .right_btn{ height: 45px; width: 40px; line-height: 45px; text-align: center; font-size: 16px; color: #000; cursor: pointer; }
.post_home__best_area > .title_box > .right > .time_box > .left_btn:hover{color: #f00}
.post_home__best_area > .title_box > .right > .time_box > .right_btn:hover{color: #f00}
.post_home__best_area > .title_box > .right > .time_box > .left_btn:active{color: #a00}
.post_home__best_area > .title_box > .right > .time_box > .right_btn:active{color: #a00}
.post_home__best_area > .title_box > .right > .time_box > .disable_btn {color:#ccc}
.post_home__best_area > .title_box > .right > .time_box > .disable_btn:hover {color:#ccc}
.post_home__best_area > .title_box > .right > .time_box > .disable_btn:active {color:#ccc}

.post_home__best_area > .title_box > .right > .period_box{ position: absolute; right: 0; width:210px; }
.post_home__best_area > .title_box > .right > .period_box > .period{ position: absolute; top:0; font-size: 16px; width: 70px; text-align: center; font-weight: 600; color:#000; height: 45px; cursor: pointer; right: 0;  }
.post_home__best_area > .title_box > .right > .period_box > .period:hover > span{color: var(--text_hover-color);}
.post_home__best_area > .title_box > .right > .period_box > .period > span { height: 45px; line-height: 45px; }
.post_home__best_area > .title_box > .right > .period_box > .period_1{left: 0;}
.post_home__best_area > .title_box > .right > .period_box > .period_2{left: 70px;}
.post_home__best_area > .title_box > .right > .period_box > .period_3{left: 140px;}
.post_home__best_area > .title_box > .right > .period_box > .select{ border: 1px solid #555; border-bottom: 1px solid #fff; color:#f00;}
.post_home__best_area > .title_box > .right > .period_box > .select:hover > span{color: #f00;}

.post_home__best_area > .title_box > .right > .period_box > .period_divider{ position: absolute; display: inline-block; height: 20px; width: 1px; top:12.5px; background-color: #ccc;}
.post_home__best_area > .title_box > .right > .period_box > .divider_1{ left:70px }
.post_home__best_area > .title_box > .right > .period_box > .divider_2{ left:140px }


.post_home__best_area > .post_box { width: var(--middle-width); border-bottom: 1px solid #555; }
.post_home__best_area > .post_box > table{vertical-align: top; border-collapse : collapse;}
.post_home__best_area > .post_box > table > tr > td {height: 50px;}
.post_home__best_area > .post_box > table > tr > .divider { width: 40px; }
.post_home__best_area > .post_box > table > tr > .post { width: 480px; cursor: pointer;}
.post_home__best_area > .post_box > table > tr > .border_bottom { border-bottom: 1px solid #ccc; }
.post_home__best_area > .post_box > table > tr > .post:hover{background-color: #fafafa;}
.post_home__best_area > .post_box > table > tr > .post:active{background-color: #eee;}
.post_home__best_area > .post_box > table > tr > .post > div > div { float: left; overflow:hidden;}
.post_home__best_area > .post_box > table > tr > .post > div > .ranking{ width: 40px; height: 50px; line-height: 50px;}
.post_home__best_area > .post_box > table > tr > .post > div > .ranking > span{ font-size: 20px; font-weight: 600; color: #000;}
.post_home__best_area > .post_box > table > tr > .post > div > .body{ max-width: 375px; font-size: 16px;height: 50px; line-height: 50px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
.post_home__best_area > .post_box > table > tr > .post > div > .body > .category{color: #777; height: 50px; line-height: 50px;}
.post_home__best_area > .post_box > table > tr > .post > div > .body > .title{color: #000; width: 100px; margin-left: 5px; height: 50px; line-height: 50px;}
.post_home__best_area > .post_box > table > tr > .post > div > .reply{color: #f00; margin-left: 5px; height: 50px; line-height: 50px;}

.post_home__best_area > .post_box > .empty { width: var(--middle-width); height: 50px; background-color: #fafafa; text-align: center; }
.post_home__best_area > .post_box > .empty > span { width: var(--middle-width); height: 50px; line-height: 50px; font-size: 15px; color: #999;}

.post_home__list_area { width: var(--middle-width); margin-top: 35px;}
.post_home__list_area > .category{ height: 50px; line-height: 50px; color: #000; font-size: 20px; font-weight: 600; border-bottom: 1px solid #555;}
.post_home__list_area > .best{color: #f00;}
.post_home__list_area > table { width: var(--middle-width);  border-collapse : collapse;}
.post_home__list_area > table > tr {width:var(--middle-width); font-size: 15px;}
.post_home__list_area > table > tr > td {text-align: center; color:#555}
.post_home__list_area > table > tr > .title {width: 530px; color:#000}
.post_home__list_area > table > tr > .writer {width: 150px;}
.post_home__list_area > table > tr > .insert_time {width: 120px;}
.post_home__list_area > table > tr > .hits {width: 100px;}
.post_home__list_area > table > tr > .likes {width: 100px;}
.post_home__list_area > table > tr:last-child{border-bottom: 1px solid #555;}
.post_home__list_area > table > .table_header{ height: 60px; border-bottom: 1px solid #555; font-size: 15px; font-weight: 600;}
.post_home__list_area > table > .table_item{ height: 50px; border-bottom: 1px solid #ccc; cursor: pointer;}
.post_home__list_area > table > .table_item:hover{background-color: #fafafa;}
.post_home__list_area > table > .table_item:active{background-color: #eee;}
.post_home__list_area > table > .table_item > .title {text-align: start;}
.post_home__list_area > table > .table_item > .title > div {width: 510px; margin-left: 10px;}
.post_home__list_area > table > .table_item > .title > div > div{ float: left;}
.post_home__list_area > table > .table_item > .title > div > .notice { background-color: #00a; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.post_home__list_area > table > .table_item > .title > div > .notice > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.post_home__list_area > table > .table_item > .title > div > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.post_home__list_area > table > .table_item > .title > div > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.post_home__list_area > table > .table_item > .title > div > .best_delete { background-color: #ff000020;}
.post_home__list_area > table > .table_item > .title > div > .title_body {  overflow:hidden; max-width: 360px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.post_home__list_area > table > .table_item > .title > div > .title_body > .category{color: #555; margin-right: 5px;}
.post_home__list_area > table > .table_item > .title > div > .title_body > .title{color: #000;}
.post_home__list_area > table > .table_item > .title > div > .title_body > .delete{color: #ccc;}
.post_home__list_area > table > .table_item > .title > div > .title_body > .delete_line{color: #ccc; text-decoration:line-through;}
.post_home__list_area > table > .table_item > .title > div > .reply{float: left; color: #f00;}
.post_home__list_area > table > .table_item > .title > div > .picture{width: 20px; height: 20px; margin-left: 5px;}
.post_home__list_area > table > .table_item > .title > div > .choice_image {float: left; width: 20px; height: 20px; margin-left: 5px;}
.post_home__list_area > table > .table_item > .title > div > .choice_image_delete {opacity: 0.3;}
.post_home__list_area > table > .table_item > .title > div > .new { float: left; background-color: #f00; width: 20px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-left: 5px;}
.post_home__list_area > table > .table_item > .title > div > .new > span { font-size: 11px; color: #fff; width: 20px; font-weight: 600;}

.post_home__list_area > table > .empty_item{ height: 150px; background-color: #fafafa; }
.post_home__list_area > table > .empty_item > td { font-size: 15px; color: #aaa;}

/** End - 사연 & 커뮤니티 공통
********************************************************************************************************/


/********************************************************************************************************
    공지사항 메인
********************************************************************************************************/
.notice_post_home__list_area { margin-top: 0px;}
.notice_post_home__list_area > table > tr > .title {width: 780px; color:#000}
.notice_post_home__list_area > table > tr > .title > div {width: 760px !important;}
.notice_post_home__list_area > table > tr > .insert_time {width: 120px; color:#000}
.notice_post_home__list_area > table > tr > .hits {width: 100px; color:#000}

/** End - 공지사항 메인
********************************************************************************************************/


/********************************************************************************************************
    문의사항 메인
********************************************************************************************************/
.inquiry_home__list_area { margin-top: 0px;}
.inquiry_home__list_area > table > tr > .title {width: 680px; color:#000}
.inquiry_home__list_area > table > tr > .title > div {width: 660px !important;}
.inquiry_home__list_area > table > tr > .insert_time {width: 120px; color:#000}
.inquiry_home__list_area > table > tr > .type {width: 100px; color:#000}
.inquiry_home__list_area > table > tr > .reply {width: 100px; color:#000}

/** End - 문의사항 메인
********************************************************************************************************/


/********************************************************************************************************
    차단한 유저 리스트
********************************************************************************************************/
.block_user__list_area { margin-top: 0px; }
.block_user__list_area > table > tr > td{ cursor: default; }
.block_user__list_area > table > tr > .nickname {width: 680px; color:#000}
.block_user__list_area > table > tr > .nickname > div {width: 680px !important;}
.block_user__list_area > table > tr > .id {width: 220px; color:#000}
.block_user__list_area > table > tr > .cancel {width: 100px; color:#000; cursor: pointer;}
.block_user__list_area > table > tr > .cancel > svg{margin-top: 5px; color: #c00;}
.block_user__list_area > table > tr > .cancel:hover > svg{color: #f00;}

.block_user__list_area > table > .table_item:hover{background-color: #fff;}
.block_user__list_area > table > .table_item:active{background-color: #fff;}
/** End - 차단한 유저 리스트
********************************************************************************************************/


/********************************************************************************************************
    내가쓴글 메인
********************************************************************************************************/
.my_post__list_area { margin-top: 0px;}
.my_post__list_area > table > tr > .title {width: 680px; color:#000}
.my_post__list_area > table > tr > .title > div {width: 660px !important;}
.my_post__list_area > table > tr > .insert_time {width: 120px; color:#000}
.my_post__list_area > table > tr > .hits {width: 100px; color:#000}
.my_post__list_area > table > tr > .likes {width: 100px; color:#000}
/** End - 내가쓴글 메인
********************************************************************************************************/


/********************************************************************************************************
    게시글화면
********************************************************************************************************/
.post_body { width: var(--middle-width); margin-bottom: 20px;}
.post_body > .top{ width: var(--middle-width); height: 50px; position: relative;}
.post_body > .top > .category{ position: absolute; font-size: 16px; color: #000; font-weight: 600; height: 50px; margin-left: 5px; line-height: 50px;}
.post_body > .top > .right { position: absolute; right: 0; height: 50px; }
.post_body > .top > .right > div {line-height: 50px; font-size: 16px; color: #000; display: inline-block; padding-left: 5px; padding-right: 5px; cursor: pointer;}
.post_body > .top > .right > div > div{float: left;}
.post_body > .top > .right > div > .icon{ margin-top: 3px;}
.post_body > .top > .right > .list { margin-right: 10px;}
.post_body > .top > .right > .list > .icon{ margin-right: 3px;}
.post_body > .top > .right > .before > .icon{ margin-right: 3px;}
.post_body > .top > .right > .next > .icon{ margin-left: 3px;}
.post_body > .top > .right > div:hover{color:var(--text_hover-color)}
.post_body > .top > .right > .disable { color:#aaa; cursor: default;}
.post_body > .top > .right > .disable:hover { color:#aaa;}

.post_body > .title_box { margin-top: 0px; min-height: 110px; border-top: 1px solid #555; border-bottom: 1px solid #999; padding-top: 15px; padding-bottom: 15px; }
.post_body > .title_box > .choice {width: 80px;height: 80px; margin-left: 20px; float: left; }
.post_body > .title_box > .body_box {width: 860px;min-height: 80px; display: inline-block; margin-left: 20px; }
.post_body > .title_box > .body_box > div{width: 100%; }
.post_body > .title_box > .body_box > .top { min-height: 40px; }
.post_body > .title_box > .body_box > .top > .best { float: left; background-color: #f00; width: 35px; height: 20px; margin-top: 10px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 10px;}
.post_body > .title_box > .body_box > .top > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.post_body > .title_box > .body_box > .top > .title { display: inline-block; width: 815px; margin-top: 7px; font-size: 18px; color: #000; }
.post_body > .title_box > .body_box > .bottom { height: 40px; }
.post_body > .title_box > .body_box > .bottom > .level { margin-top: 7.5px; float: left; width: 32px;}
.post_body > .title_box > .body_box > .bottom > .level > .level { width: 25px; height: 25px; position: absolute; display: inline-block; }
.post_body > .title_box > .body_box > .bottom > .level > .level > img {width: 25px; height: 25px; position: absolute;}
.post_body > .title_box > .body_box > .bottom > .level > .level > span {width: 25px; height: 25px; line-height: 25px; text-align: center; position: absolute; font-size: 12px; font-weight: 600;}
.post_body > .title_box > .body_box > .bottom > .nickname { float: left; height: 40px; line-height: 40px; font-size: 18px; color: #999; }
.post_body > .title_box > .body_box > .bottom > .time { float: left; height: 40px; line-height: 40px; font-size: 18px; color: #999; margin-left: 10px; }
.post_body > .title_box > .body_box > .bottom > .hits_title{ float: right; height: 40px; line-height: 40px; font-size: 18px; color: #999; margin-right: 10px; }
.post_body > .title_box > .body_box > .bottom > .hits{ float: right; height: 40px; line-height: 40px; font-size: 18px; color: #f00; }

.post_body > .body_box { width: 100%;}
.post_body > .body_box > .contents {min-height: 300px;}
.post_body > .body_box > .contents > .body_str { width: 100%; font-size: 16px; margin-top: 40px; white-space: pre-line; line-height: 30px;}
.post_body > .body_box > .contents > .image > img { max-width: var(--middle-width); display: block; margin-top: 40px;}
.post_body > .body_box > .contents > .delete_box{ width: 450px; height: 100px; margin-left: 275px; margin-top: 30px; border: 1px solid #ccc; border-radius: 20px; background-color: #fafafa; position: relative;}
.post_body > .body_box > .contents > .delete_box > div {display: inline-block; position: absolute;}
.post_body > .body_box > .contents > .delete_box > .guide { height: 50px; left: 20px; top: 25px; font-size: 18px; line-height: 25px; color:#555; width: 320px; }
.post_body > .body_box > .contents > .delete_box > .guide > span{  display: block; }
.post_body > .body_box > .contents > .delete_box > .delete_btn { right: 20px; top:25px; width: 100px; height: 50px; font-size: 15px; font-weight: 600; line-height: 50px; text-align: center; background-color: #f00; color: #fff; cursor:pointer}
.post_body > .body_box > .contents > .delete_box > .delete_btn:hover {background-color: #e00;}
.post_body > .body_box > .contents > .delete_box > .delete_btn:active {background-color: #d00;}

.post_body > .body_box > .contents > .choice_box{ width: var(--middle-width); height: 500px; margin-top: 30px; border-radius: 20px; background-color: #CA1329; position: relative;}
.post_body > .body_box > .contents > .choice_box > div { position: absolute;}
.post_body > .body_box > .contents > .choice_box > .youtube{ width: 740px; height: 380px; top:40px; left: 40px; background-color: #fff;}
.post_body > .body_box > .contents > .choice_box > .youtube_button{ cursor: pointer; width: 140px; height: 48px; right: 40px; top:40px; background-color: #fff; color: #f00; border-radius: 10px;}
.post_body > .body_box > .contents > .choice_box > .youtube_button > .icon {float: left; height: 48px; padding-top: 15px; margin-left: 15px;}
.post_body > .body_box > .contents > .choice_box > .youtube_button > .text {float: left; font-size: 20px; height: 48px; line-height: 48px; font-weight: 600; margin-left: 5px;}
.post_body > .body_box > .contents > .choice_box > .youtube_button:hover {background-color: #ffcccc;}
.post_body > .body_box > .contents > .choice_box > .youtube_button:active {background-color: #ffaaaa;}
.post_body > .body_box > .contents > .choice_box > .choice_image{ width: 140px; height: 140px; right: 40px; bottom: 40px;}
.post_body > .body_box > .contents > .choice_box > .text{ height: 80px; left: 40px; line-height: 80px; font-size: 20px; color: #fff; bottom: 0;}

.post_body > .body_box > .like_tate_box { width: 100%; text-align: center; margin-top: 100px; height: 50px; position: relative;}
.post_body > .body_box > .like_tate_box > div {position: absolute;}
.post_body > .body_box > .like_tate_box > .count {height: 50px; line-height: 50px; font-size: 25px; color: #555; font-weight: 600; }
.post_body > .body_box > .like_tate_box > .count_left { right:calc(50% + 90px)}
.post_body > .body_box > .like_tate_box > .count_right { left:calc(50% + 90px)}
.post_body > .body_box > .like_tate_box > .btn { height: 50px;width: 50px; margin-left: 10px; margin-right: 10px; cursor: pointer; }
.post_body > .body_box > .like_tate_box > .btn > span {display: block; height: 20px; margin-top: 2px; font-size: 15px; color: #777; font-weight: 600;}
.post_body > .body_box > .like_tate_box > .btn > .icon {display: block; color: #777; margin-left: 8px;}
.post_body > .body_box > .like_tate_box > .btn:hover > span{color: #555}
.post_body > .body_box > .like_tate_box > .btn:hover > .icon{color: #555}
.post_body > .body_box > .like_tate_box > .btn:active > span{color: #000}
.post_body > .body_box > .like_tate_box > .btn:active > .icon{color: #000}
.post_body > .body_box > .like_tate_box > .btn_left { right:calc(50% + 10px)}
.post_body > .body_box > .like_tate_box > .btn_right { left:calc(50% + 10px)}

.post_body > .body_box > .bottom{ width: var(--middle-width); margin-top: 50px; height: 50px; position: relative; border-bottom: 1px solid #999;}
.post_body > .body_box > .bottom > .share{ position: absolute; font-size: 16px; color: #000; height: 50px; margin-left: 5px; line-height: 50px; cursor: pointer;}
.post_body > .body_box > .bottom > .share > div{float: left;}
.post_body > .body_box > .bottom > .share > .icon{ margin-top: 3px; margin-right: 3px; }
.post_body > .body_box > .bottom > .share:hover{color:var(--text_hover-color)}

.post_body > .body_box > .bottom > .right { position: absolute; right: 0; height: 50px; }
.post_body > .body_box > .bottom > .right > div {line-height: 50px; font-size: 16px; color: #000; display: inline-block; padding-left: 5px; padding-right: 5px; cursor: pointer;}
.post_body > .body_box > .bottom > .right > div > div{float: left;}
.post_body > .body_box > .bottom > .right > div > .icon{ margin-top: 3px;}
.post_body > .body_box > .bottom > .right > .report {margin-right: 10px; color:#f00}
.post_body > .body_box > .bottom > .right > .report > .icon{ margin-right: 3px;}
.post_body > .body_box > .bottom > .right > .before > .icon{ margin-right: 3px;}
.post_body > .body_box > .bottom > .right > .next > .icon{ margin-left: 3px;}
.post_body > .body_box > .bottom > .right > div:hover{color:var(--text_hover-color)}
.post_body > .body_box > .bottom > .right > .disable { color:#aaa; cursor: default;}
.post_body > .body_box > .bottom > .right > .disable:hover { color:#aaa;}
.post_body > .body_box > .bottom > .right > .report:hover{color:#c00}
.post_body > .body_box > .bottom > .right > .report:active{color:#a00}

.post_body > .btn_box { width: 100%; margin-top: 20px; height: 50px;}
.post_body > .btn_box > div {float: right; width: 100px; height:50px; font-size: 15px; font-weight: 600; background-color: #000; color: #fff; line-height: 50px; text-align: center; cursor: pointer;}
.post_body > .btn_box > .list {float: left; background-color: #555;}
.post_body > .btn_box > .rollback {background-color: #0000AA;}
.post_body > .btn_box > .choice {background-color: #CA1329; margin-left: 20px;}
.post_body > .btn_box > .delete {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 20px;}
.post_body > .btn_box > .edit {background-color: #555; margin-left: 20px;}

.post_body > .btn_box > .list:hover {background-color: #333;}
.post_body > .btn_box > .rollback:hover {background-color: #000088;}
.post_body > .btn_box > .choice:hover {background-color: #ad1022;}
.post_body > .btn_box > .delete:hover {background-color: #eee;}
.post_body > .btn_box > .edit:hover {background-color: #333;}

.post_body > .btn_box > .list:active {background-color: #111;}
.post_body > .btn_box > .rollback:active {background-color: #000066;}
.post_body > .btn_box > .choice:active {background-color: #830d1a;}
.post_body > .btn_box > .delete:active {background-color: #ddd;}
.post_body > .btn_box > .edit:active {background-color: #111;}

.post_bottom_list_body { width: var(--middle-width);}

.post_bottom_list_body > table { width: var(--middle-width);  border-collapse : collapse; margin-top: 70px;}
.post_bottom_list_body > table > tr {width:var(--middle-width); font-size: 15px;}
.post_bottom_list_body > table > tr > td {text-align: center; color:#555}
.post_bottom_list_body > table > tr > .title {width: 530px; color:#000}
.post_bottom_list_body > table > tr > .writer {width: 150px;}
.post_bottom_list_body > table > tr > .insert_time {width: 120px;}
.post_bottom_list_body > table > tr > .hits {width: 100px;}
.post_bottom_list_body > table > tr > .likes {width: 100px;}
.post_bottom_list_body > table > tr:last-child{border-bottom: 1px solid #555;}
.post_bottom_list_body > table > .table_header{ height: 60px; border-bottom: 1px solid #555; border-top: 1px solid #555; font-size: 15px; font-weight: 600;}
.post_bottom_list_body > table > .table_item{ height: 50px; border-bottom: 1px solid #ccc; cursor: pointer;}
.post_bottom_list_body > table > .table_item:hover{background-color: #fafafa;}
.post_bottom_list_body > table > .table_item:active{background-color: #eee;}
.post_bottom_list_body > table > .table_item > .title {text-align: start;}
.post_bottom_list_body > table > .table_item > .title > div {width: 510px; margin-left: 10px;}
.post_bottom_list_body > table > .table_item > .title > div > div{ float: left;}
.post_bottom_list_body > table > .table_item > .title > div > .notice { background-color: #00a; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.post_bottom_list_body > table > .table_item > .title > div > .notice > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.post_bottom_list_body > table > .table_item > .title > div > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.post_bottom_list_body > table > .table_item > .title > div > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.post_bottom_list_body > table > .table_item > .title > div > .best_delete { background-color: #ff000020;}
.post_bottom_list_body > table > .table_item > .title > div > .title_body {  max-width: 360px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.post_bottom_list_body > table > .table_item > .title > div > .title_body > .category{color: #555; margin-right: 5px;}
.post_bottom_list_body > table > .table_item > .title > div > .title_body > .title{color: #000;}
.post_bottom_list_body > table > .table_item > .title > div > .title_body > .delete{color: #ccc;}
.post_bottom_list_body > table > .table_item > .title > div > .title_body > .delete_line{color: #ccc; text-decoration:line-through;}
.post_bottom_list_body > table > .table_item > .title > div > .reply{float: left; color: #f00;}
.post_bottom_list_body > table > .table_item > .title > div > .picture{width: 20px; height: 20px; margin-left: 5px;}
.post_bottom_list_body > table > .table_item > .title > div > .new { float: left; background-color: #f00; width: 20px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-left: 5px;}
.post_bottom_list_body > table > .table_item > .title > div > .new > span { font-size: 11px; color: #fff; width: 20px; font-weight: 600;}
.post_bottom_list_body > table > .table_item > .title > div > .choice_image {float: left; width: 20px; height: 20px; margin-left: 5px;}
.post_bottom_list_body > table > .table_item > .title > div > .choice_image_delete {opacity: 0.3;}

.post_bottom_list_body > table > .focus{background-color: #0000AA10; cursor: default;}
.post_bottom_list_body > table > .focus:hover{background-color: #0000AA10;}
.post_bottom_list_body > table > .empty_item{ height: 150px; background-color: #fafafa; }
.post_bottom_list_body > table > .empty_item > td { font-size: 15px; color: #aaa;}

.post_reply_body { width:var(--middle-width); margin-top: 30px;}
.post_reply_body > .reply_list { width: var(--middle-width);}
.post_reply_body > .reply_list > .title {height: 30px; line-height: 30px; font-size: 20px; color: #000;}
.post_reply_body > .reply_list > .title > .count {color: #f00; margin-left: 5px;}
.post_reply_body > .reply_list > .title_line{ width: var(--middle-width); height: 1px; background-color: #555; margin-top: 10px; }
.post_reply_body > .reply_list > .empty_item{ height: 100px; line-height: 100px; background-color: #fafafa; text-align: center; font-size: 18px; color: #aaa; border-bottom: 1px solid #ccc;}
.post_reply_body > .reply_list > .reply_item { width: var(--middle-width); padding-top: 20px; padding-bottom: 20px; border-bottom: 1px solid #ccc;}
.post_reply_body > .reply_list > .reply_item > .title { width: 100%; height: 30px; line-height: 30px;}
.post_reply_body > .reply_list > .reply_item > .title > div {float: left;}

.post_reply_body > .reply_list > .reply_item > .title > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; margin-top: 5px; border-radius: 5px; text-align: center; margin-right: 10px;}
.post_reply_body > .reply_list > .reply_item > .title > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.post_reply_body > .reply_list > .reply_item > .title > .level { width: 20px; height: 20px; position: relative; margin-right: 3px; margin-top: 5px;}
.post_reply_body > .reply_list > .reply_item > .title > .level > img {width: 20px; height: 20px; position: absolute;}
.post_reply_body > .reply_list > .reply_item > .title > .level > span {width: 20px; height: 20px; line-height: 20px; text-align: center; position: absolute; font-size: 12px; font-weight: 600;}
.post_reply_body > .reply_list > .reply_item > .title > .nickname {font-size: 16px; color: #000; margin-right: 10px;}
.post_reply_body > .reply_list > .reply_item > .title > .date {font-size: 16px; color: #999;}
.post_reply_body > .reply_list > .reply_item > .title > .right {float: right; margin-left: 15px; color: #999; cursor: pointer;}
.post_reply_body > .reply_list > .reply_item > .title > .right:hover{color:#555}
.post_reply_body > .reply_list > .reply_item > .title > .right:active{color:#000}
.post_reply_body > .reply_list > .reply_item > .title > .right > div{float: right;}
.post_reply_body > .reply_list > .reply_item > .title > .right > .icon{ width: 20px; height: 20px; margin-top: 5px;}
.post_reply_body > .reply_list > .reply_item > .title > .right > .count{font-size: 20px; margin-left: 5px;}
.post_reply_body > .reply_list > .reply_item > .title > .right > span {color: #555; font-weight: 600; font-size: 15px;}
.post_reply_body > .reply_list > .reply_item > .title > .right > span:hover{color:var(--text_hover-color)}
.post_reply_body > .reply_list > .reply_item > .title > .right > span:active{color:var(--text_active-color)}
.post_reply_body > .reply_list > .reply_item > .reply_item_body {width: 100%; margin-top: 15px; margin-bottom: 20px;}
.post_reply_body > .reply_list > .reply_item > .reply_item_body > .body_str {font-size: 15px; color: #000; white-space:pre-line; line-height: 25px;}
.post_reply_body > .reply_list > .reply_item > .reply_item_body > img { max-width: 800px; margin-bottom: 15px;}

.post_reply_body > .reply_list > .reply_item_delete > .reply_item_body {margin-bottom: 0px;}
.post_reply_body > .reply_list > .reply_item_delete > .reply_item_body > .body_str {color:#999}

.post_reply_body > .reply_list > .reply_item > .re_reply_area { width: 100%;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .count { height: 20px; line-height: 20px; cursor: pointer; }
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .count > div { float: left; font-size: 16px; color: #000;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .count > .count { font-size: 16px; color: #f00; margin-left: 5px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .count > .icon { margin-left: 5px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .enter_area {padding: 20px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list {margin-top: 30px; background-color: #efefef; border: 1px solid #ccc; outline: none;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item {display: block; padding-top: 20px; padding-bottom: 20px; border-top: 1px solid #ccc;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item:last-child{border-bottom: 0;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .left_icon{width: 40px; margin-left: 20px; float: left; color: #555;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body{width: 920px; display: inline-block;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title{ width: 100%; height: 20px; line-height: 20px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > div {float: left;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .level { width: 20px; height: 20px; position: relative; margin-right: 3px; }
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .level > img {width: 20px; height: 20px; position: absolute;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .level > span {width: 20px; height: 20px; line-height: 20px; text-align: center; position: absolute; font-size: 12px; font-weight: 600;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .nickname {font-size: 16px; color: #000; margin-right: 10px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .date {font-size: 16px; color: #999;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right {float: right; margin-left: 15px; color: #999; cursor: pointer;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right:hover{color:#555}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right:active{color:#000}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > div{float: right;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > .icon{ width: 20px; height: 20px; }
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > .count{font-size: 16px; margin-left: 0px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > span {color: #555; font-size: 15px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > span:hover{color:var(--text_hover-color)}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .title > .right > span:active{color:var(--text_active-color)}

.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .body_str {margin-top: 15px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .body_str > .str {font-size: 16px; color: #000; white-space:pre-line;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item > .right_body > .body_str > img { max-width: 800px; margin-bottom: 15px;}

.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item_delete > .right_body {margin-bottom: 0px;}
.post_reply_body > .reply_list > .reply_item > .re_reply_area > .re_reply_list > .re_reply_item_delete > .right_body > .body_str > .str {color:#999}


.post_reply_body > .bottom_write_reply { width: var(--middle-width); }
.post_reply_body > .bottom_write_reply > span{ font-size: 20px; font-weight: 600;}
.post_reply_body > .bottom_write_reply > .title_line{ width: var(--middle-width); height: 1px; background-color: #555; margin-top: 15px; margin-bottom: 20px;}

.write_reply {width: 100%; height: 156px;}
.write_reply > .left {width: 150px; float: left; height: 100%; border:1px solid #ddd; outline: none; }
.write_reply > .left > .image { width: 100%; height: 100%; position: relative; align-items: center; display: flex; justify-content: center;}
.write_reply > .left > .image > img {max-height: 100%; max-width: 100%;}
.write_reply > .left > .image > .loader{ width: 20px; height: 20px; }
.write_reply > .left > .image > .close { position: absolute; display: flex; right:2px; top:2px; opacity: 0.7; cursor: pointer; background-color: #fff; border-radius: 20px; color: #000;}
.write_reply > .left > .image > .close:hover{background-color: #999;}
.write_reply > .left > .image > .close:active{background-color: #555;}

.write_reply > .show_image {width: calc(100% - 170px) !important; float: left; margin-left: 20px !important;}
.write_reply > .right {width: 100%; margin-left: 0px;}
.write_reply > .right > .first{width: 100%; height: 40px;}
.write_reply > .right > .first > div {float: left;}
.write_reply > .right > .first > .nickname > input{ width: 220px; height: 40px; border: 1px solid #999; font-size:16px; color: #000; padding-left: 10px; padding-right: 10px; outline: none;}
.write_reply > .right > .first > .disable > input{ color: #999;}
.write_reply > .right > .first > .img { cursor: pointer; height: 40px; background-color: #555; margin-top: 2px; margin-left: 20px; font-size: 14px; padding-left: 15px; padding-right: 15px; }
.write_reply > .right > .first > .img:hover { background-color: #333; }
.write_reply > .right > .first > .img:active { background-color: #000;}
.write_reply > .right > .first > .img > span {color: #fff; font-size: 14px; height: 40px; line-height: 40px; }

.write_reply > .right > .second{width: 100%; height: 100px; margin-top: 15px;}
.write_reply > .right > .second > div {float: left;}

.write_reply > .right > .second > .input_body_div {width: calc(100% - 150px); height: 100px;}
.write_reply > .right > .second > .input_body_div > textarea{width: calc(100% - 4px); height: 78px; border: 1px solid #999; padding:10px; font-size:16px; color: #000; outline: none; resize: none;}
.write_reply > .right > .second > .enter_btn { cursor: pointer;width: 120px; height: 100px; background-color: #555; margin-left: 30px; border: 1px solid #999; font-size: 16px; font-weight: 600; color: #fff; text-align: center; line-height: 100px;}
.write_reply > .right > .second > .enter_btn:hover{background-color: #333;}
.write_reply > .right > .second > .enter_btn:active{background-color: #000;}
.write_reply > .right > .second > .enter_btn > .loader{ width: 20px; height: 20px; margin-left: 50px; margin-top: 40px;}


.post__reply_page_area { width: var(--middle-width); text-align: center; height: 60px; line-height: 60px; position: relative; border-top: 1px solid #ccc;}
.post__reply_page_area > div { display: inline-block;}
.post__reply_page_area > div > span { font-size: 16px; color: #000; margin: 10px; cursor: pointer; }
.post__reply_page_area > div > span:hover{color:var(--text_hover-color)}
.post__reply_page_area > div > .dot { color:#999; cursor: default; margin:0}
.post__reply_page_area > div > .dot:hover{color:#999}

.post__reply_page_area > .next > .page { color:#000; font-size: 16px; }
.post__reply_page_area > .before > .page { color:#000; font-size: 16px; }
.post__reply_page_area > .before_year > .page { color:#aaa; font-size: 16px; }
.post__reply_page_area > .before_year > .page:hover{color:var(--text_hover-color)}
.post__reply_page_area > .next_year > .page { color:#aaa; font-size: 16px; }
.post__reply_page_area > .next_year > .page:hover{color:var(--text_hover-color)}
.post__reply_page_area > .select > .page { color:#f00; font-weight: 600; cursor: default; }

/** End - 게시글 화면
********************************************************************************************************/


/********************************************************************************************************
    공지사항 게시글화면
********************************************************************************************************/
.notice_post_bottom_list_body { width: var(--middle-width);}

.notice_post_bottom_list_body > table { width: var(--middle-width);  border-collapse : collapse; margin-top: 70px;}
.notice_post_bottom_list_body > table > tr {width:var(--middle-width); font-size: 15px;}
.notice_post_bottom_list_body > table > tr > td {text-align: center; color:#555}
.notice_post_bottom_list_body > table > tr > .title {width: 780px; color:#000}
.notice_post_bottom_list_body > table > tr > .insert_time {width: 120px;}
.notice_post_bottom_list_body > table > tr > .hits {width: 100px;}
.notice_post_bottom_list_body > table > tr:last-child{border-bottom: 1px solid #555;}
.notice_post_bottom_list_body > table > .table_header{ height: 60px; border-bottom: 1px solid #555; border-top: 1px solid #555; font-size: 15px; font-weight: 600;}
.notice_post_bottom_list_body > table > .table_item{ height: 50px; border-bottom: 1px solid #ccc; cursor: pointer;}
.notice_post_bottom_list_body > table > .table_item:hover{background-color: #fafafa;}
.notice_post_bottom_list_body > table > .table_item:active{background-color: #eee;}
.notice_post_bottom_list_body > table > .table_item > .title {text-align: start;}
.notice_post_bottom_list_body > table > .table_item > .title > div {width: 760px; margin-left: 10px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > div{ float: left;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .notice { background-color: #00a; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .notice > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .best { background-color: #f00; width: 35px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-right: 5px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .best > span { font-size: 12px; color: #fff; width: 35px; font-weight: 600;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .best_delete { background-color: #ff000020;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .title_body {  max-width: 360px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.notice_post_bottom_list_body > table > .table_item > .title > div > .title_body > .category{color: #555; margin-right: 5px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .title_body > .title{color: #000;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .title_body > .delete{color: #ccc;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .title_body > .delete_line{color: #ccc; text-decoration:line-through;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .reply{float: left; color: #f00;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .picture{width: 20px; height: 20px; margin-left: 5px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .new { float: left; background-color: #f00; width: 20px; height: 20px; line-height: 18px; border-radius: 5px; text-align: center; margin-left: 5px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .new > span { font-size: 11px; color: #fff; width: 20px; font-weight: 600;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .choice_image {float: left; width: 20px; height: 20px; margin-left: 5px;}
.notice_post_bottom_list_body > table > .table_item > .title > div > .choice_image_delete {opacity: 0.3;}

.notice_post_bottom_list_body > table > .focus{background-color: #0000AA10; cursor: default;}
.notice_post_bottom_list_body > table > .focus:hover{background-color: #0000AA10;}
.notice_post_bottom_list_body > table > .empty_item{ height: 150px; background-color: #fafafa; }
.notice_post_bottom_list_body > table > .empty_item > td { font-size: 15px; color: #aaa;}

/** End - 공지사항 게시글 화면
********************************************************************************************************/


/********************************************************************************************************
    팝업 기본
********************************************************************************************************/

.popup_wrapper{ background-color: #00000070; width: 100%; height: 100%; position: absolute; top:0; align-items: center; display: block;}
.popup_body{ display: block; width: 200px; min-height: 60px; position: absolute; background-color: #fff; top:50px; left: 50%; transform: translate(-50%, 0%); box-shadow: 3px 3px 3px 3px gray;}
.popup_body > .title_area{ height: 60px; border-bottom: 1px solid #ccc; position: relative;}
.popup_body > .title_area > .title_text{width: 100%; height: 100%;text-align: center;line-height: 60px; font-size: 16px; font-weight: 600;}
.popup_body > .title_area > .close{ width: 60px; height: 60px; padding-top: 20px; position: absolute; top:0; right:0; text-align: center; cursor: pointer;}
.popup_body > .title_area > .close > .icon{color: #000;}
.popup_body > .title_area > .close:hover > .icon{color: #555;}
.popup_body > .title_area > .close:active > .icon{color: #999;}
.popup_body > .button_area { width:100%; height: 80px; position: relative;}
.popup_body > .button_area > div{ width: fit-content; height: 80px; padding-top: 20px; }
.popup_body > .button_area > div > div{float: left; position: relative; cursor: pointer; text-align: center;  width: 100px; height: 40px; margin-left: 10px; margin-right: 10px;}
.popup_body > .button_area > div > div > span{color: #fff; font-size: 15px; font-weight: 600; height: 40px; line-height: 40px;}
.popup_body > .button_area > div > .black_button{ background-color: #000; }
.popup_body > .button_area > div > .black_button:hover{background-color: #333;}
.popup_body > .button_area > div > .black_button:active{background-color: #555;}
.popup_body > .button_area > div > .red_button{ background-color: #f00; }
.popup_body > .button_area > div > .red_button:hover{background-color: #e00;}
.popup_body > .button_area > div > .red_button:active{background-color: #d00;}
.popup_body > .button_area > div > .disable{ opacity: 0.5;}
.popup_body > .button_area > div > div > .loader { float: left; width: 20px; height: 20px; display: block; position: relative;margin-top: 10px; margin-left: 40px;}

.popup_body > .body_area > .select_box {width: 280px; margin-left: 10px; margin-top: 10px; position: relative; height: 50px; border: 1px solid #000; border-radius: 5px;}
.popup_body > .body_area > .select_box > .title { width: 90px; float: left; font-size: 16px; height: 48px; line-height: 48px; font-size: 15px; font-weight: 600; color: #555; text-align: center; background-color: #fafafa; border-right: 1px solid #ccc; border-radius: 5px;}
.popup_body > .body_area > .select_box > .select{ float: left; width: 188px; height: 48px; text-align: center; font-size: 15px; border:0; top:0; color:#000; outline: none; padding-right: 15px; border-radius: 5px; }

.popup_body > .body_area > .input_title_box {width: 280px; margin-left: 10px; margin-top: 10px; position: relative; height: 50px; border: 1px solid #000; border-radius: 5px;}
.popup_body > .body_area > .input_title_box > .title { width: 90px; float: left; font-size: 16px; height: 48px; line-height: 48px; font-size: 15px; font-weight: 600; color: #555; text-align: center; background-color: #fafafa; border-right: 1px solid #ccc; border-radius: 5px;}
.popup_body > .body_area > .input_title_box > .input{ float: left; width: 170px; margin-left: 10px; background-color: #ffffff00; height: 46px; font-size: 15px; border:0; top:0; color:#000; outline: none; }

.popup_body > .body_area > .input_title_unit_box {width: 280px; margin-left: 10px; margin-top: 10px; position: relative; height: 50px; border: 1px solid #000; border-radius: 5px;}
.popup_body > .body_area > .input_title_unit_box > .title { width: 90px; float: left; height: 48px; line-height: 48px; font-size: 15px; font-weight: 600; color: #555; text-align: center; background-color: #fafafa; border-right: 1px solid #ccc; border-radius: 5px;}
.popup_body > .body_area > .input_title_unit_box > .input{ float: left; width: 144px; margin-left: 10px; background-color: #ffffff00; height: 46px; font-size: 15px; border:0; top:0; color:#000; outline: none; text-align: center;}
.popup_body > .body_area > .input_title_unit_box > .unit { width: 30px; float: left; height: 48px; line-height: 48px; font-size: 15px; font-weight: 600; color: #555; text-align: center;}

.popup_body > .body_area > .input_title_calendar_check_box {width: 280px; margin-left: 10px; margin-top: 10px; position: relative; height: 50px; border: 1px solid #000; border-radius: 5px;}
.popup_body > .body_area > .input_title_calendar_check_box > .title { width: 90px; float: left; height: 48px; line-height: 48px; font-size: 15px; font-weight: 600; color: #555; text-align: center; background-color: #fafafa; border-right: 1px solid #ccc; border-radius: 5px; cursor: pointer;}
.popup_body > .body_area > .input_title_calendar_check_box > .title > .check_box { float: left; width: 20px; height: 20px; margin-top: 5px; margin-left: 10px; color: #f00;}
.popup_body > .body_area > .input_title_calendar_check_box > .title > .check_box_not_check { color: #999;}
.popup_body > .body_area > .input_title_calendar_check_box > .title > .span { text-align: start; margin-left: 0px;}
.popup_body > .body_area > .input_title_calendar_check_box > .input{ float: left; width: 134px; margin-left: 10px; background-color: #ffffff00; height: 46px; font-size: 15px; border:0; top:0; color:#000; outline: none; text-align: center;}
.popup_body > .body_area > .input_title_calendar_check_box > .unit { width: 40px; float: left; height: 48px; line-height: 48px; font-size: 15px; font-weight: 600; color: #555; text-align: center;}

.popup_body > .body_area > .input_title_box_disable {background-color: #f1f1f1;}
/*.popup_body > .body_area > .input_title_box_disable > .title{background-color: #ddd; border-right: 1px solid #aaa;}*/
.popup_body > .body_area > .input_title_box_disable > .input { color: #999; background-color: #eee;}

.popup_body > .body_area > .check_area { width:fit-content; height: 40px; margin-left: 10px; padding: 10px; padding-left: 15px; padding-right: 15px; margin-top: 10px; cursor: pointer; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */}
.popup_body > .body_area > .check_area:active{ background-color: #eee; border-radius: 20px;}
.popup_body > .body_area > .check_area > div {float: left; color: #000; }
.popup_body > .body_area > .check_area > .checkbox{ width: 20px; height: 20px;}
.popup_body > .body_area > .check_area > .checkbox {color: #f00;}
.popup_body > .body_area > .check_area > .check_title{ margin-left: 10px; font-size: 15px; }
.popup_body > .body_area > .no_check > div {color: #999;}
.popup_body > .body_area > .no_check > .checkbox {color: #999;}

/** End - 팝업 기본
********************************************************************************************************/


/********************************************************************************************************
    사연채택 팝업
********************************************************************************************************/
.popup_body > .popup_choice {}
.popup_body > .popup_choice > .post_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; margin-top: 10px;height: 90px; border-radius: 5px;}
.popup_body > .popup_choice > .post_box > tr > td{height: 30px; font-size: 14px }
.popup_body > .popup_choice > .post_box > tr > .left{width: 70px; text-align: center; font-weight: 600;}
.popup_body > .popup_choice > .post_box > tr > .right{width: 210px;}
.popup_body > .popup_choice > .post_box > tr > .right > span{width: 210px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}

.popup_body > .popup_choice > .select_box {width: 280px;}
/** End - 팝사연채택 팝업
********************************************************************************************************/


/********************************************************************************************************
    글/댓글 삭제 팝업
********************************************************************************************************/
.popup_body > .popup_delete {}
.popup_body > .popup_delete > .post_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; margin-top: 10px;height: 90px; border-radius: 5px;}
.popup_body > .popup_delete > .post_box > tr > td{height: 30px; font-size: 14px }
.popup_body > .popup_delete > .post_box > tr > .left{width: 70px; text-align: center; font-weight: 600;}
.popup_body > .popup_delete > .post_box > tr > .right{width: 210px;}
.popup_body > .popup_delete > .post_box > tr > .right > span{width: 210px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}

.popup_body > .popup_delete > .select_box {width: 280px;}
.popup_body > .popup_delete > .input_title_box {width: 280px;}
/** End - 글/댓글 삭제 팝업
********************************************************************************************************/


/********************************************************************************************************
    글/댓글 삭제 팝업
********************************************************************************************************/
.popup_body > .popup_delete {}
.popup_body > .popup_delete > .post_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; margin-top: 10px;height: 90px; border-radius: 5px;}
.popup_body > .popup_delete > .post_box > tr > td{height: 30px; font-size: 14px }
.popup_body > .popup_delete > .post_box > tr > .left{width: 70px; text-align: center; font-weight: 600;}
.popup_body > .popup_delete > .post_box > tr > .right{width: 210px;}
.popup_body > .popup_delete > .post_box > tr > .right > span{width: 210px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}

.popup_body > .popup_delete > .select_box {width: 280px;}
.popup_body > .popup_delete > .input_title_box {width: 280px;}
/** End - 글/댓글 삭제 팝업
********************************************************************************************************/


/********************************************************************************************************
    회원탈퇴 팝업
********************************************************************************************************/
.popup_body > .popup_withdraw {}
.popup_body > .popup_withdraw > .guide { width: 280px; margin-left: 10px; margin-top: 20px; margin-bottom: 20px; text-align: center; font-size: 15px;}
.popup_body > .popup_withdraw > .guide > span{white-space:pre-line;}
.popup_body > .popup_withdraw > .input_title_box {width: 280px;}
.popup_body > .popup_withdraw > .input_title_box > .input{ float: left; width: calc(100% - 20px); margin-left: 0px; padding-left: 10px; padding-right: 10px; border-radius: 10px; text-align: center; }
/** End - 회원탈퇴
********************************************************************************************************/


/********************************************************************************************************
    블랙리스트 팝업
********************************************************************************************************/
.popup_body > .popup_blacklist {}
.popup_body > .popup_blacklist > .loader { width: 100%; text-align: center; margin-left: 130px; margin-top: 30px; margin-bottom: 30px;}
.popup_body > .popup_blacklist > .post_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; margin-top: 10px; margin-bottom: 10px;height: 90px; border-radius: 5px;}
.popup_body > .popup_blacklist > .post_box > tr > td{height: 30px; font-size: 14px;}
.popup_body > .popup_blacklist > .post_box > tr > .left{width: 70px; text-align: center; font-weight: 600;}
.popup_body > .popup_blacklist > .post_box > tr > .right{width: 210px;}
.popup_body > .popup_blacklist > .post_box > tr > .right > span{width: 210px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}

.popup_body > .popup_blacklist > .post_box > tr > .block_time {color: #f00;}
.popup_body > .popup_blacklist > .post_box > tr > .l_reason {position: relative;}
.popup_body > .popup_blacklist > .post_box > tr > .l_reason > span{ position: absolute; width: 60px; text-align: center; height: 30px; line-height: 30px; top: 0; left: 0; }
.popup_body > .popup_blacklist > .post_box > tr > .r_reason > span{white-space:pre-line;}

.popup_body > .popup_blacklist > .blacklist_box_title { height: 30px; color:#000; line-height: 30px; font-size: 14px; font-weight: 600; margin-left: 10px; }
.popup_body > .popup_blacklist > .blacklist_box_title > .red{color: #f00;}
.popup_body > .popup_blacklist > .blacklist_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; height: 30px; border-radius: 5px;}
.popup_body > .popup_blacklist > .blacklist_box > .empty{ font-size: 14px; color: #000; line-height: 30px; height: 30px; text-align: center;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li {height: 44px; border-bottom: 1px solid #ddd;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li > div{height: 20px; padding-left: 10px; padding-right: 10px; font-size: 13px;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li > .block_reason{ margin-top: 5px;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li > .bottom { position: relative;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li > .bottom > .block { color: #f00;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li > .bottom > .time { position: absolute;right: 10px;}
.popup_body > .popup_blacklist > .blacklist_box > ul > li:last-child{border-bottom: 0;}

.popup_body > .popup_blacklist > .select_box {width: 280px;}
.popup_body > .popup_blacklist > .input_title_box {width: 280px;}
/** End - 블랙리스트 팝업
********************************************************************************************************/


/********************************************************************************************************
    사연 채택 수정 팝업
********************************************************************************************************/
.popup_body > .popup_choice_info_update {}
.popup_body > .popup_choice_info_update > .loader { width: 100%; text-align: center; margin-left: 130px; margin-top: 30px; margin-bottom: 30px;}
.popup_body > .popup_choice_info_update > .post_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; margin-top: 10px; margin-bottom: 10px;height: 90px; border-radius: 5px;}
.popup_body > .popup_choice_info_update > .post_box > tr > td{height: 30px; font-size: 14px;}
.popup_body > .popup_choice_info_update > .post_box > tr > .left{width: 70px; text-align: center; font-weight: 600;}
.popup_body > .popup_choice_info_update > .post_box > tr > .right{width: 210px;}
.popup_body > .popup_choice_info_update > .post_box > tr > .right > span{width: 210px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}


.popup_body > .popup_choice_info_update > .blacklist_box_title { height: 30px; color:#000; line-height: 30px; font-size: 14px; font-weight: 600; margin-left: 10px; }
.popup_body > .popup_choice_info_update > .blacklist_box_title > .red{color: #f00;}
.popup_body > .popup_choice_info_update > .blacklist_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; height: 30px; border-radius: 5px;}
.popup_body > .popup_choice_info_update > .blacklist_box > .empty{ font-size: 14px; color: #000; line-height: 30px; height: 30px; text-align: center;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li {height: 44px; border-bottom: 1px solid #ddd;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li > div{height: 20px; padding-left: 10px; padding-right: 10px; font-size: 13px;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li > .block_reason{ margin-top: 5px;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li > .bottom { position: relative;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li > .bottom > .block { color: #f00;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li > .bottom > .time { position: absolute;right: 10px;}
.popup_body > .popup_choice_info_update > .blacklist_box > ul > li:last-child{border-bottom: 0;}

.popup_body > .popup_choice_info_update > .select_box {width: 280px;}
.popup_body > .popup_choice_info_update > .input_title_box {width: 280px;}
/** End - 사연 채택 수정 팝업
********************************************************************************************************/


/********************************************************************************************************
    금지어 수정팝업
********************************************************************************************************/
.popup_body > .popup_taboo { padding-top: 10px; }
.popup_body > .popup_taboo > input { background-color: #fff; border: 1px solid #999; width: calc(100% - 40px); font-size: 15px; color: #000; margin-left: 20px; margin-top: 10px; height: 40px; border-radius: 5px; text-align: center; outline: none;}
.popup_body > .popup_taboo > .disabled { background-color: #eee;}
/** End - 금지어 수정팝업
********************************************************************************************************/

/********************************************************************************************************
    관리자 추가&수정 팝업
********************************************************************************************************/
.popup_body > .popup_admin {}
.popup_body > .popup_admin > .loader { width: 100%; text-align: center; margin-left: 130px; margin-top: 30px; margin-bottom: 30px;}
.popup_body > .popup_admin > .post_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; margin-top: 10px; margin-bottom: 10px;max-height: 200px; overflow-y: auto; border-radius: 5px; }
.popup_body > .popup_admin > .post_box > table > tr { height: 30px; width: 100%; border-collapse : collapse; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */}
.popup_body > .popup_admin > .post_box > table > tr:last-child{border-bottom: 0;}
.popup_body > .popup_admin > .post_box > table > tr > td{height: 30px; line-height: 30px; font-size: 14px; border-collapse : collapse;}
.popup_body > .popup_admin > .post_box > table > tr > .half{width: 140px; text-align: center; background-color:#fff}
.popup_body > .popup_admin > .post_box > table > tr > .half > div {cursor: pointer;}
.popup_body > .popup_admin > .post_box > table > tr > .half > div:hover{font-weight: 600;}
.popup_body > .popup_admin > .post_box > table > tr > .half > div > div {display: inline-block;}
.popup_body > .popup_admin > .post_box > table > tr > .half > div > .checkbox{ position: relative; width: 20px; height: 20px; margin-left: 5px; margin-right: 5px; color: #aaa;}
.popup_body > .popup_admin > .post_box > table > tr > .half > div > .checkbox > svg { position: absolute; top:7px; left: 0;}
.popup_body > .popup_admin > .post_box > table > tr > .half > div > .name{ font-size: 13px; color: #999;}
.popup_body > .popup_admin > .post_box > table > tr > .half:last-child{border-left: 1px solid #eee;}
.popup_body > .popup_admin > .post_box > table > tr > .half > .select {  }
.popup_body > .popup_admin > .post_box > table > tr > .half > .select > .checkbox{ color: #090; }
.popup_body > .popup_admin > .post_box > table > tr > .half > .select > .name{ color: #090; font-weight: 600;}
.popup_body > .popup_admin > .post_box > table > .story > .half{border-bottom: 1px solid #eee;}
.popup_body > .popup_admin > .post_box > table > .community > .half{border-bottom: 1px solid #eee;}

.popup_body > .popup_admin > .post_box > table > .categorys > td{ height: 30px; width: 280px; background-color: #eee; padding-top: 5px; padding-bottom: 5px;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > div {cursor: pointer; float: left;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > div:hover{font-weight: 600;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > div > div {float: left;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > div > .checkbox{ position: relative; width: 20px; height: 20px; margin-left: 5px; margin-right: 2px; color: #aaa;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > div > .checkbox > svg { position: absolute; top:5px; left: 0;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > div > .name{ font-size: 13px; color: #999;}
.popup_body > .popup_admin > .post_box > table > .categorys > td > .select > .checkbox{ color: #f00; }
.popup_body > .popup_admin > .post_box > table > .categorys > td > .select > .name{ color: #f00; font-weight: 600;}

.popup_body > .popup_admin > .blacklist_box_title { height: 30px; color:#000; line-height: 30px; font-size: 14px; font-weight: 600; margin-left: 10px; }
.popup_body > .popup_admin > .blacklist_box_title > .red{color: #f00;}
.popup_body > .popup_admin > .blacklist_box { background-color: #FAFAFA; border: 1px solid #ccc; width: 280px; margin-left: 10px; height: 30px; border-radius: 5px;}
.popup_body > .popup_admin > .blacklist_box > .empty{ font-size: 14px; color: #000; line-height: 30px; height: 30px; text-align: center;}
.popup_body > .popup_admin > .blacklist_box > ul > li {height: 44px; border-bottom: 1px solid #ddd;}
.popup_body > .popup_admin > .blacklist_box > ul > li > div{height: 20px; padding-left: 10px; padding-right: 10px; font-size: 13px;}
.popup_body > .popup_admin > .blacklist_box > ul > li > .block_reason{ margin-top: 5px;}
.popup_body > .popup_admin > .blacklist_box > ul > li > .bottom { position: relative;}
.popup_body > .popup_admin > .blacklist_box > ul > li > .bottom > .block { color: #f00;}
.popup_body > .popup_admin > .blacklist_box > ul > li > .bottom > .time { position: absolute;right: 10px;}
.popup_body > .popup_admin > .blacklist_box > ul > li:last-child{border-bottom: 0;}

.popup_body > .popup_admin > .select_box {width: 280px;}
.popup_body > .popup_admin > .input_title_box {width: 280px;}
/** End - 관리자 추가&수정 팝업
********************************************************************************************************/



/*.popup_body{  box-shadow: 3px 3px 3px 3px gray;}*/
/********************************************************************************************************
    금지어 수정팝업
********************************************************************************************************/
.move_application_wrapper { background-color: #000000b0; }
.popup_move_application { width: calc(100% - 60px); background-color: #ffffff00; display: block; max-width: 400px; min-height: 60px; position: absolute; top:50%; left: 50%; transform: translate(-50%, -50%);}
.popup_move_application > .app_button { background-color: #f00; cursor: pointer; border-radius: 5px; width: calc(100% - 40px); margin-left: 20px; height: 50px; line-height: 50px; text-align: center; color: #fff; font-size: 15px; font-weight: 600; font-family:"DefaultFont";}
.popup_move_application > .app_button:active { background-color: #e00;}
.popup_move_application > .web_button { margin-left: 50%; transform: translate(-50%, 0); text-align: center; height: 50px; line-height: 50px; color: #fff; font-size: 14px; font-weight: 500; text-decoration: underline;}
.popup_move_application > .web_button:active { background-color: #ffffff10;}
.popup_move_application > .popup_body_area { display: block; width: calc(100% - 40px); height: 60px; margin-left: 20px; margin-bottom: 20px;}
.popup_move_application > .popup_body_area > .thumbnail { width: 60px; height: 60px; float: left;}
.popup_move_application > .popup_body_area > .thumbnail > img { width: 60px; height: 60px; }
.popup_move_application > .popup_body_area > .text_div {  float: left; width: calc(100% - 80px); padding-top: 7px; margin-left: 20px; color: #fff; font-size: 17px; font-weight: 500; white-space:pre-line; }
/** End - 금지어 수정팝업
********************************************************************************************************/


/********************************************************************************************************
    글 작성&수정 화면
********************************************************************************************************/

.write_post {width: var(--middle-width); border: 1px solid #ccc; outline: none; padding-bottom: 30px;}
.write_post > .top{ height: 50px; border-bottom: 1px solid #ccc;}
.write_post > .top > span{height: 50px; line-height: 50px; margin-left: 30px; font-size: 20px; font-weight: 600;}
.write_post > .title{ width: calc(100% - 60px); margin-left: 30px; margin-top: 20px;}
.write_post > .title > div { height: 40px; margin-top: 10px;}
.write_post > .title > div > div {height: 40px; float: left; line-height: 40px;}
.write_post > .title > div > .left {width: 100px;font-size: 18px; color: #000;}
.write_post > .title > div > .right {width: 830px;}
.write_post > .title > div > .right > .select {height: 40px; width: 120px; border: 1px solid #555; padding-left: 10px; font-size: 14px; color: #000;}
.write_post > .title > div > .right > input {height: 40px; width: 180px; border: 1px solid #ccc; background-color: #fafafa; outline: none; padding-left: 10px; }
.write_post > .title > div > .right > .input_nickname{width:190px}
.write_post > .title > div > .right > .input_title{width:825px}
.write_post > .title > div > .right > .disable { color:#ccc; cursor: default; border-color: #aaa;}
.write_post > .title > div > .right > .disable:hover { color:#ccc; background-color: #fff;}
.write_post > .title > div > .right > .error_msg {display: inline-block; margin-left: 15px; font-size: 13px; color: #f00;}

.write_post > .body_textarea {width: 940px; display: block; margin-left: 30px; margin-top: 20px; height: 500px; margin-left: 30px;}
.write_post > .body_textarea > textarea{width: 900px; height: 460px; border: 1px solid #ccc; background-color: #fafafa; padding:20px; font-size:16px; color: #000; outline: none; resize: none;}

.write_post > .image_area { width: 940px; height: 160px; margin-left: 30px; margin-top: 20px;}
.write_post > .image_area > .title{width: 100%; height: 40px; line-height: 40px; font-size: 16px; color: #000;}
.write_post > .image_area > .title > .title_str{float: left;}
.write_post > .image_area > .title > .btn{float: right; cursor: pointer; text-decoration: underline; }
.write_post > .image_area > .title > .btn:hover{color: var(--text_hover-color);}
.write_post > .image_area > .title > .btn:active{color: var(--text_active-color);}
.write_post > .image_area > .image_box{width: 100%; height: 120px; border: 1px solid #ccc; background-color: #fafafa; padding-top: 20px; padding-left: 20px;}
.write_post > .image_area > .image_box > div {width: 80px; height: 80px; margin-right: 10px; background-color: #eee; border: 0; float: left; position: relative;}
.write_post > .image_area > .image_box > div > img {width: 80px; height: 80px; margin-right: 10px; border: 0;}
.write_post > .image_area > .image_box > div > .delete {width: 20px; height: 20px; position: absolute; background-color: #fff; border-radius: 20px; top:2px; right: 2px; opacity: 0.7; cursor: pointer; color: #000;}
.write_post > .image_area > .image_box > div > .delete:hover{color: #999;}
.write_post > .image_area > .image_box > div > .delete:active{color: #555;}
.write_post > .image_area > .image_box > div > .loader { width: 20px; height: 20px; margin-left: 30px; margin-top: 30px;}



.write_post_btn_box { width: 100%; margin-top: 20px; height: 50px;}
.write_post_btn_box > div {float: right; width: 100px; height:50px; font-size: 15px; font-weight: 600; background-color: #000; color: #fff; line-height: 50px; text-align: center; cursor: pointer;}
.write_post_btn_box > .list { float: left; background-color: #fff; border: 1px solid #555; color: #000;}
.write_post_btn_box > .cancel {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 20px;}
.write_post_btn_box > .save {background-color: #555; margin-left: 20px;}
.write_post_btn_box > .save > .loader{width: 20px; height: 20px; margin-top: 15px; margin-left: 40px;}

.write_post_btn_box > .list:hover {background-color: #eee;}
.write_post_btn_box > .cancel:hover {background-color: #eee;}
.write_post_btn_box > .save:hover {background-color: #333;}

.write_post_btn_box > .list:active {background-color: #ddd;}
.write_post_btn_box > .cancel:active {background-color: #ddd;}
.write_post_btn_box > .save:active {background-color: #111;}

/** End - 글 작성&수정 화면
********************************************************************************************************/


/********************************************************************************************************
    문의 사항 보기 화면
********************************************************************************************************/

.inquiry_post {width: var(--middle-width); border: 1px solid #ccc; outline: none; padding-bottom: 20px;}
.inquiry_post > .title_box { height: 50px; border-bottom: 1px solid #ccc; line-height: 50px;}
.inquiry_post > .title_box > .title { float: left; margin-left: 20px; font-size: 16px; font-weight: 600;}
.inquiry_post > .title_box > .right { float: right;}
.inquiry_post > .title_box > .right > div { float: left; font-size: 16px; color: #000; text-align: center;}
.inquiry_post > .title_box > .right > .title { font-size: 16px; font-weight: 600; width: 100px; background-color: #eee; border-left: 1px solid #ccc; border-right: 1px solid #ccc;}
.inquiry_post > .title_box > .right > .type{ width: 80px; }
.inquiry_post > .title_box > .right > .time{ width: 150px;}
.inquiry_post > .body_box {  width: 960px; margin-left: 20px; margin-top: 20px; color:#000; font-size: 16px; }
.inquiry_post > .body_box > span { width: 960px; white-space:pre-line; }
.inquiry_post > .reply_box { background-color: #eee; width: 960px; margin-left: 20px; margin-top: 20px; padding: 20px;}
.inquiry_post > .reply_box > div {color: #555; font-size: 18px; line-height: 30px;}
.inquiry_post > .reply_box > div > span {white-space:pre-line;}
.inquiry_post > .reply_box > .top {font-size: 14px; margin-bottom: 5px;height: 20px; line-height: 20px; }

.write_reply_area > .title { height: 50px; margin-top: 20px; line-height: 50px; font-size: 16px; font-weight: 600;}
.write_reply_area > textarea{width: 960px; height: 240px; border: 1px solid #ccc; background-color: #fafafa; padding:20px; font-size:16px; color: #000; outline: none; resize: none;}
/** End - 문의 사항 보기 화면
********************************************************************************************************/



/********************************************************************************************************
    문의하기 작성 화면
********************************************************************************************************/

.inquiry_write_post {width: var(--middle-width); border: 1px solid #ccc; outline: none; padding-bottom: 30px;}
.inquiry_write_post > .top{ height: 50px; border-bottom: 1px solid #ccc;}
.inquiry_write_post > .top > span{height: 50px; line-height: 50px; margin-left: 30px; font-size: 20px; font-weight: 600;}
.inquiry_write_post > .top > .no_login { font-weight: 400; font-size: 15px; color: #f00;}
.inquiry_write_post > .title{ width: calc(100% - 60px); margin-left: 30px; margin-top: 20px;}
.inquiry_write_post > .title > div { height: 40px; margin-top: 10px;}
.inquiry_write_post > .title > div > div {height: 40px; float: left; line-height: 40px;}
.inquiry_write_post > .title > div > .left {width: 100px;font-size: 18px; color: #000;}
.inquiry_write_post > .title > div > .right {width: 830px;}
.inquiry_write_post > .title > div > .right > .select {height: 40px; width: 120px; border: 1px solid #555; padding-left: 10px; font-size: 14px; color: #000;}
.inquiry_write_post > .title > div > .right > input {height: 40px; width: 180px; border: 1px solid #ccc; background-color: #fafafa; outline: none; padding-left: 10px; }
.inquiry_write_post > .title > div > .right > .input_nickname{width:190px}
.inquiry_write_post > .title > div > .right > .input_title{width:825px}
.inquiry_write_post > .title > div > .right > .disable { color:#ccc; cursor: default; border-color: #aaa;}
.inquiry_write_post > .title > div > .right > .disable:hover { color:#ccc; background-color: #fff;}

.inquiry_write_post > .body_textarea {width: 940px; display: block; margin-left: 30px; margin-top: 20px; height: 500px; margin-left: 30px;}
.inquiry_write_post > .body_textarea > textarea{width: 900px; height: 460px; border: 1px solid #ccc; background-color: #fafafa; padding:20px; font-size:16px; color: #000; outline: none; resize: none;}

.inquiry_write_post > .image_area { width: 940px; height: 160px; margin-left: 30px; margin-top: 20px;}
.inquiry_write_post > .image_area > .title{width: 100%; height: 40px; line-height: 40px; font-size: 16px; color: #000;}
.inquiry_write_post > .image_area > .title > .title_str{float: left;}
.inquiry_write_post > .image_area > .title > .btn{float: right; cursor: pointer; text-decoration: underline; }
.inquiry_write_post > .image_area > .title > .btn:hover{color: var(--text_hover-color);}
.inquiry_write_post > .image_area > .title > .btn:active{color: var(--text_active-color);}
.inquiry_write_post > .image_area > .image_box{width: 100%; height: 120px; border: 1px solid #ccc; background-color: #fafafa; padding-top: 20px; padding-left: 20px;}
.inquiry_write_post > .image_area > .image_box > div {width: 80px; height: 80px; margin-right: 10px; background-color: #eee; border: 0; float: left; position: relative;}
.inquiry_write_post > .image_area > .image_box > div > img {width: 80px; height: 80px; margin-right: 10px; border: 0;}
.inquiry_write_post > .image_area > .image_box > div > .delete {width: 20px; height: 20px; position: absolute; background-color: #fff; border-radius: 20px; top:2px; right: 2px; opacity: 0.7; cursor: pointer; color: #000;}
.inquiry_write_post > .image_area > .image_box > div > .delete:hover{color: #999;}
.inquiry_write_post > .image_area > .image_box > div > .delete:active{color: #555;}
.inquiry_write_post > .image_area > .image_box > div > .loader { width: 20px; height: 20px; margin-left: 30px; margin-top: 30px;}



.inquiry_write_post_btn_box { width: 100%; margin-top: 20px; height: 50px;}
.inquiry_write_post_btn_box > div {float: right; width: 100px; height:50px; font-size: 15px; font-weight: 600; background-color: #000; color: #fff; line-height: 50px; text-align: center; cursor: pointer;}
.inquiry_write_post_btn_box > .email { float: left; color: #555; background-color: #fff; width: auto; font-size: 16px; font-weight: 400; cursor: default;}
.inquiry_write_post_btn_box > .cancel {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 20px;}
.inquiry_write_post_btn_box > .save {background-color: #555; margin-left: 20px;}
.inquiry_write_post_btn_box > .save > .loader{width: 20px; height: 20px; margin-top: 15px; margin-left: 40px;}

.inquiry_write_post_btn_box > .list:hover {background-color: #eee;}
.inquiry_write_post_btn_box > .cancel:hover {background-color: #eee;}
.inquiry_write_post_btn_box > .save:hover {background-color: #333;}

.inquiry_write_post_btn_box > .list:active {background-color: #ddd;}
.inquiry_write_post_btn_box > .cancel:active {background-color: #ddd;}
.inquiry_write_post_btn_box > .save:active {background-color: #111;}

/** End - 문의하기 작성 화면
********************************************************************************************************/



/********************************************************************************************************
    정보수정 화면
********************************************************************************************************/

.user_info {width: var(--middle-width); border: 1px solid #ccc; outline: none; padding-bottom: 30px;}
.user_info > .top{ height: 50px; border-bottom: 1px solid #ccc;}
.user_info > .top > span{height: 50px; line-height: 50px; margin-left: 30px; font-size: 20px; font-weight: 600;}
.user_info > .edit_box{ margin-top: 20px; width: 600px; margin-left: 200px; margin-bottom: 40px;}
.user_info > .edit_box > .title{ height: 60px; line-height: 60px; font-size: 20px; color: #000;}
.user_info > .edit_box > .button{height: 50px; width: 600px; line-height: 50px; margin-top: 20px; background-color: #555; color: #fff; font-size: 15px; font-weight: 600; text-align: center; cursor: pointer;}
.user_info > .edit_box > .button:hover{ background-color: #666; }
.user_info > .edit_box > .button:active{ background-color: #777; }
.user_info > .edit_box > .button > .loader{ width: 20px; height: 20px; margin-left: 290px; margin-top: 15px; position: absolute;}
.user_info > .edit_box > .input_box{height: 60px; border: 1px solid #ccc; background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px;}
.user_info > .edit_box > .input_box > input{ float: left; height: 56px; border: 0; outline: none; font-size: 18px; color:#000; }
.user_info > .edit_box > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.user_info > .edit_box > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.user_info > .edit_box > .input_box > .right_text { float: right; height: 60px; line-height: 60px; width: 70px; text-align: end; font-size: 18px; cursor: pointer; color: #555; text-decoration: underline;}
.user_info > .edit_box > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.user_info > .edit_box > .input_box > .right_text:active{ color: var(--text_active-color);}
.user_info > .edit_box > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.user_info > .edit_box > .input_top{border-end-start-radius: 0; border-end-end-radius: 0; border-bottom: 0;}
.user_info > .edit_box > .input_middle{border-radius: 0; border-bottom: 0;}
.user_info > .edit_box > .input_bottom{border-start-start-radius: 0; border-start-end-radius: 0;}
.user_info > .withdraw_box { width: 100%; margin-top: 50px; text-align: center; color: #555; text-decoration: underline; }
.user_info > .withdraw_box > div { width: fit-content; margin-left: 50%; transform: translate(-50%, 0);}
.user_info > .withdraw_box > div:hover{ font-weight: 600; cursor: pointer; }

/** End - 정보수정 화면
********************************************************************************************************/


/********************************************************************************************************
    회원가입 화면
********************************************************************************************************/

.sign_up_agree{width: 1000px;}
.sign_up_agree > .title{color: #000; font-size: 24px; height: 40px; line-height: 40px;}
.sign_up_agree > .agree_title{color: #000; height: 50px; margin-top: 30px; line-height: 50px; font-size: 20px; font-weight: 600;}
.sign_up_agree > .divider{background-color: #000; height: 1px; width: 1000px;}
.sign_up_agree > .item_box {width: 1000px; margin-top: 20px; position: relative;}
.sign_up_agree > .item_box > .top {width: fit-content; cursor: pointer;}
.sign_up_agree > .item_box > .top >.ck_box{float: left; height: 30px; width: 30px; margin-top: 5px; color: #ccc;}
.sign_up_agree > .item_box > .top >.ck_select{color: #0a0;}
.sign_up_agree > .item_box > .top >.title{display: inline-block; height: 40px; line-height: 40px; font-size: 20px; color: #000; margin-left: 10px; cursor: pointer;}
.sign_up_agree > .item_box > .top:hover > .ck_box{color: #aaa;}
.sign_up_agree > .item_box > .top:hover > .ck_select{color: #090;}
.sign_up_agree > .item_box > .top:hover > .title{color:var(--text_hover-color)}
.sign_up_agree > .item_box > .top:active > .ck_box{color: #999;}
.sign_up_agree > .item_box > .top:active > .ck_select{color: #080;}
.sign_up_agree > .item_box > .top:active > .title{color:var(--text_active-color)}
.sign_up_agree > .item_box > .next_btn{position: absolute; top:0; right:0; width: 100px; height: 40px; line-height: 40px; text-align: center; background-color: #555;color:#fff; font-size: 15px; font-weight: 600; cursor: pointer;}
.sign_up_agree > .item_box > .next_btn:hover{background-color: #333;}
.sign_up_agree > .item_box > .next_btn:active{background-color: #111;}

.sign_up_agree > .item_box > .middle{ width: 1000px; min-height: 100px; border: 1px solid #ccc; margin-top: 20px;}
.sign_up_agree > .item_box > .middle > .use_agreement{ width: 1000px; height: 250px; overflow-y: scroll; padding: 20px;}
.sign_up_agree > .item_box > .middle > .use_agreement > span{ width: 960px; color: #000; font-size: 15px; white-space:pre-line; }
.sign_up_agree > .item_box > .middle > .use_agreement_loading{ width: 1000px; height: 145px;}
.sign_up_agree > .item_box > .middle > .use_agreement_loading > div{width: 40px; height: 40px; margin-left: 460px; margin-top: 105px;}

.sign_up_agree > .item_box > .middle > .personal > table { width: 960px; margin: 20px; border: 1px solid #ccc; border-collapse : collapse;}
.sign_up_agree > .item_box > .middle > .personal > table > tr > td{text-align: center; color: #555; font-size: 15px;}
.sign_up_agree > .item_box > .middle > .personal > table > tr > .left { border-right: 1px solid #ccc; width: 220px;}
.sign_up_agree > .item_box > .middle > .personal > table > tr > .right { width: 740px;}
.sign_up_agree > .item_box > .middle > .personal > table > .title {background-color: #f5f5f5; height: 60px;}
.sign_up_agree > .item_box > .middle > .personal > table > .title > td{color: #000; font-weight: 600;}
.sign_up_agree > .item_box > .middle > .personal > table > .item { border-top: 1px solid #ccc;}
.sign_up_agree > .item_box > .middle > .personal > table > .item > .right{ text-align: start; padding: 20px; padding-top: 40px; padding-bottom: 40px; white-space:pre-line;}
.sign_up_agree > .item_box > .middle > .personal > .bottom_text{ margin-left: 20px; margin-bottom: 20px;}
.sign_up_agree > .item_box > .middle > .personal > .bottom_text > span{ font-size: 15px; color: #555; white-space:pre-line;}
.sign_up_agree > .item_box > .middle > .choice_agree{ padding: 20px;}
.sign_up_agree > .item_box > .middle > .choice_agree > div > span {color: #000; font-size: 16px;}
.sign_up_agree > .item_box > .middle > .choice_agree > .middle{margin-top: 20px; margin-bottom: 20px;}


.sign_up_input { width: 600px; margin-left: 200px;}
.sign_up_input > .box_title { height: 60px; color: #000; font-size: 20px; line-height: 60px;margin-top: 20px; position: relative;}
.sign_up_input > .input_box{height: 60px; border: 1px solid #ccc; background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px;}
.sign_up_input > .input_box > input{ float: left; height: 56px; border: 0; outline: none; font-size: 18px; color:#000; background-color: #fff; }
.sign_up_input > .input_box > input:disabled{background-color: #fafafa;}
.sign_up_input > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.sign_up_input > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.sign_up_input > .input_box > .right_text { float: right; height: 60px; line-height: 60px; font-weight: 600; width: 70px; text-align: end; font-size: 16px; cursor: pointer; color: #555;}
.sign_up_input > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.sign_up_input > .input_box > .right_text:active{ color: var(--text_active-color);}
.sign_up_input > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.sign_up_input > .input_box_focus { border: 1px solid #555; }
.sign_up_input > .disabled{background-color: #fafafa;}
.sign_up_input > .input_top{border-end-start-radius: 0; border-end-end-radius: 0; border-bottom: 0;}
.sign_up_input > .input_middle{border-radius: 0; border-bottom: 0;}
.sign_up_input > .input_bottom{border-start-start-radius: 0; border-start-end-radius: 0;}

.sign_up_input > .certification_box{ width: 600px; height: 60px; border: 1px solid #ccc; background-color: #f9faff; border-radius: 10px; border-start-start-radius: 0; border-start-end-radius: 0; padding-left: 20px; padding-right: 20px;}
.sign_up_input > .certification_box > .cer_button{ float: right; width: 70px; height: 40px; line-height: 40px; margin-top: 10px; background-color: #555; font-size: 14px; font-weight: 600; text-align: center; color: #fff; cursor: pointer; border-radius: 5px;}
.sign_up_input > .certification_box > .cer_button:hover{background-color: #333;}
.sign_up_input > .certification_box > .cer_button:active{background-color: #111;}

.sign_up_input > .certification_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.sign_up_input > .certification_box > .left {float: left; height: 60px; line-height: 60px; color:#000; font-size: 18px;}
.sign_up_input > .certification_box > .success { float: right; right: 0; height: 60px; top:0; color: #090; font-size: 16px; font-weight: 600; position: relative;}
.sign_up_input > .certification_box > .success > span {line-height: 60px; }
.sign_up_input > .certification_box > .success > div {width: 20px; height: 20px; position: absolute; top: 20px; right: 60px;}

.sign_up_input > .button_area { width: 280px; height: 50px; margin-top: 50px; margin-left: 160px;}
.sign_up_input > .button_area > div {float: left; width: 100px; height:50px; font-size: 15px; margin-left: 20px; margin-right: 20px; font-weight: 600; background-color: #000; color: #fff; line-height: 50px; text-align: center; cursor: pointer;}
.sign_up_input > .button_area > .cancel {background-color: #555;}
.sign_up_input > .button_area > .sign_up {background-color: #0000AA;}
.sign_up_input > .button_area > .cancel:hover {background-color: #333;}
.sign_up_input > .button_area > .sign_up:hover {background-color: #000088;}
.sign_up_input > .button_area > .cancel:active {background-color: #111;}
.sign_up_input > .button_area > .sign_up:active {background-color: #000066;}

/** End - 회원가입 화면
********************************************************************************************************/


/********************************************************************************************************
    개인정보 처리방침 화면
********************************************************************************************************/

.personal_top {width: var(--middle-width);}
.personal_top > .title{ height: 40px; color: #000; font-size: 32px; font-weight: 600; margin-top: 10px; white-space:pre-line;}
.personal_top > .box{ border: 5px solid #ccc; padding: 20px; font-size: 20px; color: #777; margin-top: 30px; white-space:pre-line;}
.personal_top > .information{ margin-top: 30px; font-size: 20px; color: #555; white-space:pre-line; }

.personal_provision_box {border: 1px solid #ccc; border-radius: 10px; margin-top: 40px;padding-left: 30px; padding-right: 30px;}
.personal_provision_box > .title {height: 70px; line-height: 70px; border-bottom: 1px solid #ccc; color: #000; font-size: 20px; white-space:pre-line;}
.personal_provision_box > .item{padding-top: 30px; padding-bottom: 30px; color: #555; font-size: 20px; white-space:pre-line;}

/** End - 개인정보 처리방침 화면
********************************************************************************************************/


/********************************************************************************************************
    아이디 / 비밀번호 찾기 화면
********************************************************************************************************/

.find { width: 600px; margin-left: 200px;}
.find > .title{color: #000; font-size: 24px; height: 40px; line-height: 40px; margin-top: 20px;}
.find > .box{border: 1px solid #ccc; width: 600px; border-radius: 10px; padding-left: 30px; padding-right: 30px; margin-top: 40px; outline: none;}
.find > .box > .item{ background-color: #fff; }
.find > .box > .item > .tab { height: 70px; cursor: pointer;}
.find > .box > .item > .tab > .checkbox { float: left; width: 24px; height: 24px; margin-top: 23px; color: #aaa;}
.find > .box > .item > .tab > .tab_title { float: left; font-size: 20px; height: 70px; line-height: 70px; color: #aaa; margin-left: 15px;}
.find > .box > .item > .tab:hover { background-color: #00000005;}
.find > .box > .item > .tab:hover > .checkbox { color: #777; }
.find > .box > .item > .tab:hover > .tab_title { color: #777; }
.find > .box > .item > .item_body{ margin-top: 10px; margin-bottom: 30px; }
.find > .box > .item > .item_body > input{ height: 50px; width:495px; padding-left: 20px; font-size: 16px; color: #000; padding-right: 20px; border: 1px solid #aaa; outline: none; border-radius: 5px; font-size: 18px; color:#000; background-color: #fff; margin-bottom: 20px; }
.find > .box > .item > .item_body > .input_disable {background-color: #eee; color: #777;}


.find > .box > .item > .item_body > .input_box{height: 60px; border: 1px solid #ccc; background-color: #fff; border-radius: 10px; padding-left: 20px; padding-right: 20px; margin-bottom: 20px;}
.find > .box > .item > .item_body > .input_box > input{ float: left; height: 56px; border: 0; outline: none; font-size: 18px; color:#000; }
.find > .box > .item > .item_body > .input_box > .left_icon { float: left; height: 20px; width: 20px; margin-top: 20px; margin-right: 10px;}
.find > .box > .item > .item_body > .input_box > .right_icon { float: right; height: 24px; width: 24px; margin-top: 18px; color: #777;}
.find > .box > .item > .item_body > .input_box > .right_text { float: right; height: 60px; line-height: 60px; width: 70px; text-align: end; font-size: 18px; cursor: pointer; color: #555; text-decoration: underline;}
.find > .box > .item > .item_body > .input_box > .right_text:hover{ color: var(--text_hover-color);}
.find > .box > .item > .item_body > .input_box > .right_text:active{ color: var(--text_active-color);}
.find > .box > .item > .item_body > .input_box > .right_text > .loader{ width: 20px; height: 20px; margin-top: 20px; float: right;}
.find > .box > .item > .item_body > .disabled{ background-color: #eee;}

.find > .box > .item > .tab_focus{ cursor: default;}
.find > .box > .item > .tab_focus > .checkbox {color: #0a0;}
.find > .box > .item > .tab_focus > .tab_title {color: #000;}
.find > .box > .item > .tab_focus:hover > .checkbox { color: #0a0; }
.find > .box > .item > .tab_focus:hover > .tab_title { color: #000; }
.find > .box > .item > .title{ height: 70px; text-align: center; line-height: 70px; font-size: 20px;}
.find > .box > .second{margin-top: 0; border-top: 1px solid #ccc;}

.find > .box > .id_result{text-align: center; margin:40px; font-size: 20px; margin-top: 30px;}
.find > .box > .id_result > .title{ display: block; color: #555; margin-bottom: 20px;}
.find > .box > .id_result > .id{ display: block; color: #000; font-weight: 600;}

/** End - 아이디 / 비밀번호 찾기 화면
********************************************************************************************************/



/********************************************************************************************************
    팝업
********************************************************************************************************/

/* 본인인증 팝업 */
.certification_popup { width: 410px; height: 689px; padding-top: 250px; text-align: center; background-color: #555;}
.certification_popup > div { margin-left: 180px; width: 50px; height: 50px; margin-bottom: 50px;}
.certification_popup > span {color: #fff; font-size: 16px;}

/** End - 팝업
********************************************************************************************************/





/********************************************************************************************************

    관리자 페이지 공통

********************************************************************************************************/

.admin_body_div {width: 100%; padding-bottom: 50px; position: relative; overflow-y: auto; text-align: center;}
.admin_body_div > .middle_area {padding-bottom: 50px; position: relative;}

.admin_header { border-bottom:solid 1px #CCCCCC; height: 70px;}
.admin_header > .header_body { height: 100%; width: 100%; position: relative;}
.admin_header > .header_body > .header_title_area{ height: 100%; font-size: 20px; color: #000;}
.admin_header > .header_body > .header_title_area > div { float: left; height: 100%; }
.admin_header > .header_body > .header_title_area > .logo { font-family: "LogoFont"; cursor: pointer; line-height: 70px; font-size: 25px; margin-left: 20px; white-space:nowrap; }
.admin_header > .header_body > .header_title_area > .right { font-size: 16px; float: right; margin-right: 20px; color: #000;}
.admin_header > .header_body > .header_title_area > .right > div { display: inline-block; line-height: 70px; margin-left: 5px;}
.admin_header > .header_body > .header_title_area > .right > .logout {cursor: pointer;}
.admin_header > .header_body > .header_title_area > .right > .logout:hover {color: var(--text_hover-color);}
.admin_header > .header_body > .header_title_area > .right > .logout:active {color: var(--text_active-color); font-weight: 600;}
.admin_header > .header_body > .header_search_area{position: absolute; border: solid 1px #000000; right: 0; top: 25px; height: 50px; line-height: 50px; width: 300px;}
.admin_header > .header_body > .header_search_area > .select{position: absolute; width: 70px; height: 48px; size:17px; border:0; top:0; color:#555; outline: none; padding-left: 5px; }
.admin_header > .header_body > .header_search_area > .search_input{ position: absolute; border: 0; size:17px; height: 48px; width:180px; top:0; left:80px;outline: none;}
.admin_header > .header_body > .header_search_area > .search_btn_area{ position: absolute; right: 0; height: 50px; width: 40px; cursor: pointer;}
.admin_header > .header_body > .header_search_area > .search_btn_area > .search_btn{ position: absolute; right: 10px; top:15px;color: #777;}
.admin_header > .header_body > .header_search_area > .search_btn_area > .search_btn:hover{color: #000;}

.admin_top_tab { border-bottom:solid 1px #555; height: 60px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.admin_top_tab > .top_tab_body { height: 100%; text-align: center; padding-left: 10px; padding-right: 10px;}
.admin_top_tab > .top_tab_body > .tab_item { display: inline-block; height: 100%; line-height: 60px; margin-left: 5px; margin-right: 5px; padding-left: 5px; padding-right: 5px; cursor: pointer;}
.admin_top_tab > .top_tab_body > .tab_item > span { font-size: 16px; color: #000000;}
.admin_top_tab > .top_tab_body > .tab_item_select{ display: inline-block; height: 60px; line-height: 60px; margin-left: 5px; margin-right: 5px; padding-left: 5px; padding-right: 5px; border-bottom: 4px solid #f00;}
.admin_top_tab > .top_tab_body > .tab_item_select > span { font-size: 16px; color: #ff0000; font-weight: 600;}


.admin_sub_top_tab { height: 60px; background-color: #F1F1F1;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.admin_sub_top_tab > .top_tab_body { height: 100%; width: 100%; position: relative; padding-left: 10px; padding-right: 10px;}
.admin_sub_top_tab > .top_tab_body > .tab_item { display: inline-block; height: 100%; line-height: 60px; margin-left: 2px; margin-right: 2px; padding-left: 5px; padding-right: 5px; cursor: pointer;}
.admin_sub_top_tab > .top_tab_body > .tab_item > span { font-size: 15px; color: #555;}
.admin_sub_top_tab > .top_tab_body > .tab_item_select{ display: inline-block; height: 100%; line-height: 60px; margin-left: 2px; margin-right: 2px; padding-left: 5px; padding-right: 5px; }
.admin_sub_top_tab > .top_tab_body > .tab_item_select > span { font-size: 18px; color: #000; font-weight: 600;}

/* 모바일 화면 */
.admin_mobile_body_div > .admin_header > .header_body > .header_title_area > .logo{ font-size: 16px; margin-left: 10px;}
.admin_mobile_body_div > .admin_header > .header_body > .header_title_area > .right { font-size: 15px; margin-right: 10px; }
.admin_mobile_body_div > .admin_top_tab > .top_tab_body{ overflow-x: auto; overflow-y: hidden; white-space: nowrap; width: 100%; }
.admin_mobile_body_div > .admin_top_tab > .top_tab_body::-webkit-scrollbar { display: none; }

.admin_mobile_body_div > .admin_sub_top_tab > .top_tab_body{ overflow-x: auto; overflow-y: hidden; white-space: nowrap; width: 100%; }
.admin_mobile_body_div > .admin_sub_top_tab > .top_tab_body::-webkit-scrollbar { display: none; }
/* End - 모바일 화면 */


.admin_list_box { margin-left: 50%; transform: translate(-50%, 0); margin-top: 20px;}
.admin_list_box > ul { border-top: 1px solid #000; border-bottom: 1px solid #999;}
.admin_list_box > ul > li { height: 50px; line-height: 50px; font-size: 14px; border-bottom: 1px solid #ddd; }
.admin_list_box > ul > li:last-child { border-bottom: 0;}
.admin_list_box > ul > li > div { float: left; text-align: center; white-space: pre-line; height: 100%;}
.admin_list_box > ul > li > div:nth-child(even) {background-color: var(--admin-list-divide-bg);}
.admin_list_box > ul > li > .pointer { cursor: pointer; text-decoration: underline; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari 텍스트 선택 막음*/
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */}
.admin_list_box > ul > li > .pointer:active {font-weight: 600;}
.admin_list_box > ul > li > .two_line { line-height: 20px; padding-top: 5px;}
.admin_list_box > ul > li > .divide_bg { background-color: var(--admin-list-divide-bg); }
.admin_list_box > ul > .item:hover { background-color: var(--admin-list-hover-bg); }
.admin_list_box > ul > .item > div > .loading {width: 20px; margin-left: 50%; transform: translate(-50%, 0); margin-top: 15px;}

.admin_list_box > ul > .title { font-size: 15px; font-weight: 600; border-bottom: 1px solid #999;}
.admin_list_box > ul > .title_empty > div:nth-child(even) {background-color: #fff;}
.admin_list_box > ul > .empty { height: 100px !important; line-height: 100px; color: #999 !important; background-color: #fafafa !important; padding-top: 0px !important;}
.admin_list_box > ul > .empty > span {font-size: 17px;}

.admin_list_box > .list_top { height: 40px; width: 100%; margin-bottom: 20px;}
.admin_list_box > .list_top > .admin_search_area{ float: left; border: solid 1px #000000; border-radius: 5px; background-color: #fff; height: 40px; line-height: 40px; width: 222px;}
.admin_list_box > .list_top > .admin_search_area > .select{ float: left; width: 70px; height: 38px; font-size:13px; border:0; color:#555; outline: none; padding-left: 5px; background-color: #00000000; }
.admin_list_box > .list_top > .admin_search_area > .search_input{ float: left; border: 0; size:17px; height: 38px; width:110px; top:0; left:80px;outline: none; background-color: #00000000;}
.admin_list_box > .list_top > .admin_search_area > .search_btn_area{ float: left; height: 40px; width: 40px; cursor: pointer;}
.admin_list_box > .list_top > .admin_search_area > .search_btn_area > .search_btn{ color: #777; margin-top: 10px;}
.admin_list_box > .list_top > .admin_search_area > .search_btn_area:hover > .search_btn{color: #000;}


.admin_list_box > .list_top > .admin_select_area{ float: left; border: solid 1px #000000; border-radius: 5px; background-color: #fff; height: 40px; line-height: 40px; margin-right: 10px; }
.admin_list_box > .list_top > .admin_select_area:last-child{margin-right: 0;}
.admin_list_box > .list_top > .admin_select_area > .title { float: left; font-size: 13px; font-weight: 600; width: 50px; height: 38px; background-color: #fafafa; border-right: 1px solid #ccc; border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
.admin_list_box > .list_top > .admin_select_area > .select{ float: left; width: 60px; height: 38px; font-size:13px; border:0; color:#555; outline: none; padding-left: 10px; background-color: #00000000; }

.admin_mobile_body_div > .middle_area > .admin_list_box { margin-top: 10px; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .list_top { height: 30px;}
.admin_mobile_body_div > .middle_area > .admin_list_box > .list_top > .admin_select_area { width: calc(33% - 6px); height: 40px; }
.admin_mobile_body_div > .middle_area > .admin_list_box > .list_top > .admin_select_area > .title {height: 38px; line-height: 40px;}
.admin_mobile_body_div > .middle_area > .admin_list_box > .list_top > .admin_select_area > .select{ width: calc(100% - 50px); height: 40px; line-height: 40px;}

.admin_mobile_body_div > .middle_area > .admin_list_box { width: 100%;}
.admin_mobile_body_div > .middle_area > .admin_list_box > .list_top { width: calc(100% - 20px); margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li { font-size: 13px; height: 50px; background-color: #fff; }
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > div {height: 20px; width: 100%;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .sub > div {height: 20px; line-height: 20px;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .sub > .divide { margin-left: 5px; margin-right: 5px; color: #555; font-weight: 600;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .sub { height: 30px; padding-top: 5px; padding-bottom: 5px; background-color: #fff;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .sub_bg {background-color: var(--admin-list-divide-bg);}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .height_25 {height: 25px; line-height: 25px; padding: 0;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .top_border { border-top: 1px solid #f1f1f1;}
.admin_mobile_body_div > .middle_area > .admin_list_box > ul > li > .button_area {height: 30px; background-color: #eee !important; border-top: 1px solid #f1f1f1; line-height: 30px; padding-top: 0; padding-bottom: 0;}

.admin_mobile_body_div > .middle_area > .admin_list_box > ul > .item > .button_area > div > .loading {width: 20px; margin-left: 50%; transform: translate(-50%, 0); margin-top: 5px;}

.admin__page_area { width: 100%;}

/** End - 관리자 페이지 공통
********************************************************************************************************/



/********************************************************************************************************

    관리자 사연 페이지

********************************************************************************************************/

.admin_story_category_box { width: 410px;}
.admin_story_category_box > ul > li > .name { width: calc(100% - 120px);}
.admin_story_category_box > ul > li > .state { width: 60px; }
.admin_story_category_box > ul > li > .edit { width: 60px;}
.admin_story_category_box > .list_top > .admin_search_area{ width: 202px;}
.admin_story_category_box > .list_top > .admin_search_area > .search_input { width: 160px; padding-left: 10px;}
.admin_story_category_box > .list_top > .add_btn{ float: right; height: 40px; line-height:40px; width: 80px; font-size: 13px;}

.admin_mobile_body_div > .middle_area > .admin_story_category_box > ul > li { height: 50px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_story_category_box > ul > li > div {float: left; height: 50px;}
.admin_mobile_body_div > .middle_area > .admin_story_category_box > ul > li > .name { width: calc(100% - 120px);}
.admin_mobile_body_div > .middle_area > .admin_story_category_box > ul > li > .state { width: 60px; }
.admin_mobile_body_div > .middle_area > .admin_story_category_box > ul > li > .edit { width: 60px;}



.admin_story_choice_box { width: 1400px;}
.admin_story_choice_box > ul > li > .title { width: 500px; padding-left: 10px; padding-right: 10px; }
.admin_story_choice_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_story_choice_box > ul > li > .title > div { float: left; text-align: start;}
.admin_story_choice_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_choice_box > ul > li > .title > div > .title{ color: #000; font-size: 14px;}
.admin_story_choice_box > ul > li > .title > div > .title > div {float: left;}
.admin_story_choice_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_story_choice_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_story_choice_box > ul > li > .title > div > .body{ color: #999; font-size: 14px;}
.admin_story_choice_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_choice_box > ul > li > .insert_time { width: 100px; }
.admin_story_choice_box > ul > li > .choice_type { width: 100px; }
.admin_story_choice_box > ul > li > .choice_time { width: 100px;}
.admin_story_choice_box > ul > li > .video { width: 100px; }
.admin_story_choice_box > ul > li > .price { width: 100px;}
.admin_story_choice_box > ul > li > .price_send { width: 100px; }
.admin_story_choice_box > ul > li > .edit { width: 75px;}
.admin_story_choice_box > ul > li > .cancel { width: 75px; }
.admin_story_choice_box > ul > .item > .title { cursor:pointer; }
.admin_story_choice_box > .list_top > .select_type > .select{ width: 60px;}
.admin_story_choice_box > .list_top > .select_send_type > .select{ width: 70px;}
.admin_story_choice_box > .list_top > .select_show_type > .select{ width: 70px;}
.admin_story_choice_box > .list_top > .admin_search_area{ width: 232px;}
.admin_story_choice_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_story_choice_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_story_choice_box > .list_top > .select_sort > .select{ width: 70px;}

.admin_mobile_body_div > .middle_area > .admin_story_choice_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > .list_top > .admin_search_area{ width: calc(100% - 130px);}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 112px);}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > .list_top > .select_sort{float: left; width: 120px; margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div { float: left; text-align: start;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div > .title{ color: #000; font-size: 14px; font-weight: 600; }
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div > .title > div {float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .title > div > .body{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .sub > .writer { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .sub > .choice_type { margin-left: 10px; color: #555;}
.admin_mobile_body_div > .middle_area > .admin_story_choice_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 33%;}

.admin_story_delete_post_box { width: 1300px;}
.admin_story_delete_post_box > ul > li > .title { width: 500px; padding-left: 10px; padding-right: 10px; }
.admin_story_delete_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_story_delete_post_box > ul > li > .title > div { float: left; text-align: start;}
.admin_story_delete_post_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_delete_post_box > ul > li > .title > div > .title{ color: #000; font-size: 14px;}
.admin_story_delete_post_box > ul > li > .title > div > .title > div {float: left;}
.admin_story_delete_post_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_story_delete_post_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_story_delete_post_box > ul > li > .title > div > .body{ color: #999; font-size: 14px;}
.admin_story_delete_post_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_delete_post_box > ul > li > .insert_time { width: 100px; }
.admin_story_delete_post_box > ul > li > .delete_date { width: 100px; }
.admin_story_delete_post_box > ul > li > .delete_user { width: 50px;}
.admin_story_delete_post_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_delete_post_box > ul > li > .cancel { width: 50px; }
.admin_story_delete_post_box > ul > li > .real_delete { width: 100px; }
.admin_story_delete_post_box > ul > .item > .real_delete { color: #f00;}
.admin_story_delete_post_box > ul > .item > .title { cursor:pointer; }
.admin_story_delete_post_box > .list_top > .admin_search_area{ width: 232px;}
.admin_story_delete_post_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_story_delete_post_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_story_delete_post_box > .list_top > .select_sort > .select{ width: 70px;}

.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > .list_top > .admin_search_area{ width:calc(100% - 140px)}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 112px);}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > .list_top > .select_sort{float: left; width: 130px; margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div { float: left; text-align: start;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div > .title{ color: #000; font-size: 14px; font-weight: 600; }
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div > .title > div {float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .title > div > .body{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .sub > .writer { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .sub > .delete_user { margin-left: 10px; color: #555;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 50%;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_post_box > ul > li > .button_area > .real_delete { color: #f00;}


.admin_story_delete_reply_box { width: 1400px;}
.admin_story_delete_reply_box > ul > li > .title { width: 450px; padding-left: 10px; padding-right: 10px; }
.admin_story_delete_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_story_delete_reply_box > ul > li > .title > div { float: left; text-align: start;}
.admin_story_delete_reply_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_delete_reply_box > ul > li > .title > div > .reply_body{ color: #000; font-size: 14px;}
.admin_story_delete_reply_box > ul > li > .title > div > .post_title{ color: #999; font-size: 14px;}
.admin_story_delete_reply_box > ul > li > .reply_writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_delete_reply_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_delete_reply_box > ul > li > .insert_time { width: 100px; }
.admin_story_delete_reply_box > ul > li > .delete_date { width: 100px; }
.admin_story_delete_reply_box > ul > li > .delete_user { width: 50px;}
.admin_story_delete_reply_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_delete_reply_box > ul > li > .cancel { width: 50px; }
.admin_story_delete_reply_box > ul > li > .real_delete { width: 100px; }
.admin_story_delete_reply_box > ul > .item > .real_delete { color: #f00;}
.admin_story_delete_reply_box > ul > .item > .title { cursor:pointer; }
.admin_story_delete_reply_box > .list_top > .admin_search_area{ width: 252px;}
.admin_story_delete_reply_box > .list_top > .admin_search_area > .select{ width: 90px;}
.admin_story_delete_reply_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_story_delete_reply_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_story_delete_reply_box > .list_top > .select_sort > .select{ width: 70px;}

.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > .list_top > .admin_search_area{ width:calc(100% - 140px)}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 132px);}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > .list_top > .select_sort{float: left; width: 130px; margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .title > .reply_body{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .title > .post_title{ color: #999; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .sub > .writer { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .sub > .delete_date { margin-left: 10px; color: #555;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 50%;}
.admin_mobile_body_div > .middle_area > .admin_story_delete_reply_box > ul > li > .button_area > .real_delete { color: #f00;}


.admin_story_report_post_box { width: 1300px;}
.admin_story_report_post_box > ul > li > .title { width: 500px; padding-left: 10px; padding-right: 10px; }
.admin_story_report_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_story_report_post_box > ul > li > .title > div { float: left; text-align: start;}
.admin_story_report_post_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_report_post_box > ul > li > .title > div > .title{ color: #000; font-size: 14px;}
.admin_story_report_post_box > ul > li > .title > div > .title > div {float: left;}
.admin_story_report_post_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_story_report_post_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_story_report_post_box > ul > li > .title > div > .body{ color: #999; font-size: 14px;}
.admin_story_report_post_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_report_post_box > ul > li > .insert_time { width: 100px; }
.admin_story_report_post_box > ul > li > .report_count { width: 100px; }
.admin_story_report_post_box > ul > li > .is_delete { width: 100px;}
.admin_story_report_post_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_report_post_box > ul > li > .delete { width: 100px; }
.admin_story_report_post_box > ul > .item > .real_delete { color: #f00;}
.admin_story_report_post_box > ul > .item > .title { cursor:pointer; }
.admin_story_report_post_box > .list_top > .admin_search_area{ width: 232px;}
.admin_story_report_post_box > .list_top > .admin_search_area > .select{ width: 70px;}
.admin_story_report_post_box > .list_top > .admin_search_area > .search_input { width: 120px; }
.admin_story_report_post_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_story_report_post_box > .list_top > .select_sort > .select{ width: 70px;}
.admin_story_report_post_box > .list_top > .delete_type > .title{ width: 70px;}
.admin_story_report_post_box > .list_top > .delete_type > .select{ width: 80px;}



.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 110px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top > .delete_type{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top > .delete_type > .select{width: calc(100% - 70px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > .list_top > .select_sort > .select{width: calc(100% -50px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .title > .title{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .title > .body{ color: #999; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .sub > .report_count { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}


.admin_story_report_reply_box { width: 1400px;}
.admin_story_report_reply_box > ul > li > .title { width: 450px; padding-left: 10px; padding-right: 10px; }
.admin_story_report_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_story_report_reply_box > ul > li > .title > div { float: left; text-align: start;}
.admin_story_report_reply_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_report_reply_box > ul > li > .title > div > .reply_body{ color: #000; font-size: 14px;}
.admin_story_report_reply_box > ul > li > .title > div > .post_title{ color: #999; font-size: 14px;}
.admin_story_report_reply_box > ul > li > .reply_writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_report_reply_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_story_report_reply_box > ul > li > .insert_time { width: 100px; }
.admin_story_report_reply_box > ul > li > .report_count { width: 100px; }
.admin_story_report_reply_box > ul > li > .is_delete { width: 100px;}
.admin_story_report_reply_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_story_report_reply_box > ul > li > .delete { width: 100px; }
.admin_story_report_reply_box > ul > .item > .real_delete { color: #f00;}
.admin_story_report_reply_box > ul > .item > .title { cursor:pointer; }
.admin_story_report_reply_box > .list_top > .admin_search_area{ width: 252px;}
.admin_story_report_reply_box > .list_top > .admin_search_area > .select{ width: 90px;}
.admin_story_report_reply_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_story_report_reply_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_story_report_reply_box > .list_top > .select_sort > .select{ width: 70px;}
.admin_story_report_reply_box > .list_top > .delete_type > .title{ width: 70px;}
.admin_story_report_reply_box > .list_top > .delete_type > .select{ width: 80px;}


.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 130px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top > .delete_type{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top > .delete_type > .select{width: calc(100% - 70px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > .list_top > .select_sort > .select{width: calc(100% -50px);}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .title > .title{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .title > .body{ color: #999; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .sub > .report_count { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_story_report_reply_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}

/** End - 관리자 사연 페이지
********************************************************************************************************/


/********************************************************************************************************

    관리자 커뮤니티 페이지

********************************************************************************************************/

.admin_community_category_box { width: 570px;}
.admin_community_category_box > ul > li > .name { width: calc(100% - 300px); max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_category_box > ul > li > .channel { width: 60px; }
.admin_community_category_box > ul > li > .banner { width: 60px;}
.admin_community_category_box > ul > li > .mob_banner { width: 60px;}
.admin_community_category_box > ul > li > .state { width: 60px; }
.admin_community_category_box > ul > li > .edit { width: 60px;}
.admin_community_category_box > .list_top > .admin_search_area{ width: 202px;}
.admin_community_category_box > .list_top > .admin_search_area > .search_input { width: 160px; padding-left: 10px;}
.admin_community_category_box > .list_top > .add_btn{ float: right; height: 40px; line-height:40px; width: 80px; font-size: 13px;}

.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > div {height: 50px;}
.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > .name { width: calc(100% - 250px);}
.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > .channel { width: 50px; }
.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > .banner { width: 50px;}
.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > .mob_banner { width: 50px;}
.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > .state { width: 50px; }
.admin_mobile_body_div > .middle_area > .admin_community_category_box > ul > li > .edit { width: 50px;}



.admin_community_delete_post_box { width: 1300px;}
.admin_community_delete_post_box > ul > li > .title { width: 500px; padding-left: 10px; padding-right: 10px; }
.admin_community_delete_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_community_delete_post_box > ul > li > .title > div { float: left; text-align: start;}
.admin_community_delete_post_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_delete_post_box > ul > li > .title > div > .title{ color: #000; font-size: 14px;}
.admin_community_delete_post_box > ul > li > .title > div > .title > div {float: left;}
.admin_community_delete_post_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_community_delete_post_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_community_delete_post_box > ul > li > .title > div > .body{ color: #999; font-size: 14px;}
.admin_community_delete_post_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_community_delete_post_box > ul > li > .insert_time { width: 100px; }
.admin_community_delete_post_box > ul > li > .delete_date { width: 100px; }
.admin_community_delete_post_box > ul > li > .delete_user { width: 50px;}
.admin_community_delete_post_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_delete_post_box > ul > li > .cancel { width: 50px; }
.admin_community_delete_post_box > ul > li > .real_delete { width: 100px; }
.admin_community_delete_post_box > ul > .item > .real_delete { color: #f00;}
.admin_community_delete_post_box > ul > .item > .title { cursor:pointer; }
.admin_community_delete_post_box > .list_top > .admin_search_area{ width: 232px;}
.admin_community_delete_post_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_community_delete_post_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_community_delete_post_box > .list_top > .select_sort > .select{ width: 70px;}


.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > .list_top > .admin_search_area{ width:calc(100% - 140px)}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 112px);}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > .list_top > .select_sort{float: left; width: 130px; margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div { float: left; text-align: start;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div > .title{ color: #000; font-size: 14px; font-weight: 600; }
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div > .title > div {float: left;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .title > div > .body{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .sub > .writer { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .sub > .delete_user { margin-left: 10px; color: #555;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 50%;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_post_box > ul > li > .button_area > .real_delete { color: #f00;}


.admin_community_delete_reply_box { width: 1400px;}
.admin_community_delete_reply_box > ul > li > .title { width: 450px; padding-left: 10px; padding-right: 10px; }
.admin_community_delete_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_community_delete_reply_box > ul > li > .title > div { float: left; text-align: start;}
.admin_community_delete_reply_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_delete_reply_box > ul > li > .title > div > .reply_body{ color: #000; font-size: 14px;}
.admin_community_delete_reply_box > ul > li > .title > div > .post_title{ color: #999; font-size: 14px;}
.admin_community_delete_reply_box > ul > li > .reply_writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_community_delete_reply_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_community_delete_reply_box > ul > li > .insert_time { width: 100px; }
.admin_community_delete_reply_box > ul > li > .delete_date { width: 100px; }
.admin_community_delete_reply_box > ul > li > .delete_user { width: 50px;}
.admin_community_delete_reply_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_delete_reply_box > ul > li > .cancel { width: 50px; }
.admin_community_delete_reply_box > ul > li > .real_delete { width: 100px; }
.admin_community_delete_reply_box > ul > .item > .real_delete { color: #f00;}
.admin_community_delete_reply_box > ul > .item > .title { cursor:pointer; }
.admin_community_delete_reply_box > .list_top > .admin_search_area{ width: 252px;}
.admin_community_delete_reply_box > .list_top > .admin_search_area > .select{ width: 90px;}
.admin_community_delete_reply_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_community_delete_reply_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_community_delete_reply_box > .list_top > .select_sort > .select{ width: 70px;}


.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > .list_top > .admin_search_area{ width:calc(100% - 140px)}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 132px);}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > .list_top > .select_sort{float: left; width: 130px; margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .title > .reply_body{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .title > .post_title{ color: #999; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .sub > .writer { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .sub > .delete_date { margin-left: 10px; color: #555;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 50%;}
.admin_mobile_body_div > .middle_area > .admin_community_delete_reply_box > ul > li > .button_area > .real_delete { color: #f00;}


.admin_community_report_post_box { width: 1300px;}
.admin_community_report_post_box > ul > li > .title { width: 500px; padding-left: 10px; padding-right: 10px; }
.admin_community_report_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_community_report_post_box > ul > li > .title > div { float: left; text-align: start;}
.admin_community_report_post_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_report_post_box > ul > li > .title > div > .title{ color: #000; font-size: 14px;}
.admin_community_report_post_box > ul > li > .title > div > .title > div {float: left;}
.admin_community_report_post_box > ul > li > .title > div > .title > .top { max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_community_report_post_box > ul > li > .title > div > .title > .reply { color: #f00;}
.admin_community_report_post_box > ul > li > .title > div > .body{ color: #999; font-size: 14px;}
.admin_community_report_post_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_community_report_post_box > ul > li > .insert_time { width: 100px; }
.admin_community_report_post_box > ul > li > .report_count { width: 100px; }
.admin_community_report_post_box > ul > li > .is_delete { width: 100px;}
.admin_community_report_post_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_report_post_box > ul > li > .delete { width: 100px; }
.admin_community_report_post_box > ul > .item > .real_delete { color: #f00;}
.admin_community_report_post_box > ul > .item > .title { cursor:pointer; }
.admin_community_report_post_box > .list_top > .admin_search_area{ width: 232px;}
.admin_community_report_post_box > .list_top > .admin_search_area > .select{ width: 70px;}
.admin_community_report_post_box > .list_top > .admin_search_area > .search_input { width: 120px; }
.admin_community_report_post_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_community_report_post_box > .list_top > .select_sort > .select{ width: 70px;}
.admin_community_report_post_box > .list_top > .delete_type > .title{ width: 70px;}
.admin_community_report_post_box > .list_top > .delete_type > .select{ width: 80px;}


.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 110px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top > .delete_type{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top > .delete_type > .select{width: calc(100% - 70px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > .list_top > .select_sort > .select{width: calc(100% -50px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .title > .title{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .title > .body{ color: #999; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .sub > .report_count { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}


.admin_community_report_reply_box { width: 1400px;}
.admin_community_report_reply_box > ul > li > .title { width: 450px; padding-left: 10px; padding-right: 10px; }
.admin_community_report_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_community_report_reply_box > ul > li > .title > div { float: left; text-align: start;}
.admin_community_report_reply_box > ul > li > .title > div > div {max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_report_reply_box > ul > li > .title > div > .reply_body{ color: #000; font-size: 14px;}
.admin_community_report_reply_box > ul > li > .title > div > .post_title{ color: #999; font-size: 14px;}
.admin_community_report_reply_box > ul > li > .reply_writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_community_report_reply_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_community_report_reply_box > ul > li > .insert_time { width: 100px; }
.admin_community_report_reply_box > ul > li > .report_count { width: 100px; }
.admin_community_report_reply_box > ul > li > .is_delete { width: 100px;}
.admin_community_report_reply_box > ul > li > .reason { width: 250px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_community_report_reply_box > ul > li > .delete { width: 100px; }
.admin_community_report_reply_box > ul > .item > .real_delete { color: #f00;}
.admin_community_report_reply_box > ul > .item > .title { cursor:pointer; }
.admin_community_report_reply_box > .list_top > .admin_search_area{ width: 252px;}
.admin_community_report_reply_box > .list_top > .admin_search_area > .select{ width: 90px;}
.admin_community_report_reply_box > .list_top > .admin_search_area > .search_input { width: 120px;}
.admin_community_report_reply_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_community_report_reply_box > .list_top > .select_sort > .select{ width: 70px;}
.admin_community_report_reply_box > .list_top > .delete_type > .title{ width: 70px;}
.admin_community_report_reply_box > .list_top > .delete_type > .select{ width: 80px;}


.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 130px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top > .delete_type{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top > .delete_type > .select{width: calc(100% - 70px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > .list_top > .select_sort > .select{width: calc(100% -50px);}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li { height: 140px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .title { width: 100%; height: 50px; padding-left: 10px; padding-right: 10px; }
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .title > img { float: left; width: 40px; height: 40px; margin-right: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .title > .title{ color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .title > .body{ color: #999; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .sub > .report_count { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_community_report_reply_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}

/** End - 관리자 커뮤니티 페이지
********************************************************************************************************/


/********************************************************************************************************

    관리자 공지사항 페이지

********************************************************************************************************/

.admin_notice_list_box { width: 1000px;}
.admin_notice_list_box > ul > li > .state { width: 50px} 
.admin_notice_list_box > ul > li > .title { width: 400px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;} 
.admin_notice_list_box > ul > li > .writer { width: 150px;  max-lines: 2; height: 100%; overflow:hidden; white-space:pre-line; text-overflow:ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.admin_notice_list_box > ul > li > .insert_time { width: 100px; }
.admin_notice_list_box > ul > li > .type { width: 100px; }
.admin_notice_list_box > ul > li > .category { width: 100px;}
.admin_notice_list_box > ul > li > .start { width: 100px; }
.admin_notice_list_box > ul > li > .end { width: 100px;}
.admin_notice_list_box > ul > li > .edit { width: 50px;}
.admin_notice_list_box > ul > .item > .title { cursor:pointer; }
.admin_notice_list_box > .list_top > .notice_state > .select{ width: 60px;}
.admin_notice_list_box > .list_top > .post_type > .select{ width: 80px;}
.admin_notice_list_box > .list_top > .category_type > .title{ width: 70px;}
.admin_notice_list_box > .list_top > .category_type > .select{ width: 150px;}
.admin_notice_list_box > .list_top > .admin_search_area{ width: 162px;}
.admin_notice_list_box > .list_top > .admin_search_area > .search_input { width: 120px; padding-left: 10px;}
.admin_notice_list_box > .list_top > .write_btn{ float: right; height: 40px; line-height:40px; width: 80px; font-size: 13px;}


.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 40px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .notice_state{float: left; width: 110px}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .notice_state > .select{width: calc(100% - 50px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .post_type{float: left; width: calc(100% - 110px - 100px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .post_type > .select{width: calc(100% - 50px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .category_type{ width: 100%;}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .category_type > .select{width: calc(100% - 70px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > .list_top > .select_sort > .select{width: calc(100% -50px);}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > ul > li { height: 120px; color: #000;}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > ul > li > .title { text-align: start; width: 100%; height: 30px; line-height: 30px; padding-left: 10px; padding-right: 10px; color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > ul > li > .sub > .insert_time { margin-left: 10px; color: #999;}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > ul > li > .sub > .start { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_notice_list_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}

/** End - 관리자 공지사항 페이지
********************************************************************************************************/


/********************************************************************************************************
    관리자 공지사항 작성&수정 화면
********************************************************************************************************/

.write_notice {max-width: 800px; width: 100%; border: 1px solid #ccc; outline: none; padding-bottom: 30px; margin-left: 50%; transform: translate(-50%, 0); margin-top: 20px; text-align: left;}
.write_notice > .top{ height: 50px; border-bottom: 1px solid #ccc;}
.write_notice > .top > span{height: 50px; line-height: 50px; margin-left: 20px; font-size: 20px; font-weight: 600;}
.write_notice > .title{ width: calc(100% - 60px); margin-left: 20px; margin-top: 20px;}
.write_notice > .title > div { height: 40px; margin-top: 10px;}
.write_notice > .title > div > div {height: 40px; float: left; line-height: 40px;}
.write_notice > .title > div > .left {width: 80px;font-size: 15px; color: #000;}
.write_notice > .title > div > .right {width: calc(100% - 80px);}
.write_notice > .title > div > .right > .select {height: 40px; width: 90px; border: 1px solid #555; padding-left: 10px; font-size: 14px; color: #000;}
.write_notice > .title > div > .right > input {height: 40px; width: 180px; border: 1px solid #ccc; background-color: #fafafa; outline: none; padding-left: 10px; }
.write_notice > .title > div > .right > .input_nickname{width:190px}
.write_notice > .title > div > .right > .input_title{width:100%;}
.write_notice > .title > div > .right > .disable { color:#ccc; cursor: default; border-color: #aaa;}
.write_notice > .title > div > .right > .disable:hover { color:#ccc; background-color: #fff;}
.write_notice > .title > div > .right > .error_msg {display: inline-block; margin-left: 15px; font-size: 13px; color: #f00;}


.write_notice > .title > div > .right > .date {width: calc(50% - 5px); max-width: 140px; float: left; position: relative; height: 40px; border: 1px solid #ccc; background-color: #fafafa; }
.write_notice > .title > div > .right > .date > .input{ float: left; width: calc(100% - 39px); background-color: #ffffff00; height: 40px; font-size: 13px; border:0; top:0; color:#000; outline: none; text-align: center;}
.write_notice > .title > div > .right > .date > .unit { width: 35px; float: left; height: 40px; line-height: 40px; font-size: 15px; font-weight: 600; color: #555; text-align: center;}
.write_notice > .title > div > .right > .date:last-child { margin-left: 10px;}





.write_notice > .body_textarea {width: calc(100% - 90px); display: block; margin-left: 20px; margin-top: 20px; height: 500px;}
.write_notice > .body_textarea > textarea{width: 100%; height: 460px; border: 1px solid #ccc; background-color: #fafafa; padding:20px; font-size:15px; color: #000; outline: none; resize: none;}

.write_notice > .image_area { width: calc(100% - 50px); margin-left: 20px; margin-top: 20px;}
.write_notice > .image_area > .title{width: 100%; height: 40px; line-height: 40px; font-size: 16px; color: #000;}
.write_notice > .image_area > .title > .title_str{float: left;}
.write_notice > .image_area > .title > .btn{float: right; cursor: pointer; text-decoration: underline; }
.write_notice > .image_area > .title > .btn:hover{color: var(--text_hover-color);}
.write_notice > .image_area > .title > .btn:active{color: var(--text_active-color);}
.write_notice > .image_area > .image_box{width: 100%; border: 1px solid #ccc; background-color: #fafafa; padding-top: 10px; padding-left: 20px; padding-bottom: 20px;}
.write_notice > .image_area > .image_box > div {width: 60px; height: 60px; margin-right: 10px; background-color: #eee; border: 0; display: inline-block; position: relative; margin-top: 10px;}
.write_notice > .image_area > .image_box > div > img {width: 60px; height: 60px; margin-right: 10px; border: 0;}
.write_notice > .image_area > .image_box > div > .delete {width: 20px; height: 20px; position: absolute; background-color: #fff; border-radius: 20px; top:2px; right: 2px; opacity: 0.7; cursor: pointer; color: #000;}
.write_notice > .image_area > .image_box > div > .delete:hover{color: #999;}
.write_notice > .image_area > .image_box > div > .delete:active{color: #555;}
.write_notice > .image_area > .image_box > div > .loader { width: 20px; height: 20px; margin-left: 20px; padding-top: 20px; position: absolute;}



.write_notice_btn_box { width: 100%; max-width: 800px; margin-top: 20px; height: 50px; margin-left: 50%; transform: translate(-50%, 0); }
.write_notice_btn_box > div {float: right; width: 100px; height:50px; font-size: 15px; font-weight: 600; background-color: #000; color: #fff; line-height: 50px; text-align: center; cursor: pointer;}
.write_notice_btn_box > .list { float: left; background-color: #fff; border: 1px solid #555; color: #000;}
.write_notice_btn_box > .cancel {background-color: #fff; border: 1px solid #555; color: #000; margin-left: 20px;}
.write_notice_btn_box > .save {background-color: #555; margin-left: 20px;}
.write_notice_btn_box > .save > .loader{width: 20px; height: 20px; margin-top: 15px; margin-left: 40px;}

.write_notice_btn_box > .list:hover {background-color: #eee;}
.write_notice_btn_box > .cancel:hover {background-color: #eee;}
.write_notice_btn_box > .save:hover {background-color: #333;}

.write_notice_btn_box > .list:active {background-color: #ddd;}
.write_notice_btn_box > .cancel:active {background-color: #ddd;}
.write_notice_btn_box > .save:active {background-color: #111;}

/** End - 관리자 공지사항 작성&수정 화면
********************************************************************************************************/


/********************************************************************************************************

    관리자 계정 페이지

********************************************************************************************************/

.admin_account_admin_user_post_box { width: 1100px;}
.admin_account_admin_user_post_box > ul > li > .insert_time { width: 100px; }
.admin_account_admin_user_post_box > ul > li > .state { width: 50px; }
.admin_account_admin_user_post_box > ul > li > .id { width: 200px;}
.admin_account_admin_user_post_box > ul > li > .name { width: 200px;}
.admin_account_admin_user_post_box > ul > li > .number { width: 150px;}
.admin_account_admin_user_post_box > ul > li > .email { width: 200px;}
.admin_account_admin_user_post_box > ul > li > .access { width: 100px;}
.admin_account_admin_user_post_box > ul > li > .edit { width: 100px;}
.admin_account_admin_user_post_box > ul > .item > .edit > .edit { cursor:pointer; text-decoration: underline;}
.admin_account_admin_user_post_box > ul > .item > .edit > .state { cursor:pointer; text-decoration: underline;}
.admin_account_admin_user_post_box > .list_top > .admin_search_area{ width: 242px; }
.admin_account_admin_user_post_box > .list_top > .admin_search_area > .search_input{ width: 200px; padding-left: 10px;}
.admin_account_admin_user_post_box > .list_top > .select_state{}
.admin_account_admin_user_post_box > .list_top > .select_state > .select{ width: 70px;}
.admin_account_admin_user_post_box > .list_top > .select_sort{float: right;}
.admin_account_admin_user_post_box > .list_top > .select_sort > .select{ width: 70px;}
.admin_account_admin_user_post_box > .list_top > .write_btn{ float: right; height: 40px; line-height:40px; width: 80px; font-size: 13px; margin-left: 10px;}



.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top { height: 30px; width:100%; }
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .admin_search_area{ width:calc(100% - 20px);}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 40px);}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .select_state{float: left; width: 120px}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .select_state > .select{width: calc(100% - 50px);}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .write_btn{float: left; width: 79px; margin-left: 0;}

.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .select_sort{float: left; width: calc(100% - 110px - 130px);}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > .list_top > .select_sort > .select{width: calc(100% - 50px);}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li { height: 90px; color: #000; padding-top: 5px;}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .title { text-align: start; width: 100%; height: 30px; line-height: 30px; padding-left: 10px; padding-right: 10px; color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .sub > .id { margin-left: 10px; font-weight: 600; }
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .sub > .insert_time { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .sub > .name { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 50%;}
.admin_mobile_body_div > .middle_area > .admin_account_admin_user_post_box > ul > li > .button_area > .divide {width: calc(100% - 10px);}


.admin_account_user_post_box { width: 1400px;}
.admin_account_user_post_box > ul > li > .insert_time { width: 100px; }
.admin_account_user_post_box > ul > li > .login_time { width: 100px; }
.admin_account_user_post_box > ul > li > .state { width: 100px; }
.admin_account_user_post_box > ul > li > .id { width: 170px;}
.admin_account_user_post_box > ul > li > .name { width: 80px;}
.admin_account_user_post_box > ul > li > .nickname { width: 220px;}
.admin_account_user_post_box > ul > li > .number { width: 130px;}
.admin_account_user_post_box > ul > li > .email { width: 200px;}
.admin_account_user_post_box > ul > li > .posts { width: 50px;}
.admin_account_user_post_box > ul > li > .replys { width: 50px;}
.admin_account_user_post_box > ul > li > .reports { width: 50px;}
.admin_account_user_post_box > ul > li > .blocks { width: 50px;}
.admin_account_user_post_box > ul > li > .blacklist { width: 100px;}
.admin_account_user_post_box > ul > .item > .edit > .edit { cursor:pointer; text-decoration: underline;}
.admin_account_user_post_box > ul > .item > .edit > .state { cursor:pointer; text-decoration: underline;}
.admin_account_user_post_box > .list_top > .admin_search_area{ width: 292px; }
.admin_account_user_post_box > .list_top > .admin_search_area > .search_input{ width: 250px; padding-left: 10px;}
.admin_account_user_post_box > .list_top > .select_state{}
.admin_account_user_post_box > .list_top > .select_state > .select{ width: 95px;}
.admin_account_user_post_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_account_user_post_box > .list_top > .select_sort > .select{ width: 95px;}



.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 40px);}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > .list_top > .select_state{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > .list_top > .select_sort > .select{width: calc(100% - 50px);}

.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li { height: 140px; color: #000; padding-top: 5px;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .title { text-align: start; width: 100%; height: 30px; line-height: 30px; padding-left: 10px; padding-right: 10px; color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .sub > .id { margin-left: 10px; font-weight: 600; }
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .sub > .insert_time { color: #999;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .sub > .name { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .sub > .number { margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .sub_bg > div{ width: 25%;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}
.admin_mobile_body_div > .middle_area > .admin_account_user_post_box > ul > li > .button_area > .divide {width: calc(100% - 10px);}



.admin_account_blacklist_post_box { width: 1500px;}
.admin_account_blacklist_post_box > ul > li > .insert_time { width: 100px; }
.admin_account_blacklist_post_box > ul > li > .end_time { width: 100px; }
.admin_account_blacklist_post_box > ul > li > .state { width: 100px; }
.admin_account_blacklist_post_box > ul > li > .id { width: 150px;}
.admin_account_blacklist_post_box > ul > li > .name { width: 80px;}
.admin_account_blacklist_post_box > ul > li > .nickname { width: 210px;}
.admin_account_blacklist_post_box > ul > li > .number { width: 120px;}
.admin_account_blacklist_post_box > ul > li > .email { width: 200px;}
.admin_account_blacklist_post_box > ul > li > .reason { width: 170px;}
.admin_account_blacklist_post_box > ul > li > .cancel_reason { width: 170px;}
.admin_account_blacklist_post_box > ul > li > .cancel { width: 100px;}
.admin_account_blacklist_post_box > ul > .item > .edit > .edit { cursor:pointer; text-decoration: underline;}
.admin_account_blacklist_post_box > ul > .item > .edit > .state { cursor:pointer; text-decoration: underline;}
.admin_account_blacklist_post_box > .list_top > .admin_search_area{ width: 292px; }
.admin_account_blacklist_post_box > .list_top > .admin_search_area > .search_input{ width: 250px; padding-left: 10px;}
.admin_account_blacklist_post_box > .list_top > .select_state{}
.admin_account_blacklist_post_box > .list_top > .select_state > .select{ width: 95px;}
.admin_account_blacklist_post_box > .list_top > .select_sort{float: right; margin-right: 0;}
.admin_account_blacklist_post_box > .list_top > .select_sort > .select{ width: 95px;}


.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 40px);}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > .list_top > .select_state{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > .list_top > .select_sort{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > .list_top > .select_sort > .select{width: calc(100% - 50px);}

.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li { height: 160px; color: #000; padding-top: 5px;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .title { text-align: start; width: 100%; height: 30px; line-height: 30px; padding-left: 10px; padding-right: 10px; color: #000; font-size: 14px;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub > div { float: left;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub > .id { margin-left: 10px; font-weight: 600; }
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub > .insert_time { color: #555; margin-left: 10px;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub > .end_time { color: #555;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub > .number { margin-left: 10px; }
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub > .reason { margin-left: 10px; line-height: 25px; color: #555; width: calc(100% - 20px); text-align: start; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .sub_bg > div{ width: 25%;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}
.admin_mobile_body_div > .middle_area > .admin_account_blacklist_post_box > ul > li > .button_area > .divide {width: calc(100% - 10px);}

/** End - 관리자 계정 페이지
********************************************************************************************************/


/********************************************************************************************************

    관리자 문의 페이지

********************************************************************************************************/

.admin_inquiry_list_box { width: 800px;}
.admin_inquiry_list_box > ul > li {cursor: pointer;}
.admin_inquiry_list_box > ul > li > .name { width: 300px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_inquiry_list_box > ul > li > .insert_time { width: 100px; }
.admin_inquiry_list_box > ul > li > .type { width: 100px;}
.admin_inquiry_list_box > ul > li > .nickname { width: 240px; }
.admin_inquiry_list_box > ul > li > .reply { width: 60px;}
.admin_inquiry_list_box > .list_top > .select_read > .select{ width: 70px;}
.admin_inquiry_list_box > .list_top > .admin_search_area { width: 202px;}
.admin_inquiry_list_box > .list_top > .admin_search_area > .search_input { width: 160px; padding-left: 10px;}


.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > .list_top { height: 30px; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > .list_top > .admin_search_area{ width:100%}
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > .list_top > .admin_search_area > .search_input { width: calc(100% - 40px);}
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > .list_top > .select_type{float: left; width: calc(50% - 5px);}
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > .list_top > .select_read{float: left; width: calc(50% - 5px);}

.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li { height: 85px; color: #000; padding-top: 5px;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .sub > div { float: left; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .sub > .title { margin-left: 10px; font-weight: 600; width: calc(100% - 20px); text-align: start; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .sub > .type { margin-left: 10px; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .sub > .insert_time { color: #555; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .sub > .id { margin-left: 10px; text-align: start; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .button_area > div {height: 30px; line-height: 30px; width: 100%;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_list_box > ul > li > .button_area > .divide {width: calc(100% - 10px);}

/** End - 관리자 문의 페이지
********************************************************************************************************/


/********************************************************************************************************
    관리자 문의 사항 보기 화면
********************************************************************************************************/

.admin_inquiry_post {max-width: 1000px; width: 100%; margin-left: 50%; transform: translate(-50% , 0); border: 1px solid #ccc; outline: none; padding-bottom: 20px; margin-top: 20px;}
.admin_inquiry_post > .title { width: 100%; padding-left: 20px; padding-top: 10px; padding-bottom: 10px; padding-right: 20px; border-bottom: 1px solid #ccc; line-height: 20px; text-align: start; font-size: 16px; font-weight: 600; min-height: 40px;}
.admin_inquiry_post > .title_box { height: 50px; border-bottom: 1px solid #ccc; line-height: 50px;}
.admin_inquiry_post > .title_box > .left { float: left; height: 49px; width:calc(100% - 160px - 50px - 130px);}
.admin_inquiry_post > .title_box > .left > div { float: left; height: 100%; font-size: 14px; color: #000; text-align: center;}
.admin_inquiry_post > .title_box > .left > .title { font-size: 14px; font-weight: 600; width: 120px; background-color: #eee; border-left: 1px solid #ccc; border-right: 1px solid #ccc;}
.admin_inquiry_post > .title_box > .left > .id{ width: calc(100% - 120px); }
.admin_inquiry_post > .title_box > .right { float: right; height: 49px;}
.admin_inquiry_post > .title_box > .right > div { float: left; height: 100%; font-size: 14px; color: #000; text-align: center;}
.admin_inquiry_post > .title_box > .right > .title { font-size: 14px; font-weight: 600; width: 80px; background-color: #eee; border-left: 1px solid #ccc; border-right: 1px solid #ccc;}
.admin_inquiry_post > .title_box > .right > .type{ width: 50px; }
.admin_inquiry_post > .title_box > .right > .time{ width: 130px;}
.admin_inquiry_post > .body_box {  width: calc(100% - 40px); margin-left: 20px; margin-top: 20px; color:#000; font-size: 16px; text-align: start; }
.admin_inquiry_post > .body_box > span { width: 100%; white-space:pre-line; }
.admin_inquiry_post > .reply_box { background-color: #eee; width: calc(100% - 40px); margin-left: 20px; margin-top: 20px; padding: 20px; text-align: start;}
.admin_inquiry_post > .reply_box > div {color: #555; font-size: 18px; line-height: 30px;}
.admin_inquiry_post > .reply_box > div > span {white-space:pre-line;}
.admin_inquiry_post > .reply_box > .top {font-size: 14px; margin-bottom: 5px;height: 20px; line-height: 20px; }


.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box { height: 40px; line-height: 40px; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .nickname {width: 100%; height: 100%;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .nickname > div { float: left; height: 100%; font-size: 14px; color: #000; text-align: center; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .nickname > .title { font-size: 14px; font-weight: 600; width: 120px; background-color: #eee; border-right: 1px solid #ccc;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .nickname > .id{ width: calc(100% - 120px); }
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .second {width: 100%; height: 100%;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .second > div { float: left; height: 100%; font-size: 14px; color: #000; text-align: center;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .second > .title { font-size: 14px; font-weight: 600; width: 80px; background-color: #eee; border-left: 1px solid #ccc; border-right: 1px solid #ccc;}
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .second > .type{ width: 80px; }
.admin_mobile_body_div > .middle_area > .admin_inquiry_post > .title_box > .second > .time{ width: calc(100% - 160px - 80px);}
.admin_mobile_body_div > .middle_area > .admin_write_post_btn_box > .save {margin-right: 10px; margin-left: 0;}
.admin_mobile_body_div > .middle_area > .admin_write_post_btn_box > .back {margin-right: 10px;}

.admin_write_reply_area { max-width: 1000px; width: 100%; margin-left: 50%; transform: translate(-50% , 0); }
.admin_write_reply_area > .title { height: 50px; margin-top: 20px; line-height: 50px; font-size: 16px; font-weight: 600; margin-left: 20px; text-align: start;}
.admin_write_reply_area > textarea{width: 960px; height: 240px; border: 1px solid #ccc; background-color: #fafafa; padding:20px; font-size:16px; color: #000; outline: none; resize: none;}


.admin_write_post_btn_box { max-width: 1000px; width: 100%; margin-left: 50%; transform: translate(-50% , 0); width: 100%; margin-top: 20px; height: 50px;}
.admin_write_post_btn_box > div {float: right; width: 100px; height:50px; font-size: 15px; font-weight: 600; background-color: #000; color: #fff; line-height: 50px; text-align: center; cursor: pointer;}

.admin_write_post_btn_box > .save {background-color: #555; margin-left: 20px;}
.admin_write_post_btn_box > .save > .loader{width: 20px; height: 20px; margin-top: 15px; margin-left: 40px;}
.admin_write_post_btn_box > .save:hover {background-color: #333;}
.admin_write_post_btn_box > .save:active {background-color: #111;}

.admin_write_post_btn_box > .back {background-color: #000;}
.admin_write_post_btn_box > .back > .loader{width: 20px; height: 20px; margin-top: 15px; margin-left: 40px;}
.admin_write_post_btn_box > .back:hover {background-color: #333;}
.admin_write_post_btn_box > .back:active {background-color: #111;}

/** End - 관리자 문의 사항 보기 화면
********************************************************************************************************/


/********************************************************************************************************

    관리자 설정 페이지

********************************************************************************************************/

.admin_user_info_box { margin-top: 30px; max-width: 400px; width: calc(100% - 40px); margin-left: 50%; transform: translate(-50%, 0);}
.admin_user_info_box > .input_box { border: 1px solid #CCCCCC; border-radius: 10px; width: 100%;}
.admin_user_info_box > .input_box > div { height: 50px; width: 100%; border-bottom: 1px solid #CCCCCC;}
.admin_user_info_box > .input_box > div:last-child {border-bottom: 0;}
.admin_user_info_box > .input_box > div:first-child > .left {border-top-left-radius: 10px;}
.admin_user_info_box > .input_box > div:first-child > .right {border-top-right-radius: 10px;}
.admin_user_info_box > .input_box > div:last-child > .left {border-bottom-left-radius: 10px;}
.admin_user_info_box > .input_box > div:last-child > .right {border-bottom-right-radius: 10px;}
.admin_user_info_box > .input_box > div > div {float: left; height: 100%; line-height: 50px; font-size: 14px; color: #000;}
.admin_user_info_box > .input_box > div > .left {width: 100px; border-right: 1px solid #CCCCCC; font-weight: 600; background-color: #FAFAFA;}
.admin_user_info_box > .input_box > div > .right {width: calc(100% - 102px); position: relative;}
.admin_user_info_box > .input_box > div > .right > input {width: calc(100% - 30px);  font-size: 14px; height: calc(100% - 4px); background-color: #00000000; border: 0; outline: none;}
.admin_user_info_box > .input_box > div > .right > .icon {position: absolute; right: 10px; top: 7px; height: 24px; width: 24px;}
.admin_user_info_box > .input_box > div > .all {width:100%}
.admin_user_info_box > .input_box > div > .all > input {text-align: center;}
.admin_user_info_box > .input_box > div > .disable{ background-color: #FAFAFA; }
.admin_user_info_box > .input_box > div > .disable > input {color: #999;}
.admin_user_info_box > .edit_btn { width: 100%; margin-left: 50%; transform: translate(-50%, 0); margin-top: 30px; font-size: 15px;}
.admin_user_info_box > .divider { width: 100%; height: 1px; background-color: #ccc; margin-left: 50%; transform: translate(-50%, 0); margin-top: 30px; margin-bottom: 30px; }
.admin_user_info_box > .title { width: 100%; font-size: 15px; font-weight: 600; margin-left:calc(50% + 5px); transform: translate(-50%, 0); text-align: left; margin-bottom: 10px;}

.admin_taboo_list_box { width: 600px;}
.admin_taboo_list_box > ul > li > .name { width: 400px; max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_taboo_list_box > ul > li > .state { width: 100px; }
.admin_taboo_list_box > ul > li > .edit { width: 100px;}
.admin_taboo_list_box > .list_top > .select_read > .select{ width: 70px;}
.admin_taboo_list_box > .list_top > .admin_search_area { width: 202px;}
.admin_taboo_list_box > .list_top > .admin_search_area > .search_input { width: 160px; padding-left: 10px;}
.admin_taboo_list_box > .list_top > .add_btn { float: right; height: 40px; line-height:40px; width: 80px; font-size: 13px;}

.admin_mobile_body_div > .middle_area > .admin_taboo_list_box > ul > li { width: 100%; height: 50px;}
.admin_mobile_body_div > .middle_area > .admin_taboo_list_box > ul > li > div { height: 50px;}
.admin_mobile_body_div > .middle_area > .admin_taboo_list_box > ul > li > .name { width: calc(100% - 160px); max-lines: 1; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; display: block;}
.admin_mobile_body_div > .middle_area > .admin_taboo_list_box > ul > li > .state { width: 80px; }
.admin_mobile_body_div > .middle_area > .admin_taboo_list_box > ul > li > .edit { width: 80px;}


.admin_system_box { max-width: 440px; width: calc(100% - 40px); margin-left: 50%; transform: translate(-50%, 0);}
.admin_system_box > .password_box { margin-top: 30px; height: 50px; border: 1px solid #CCCCCC; border-radius: 10px; width: 100%; }
.admin_system_box > .password_box > input {text-align: center;width: calc(100% - 30px);  font-size: 14px; height: calc(100% - 4px); background-color: #00000000; border: 0; outline: none;}
.admin_system_box > .edit_btn { width: 100%; margin-left: 50%; transform: translate(-50%, 0); margin-top: 30px; font-size: 15px;}
.admin_system_box > .title { width: 100%;  margin-top: 30px; font-size: 15px; font-weight: 600; margin-left:calc(50% + 5px); transform: translate(-50%, 0); text-align: left; margin-bottom: 15px;}
.admin_system_box > .system_box{ border: 1px solid #CCCCCC; border-radius: 10px; width: 100%; padding-bottom: 15px;}
.admin_system_box > .system_box > .item {width: calc(100% - 40px); margin-left: 20px; margin-top: 20px;}
.admin_system_box > .system_box > .item > div {width: 100%; height: 50px;}
.admin_system_box > .system_box > .item > div > div { float: left;}
.admin_system_box > .system_box > .item > div > .input_div { height: 50px; width: calc(100% - 60px); border: 1px solid #CCCCCC; border-radius: 10px; }
.admin_system_box > .system_box > .item > div > .input_div > .title { float: left; font-size: 14px; height: 48px;white-space:pre-line; width: 120px; font-weight: 600; border-right: 1px solid #CCCCCC;line-height: 18px; padding-top: 5px; background-color: #FAFAFA; border-top-left-radius: 10px; border-bottom-left-radius: 10px;}
.admin_system_box > .system_box > .item > div > .input_div > input {float: left; height: 42px; width: calc(100% - 128px); border: 0px; text-align: center; outline: none;}
.admin_system_box > .system_box > .item > div > .button_div { height: 50px; width: 50px; margin-left: 10px; font-size: 13px; border-radius: 10px; }
.admin_system_box > .system_box > .item > .guide {text-align: left; font-size: 13px; height: fit-content; margin-top: 5px; padding-left: 10px;}

.admin_system_box > .grade_box {}
.admin_system_box > .grade_box {width: 100%; border-bottom: 1px solid #555;}
.admin_system_box > .grade_box > li{height: 70px; line-height: 70px;}
.admin_system_box > .grade_box > li:last-child {border-bottom: 0;}
.admin_system_box > .grade_box > li > div {float: left;}
.admin_system_box > .grade_box > li > .level { width: 40px;}
.admin_system_box > .grade_box > li > .best { width: 70px;}
.admin_system_box > .grade_box > li > .post { width: 70px;}
.admin_system_box > .grade_box > li > .reply { width: 70px;}
.admin_system_box > .grade_box > li > .login { width: 120px;}
.admin_system_box > .grade_box > li > .change { width: 70px;}
.admin_system_box > .grade_box > .title{border-top: 1px solid #555; border-bottom: 1px solid #555; font-weight: 600; font-size: 15px;}
.admin_system_box > .grade_box > .item{border-bottom: 1px solid #ccc; font-size: 15px;}
.admin_system_box > .grade_box > .item > .level { font-weight: 600;}
.admin_system_box > .grade_box > .item > div > input {height: 50px; border: 1px solid #CCCCCC; border-radius: 10px; width: calc(100% - 20px); outline: none; text-align: center;}
.admin_system_box > .grade_box > .item > .change > .button_div { width: 60px; height: 50px; margin-top: 10px; margin-left: 5px; font-size: 13px; border-radius: 10px;}


.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > li > .level { width: 30px;}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > li > .best { width: 60px;}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > li > .post { width: 60px;}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > li > .reply { width: 60px;}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > li > .login { width: calc(100% - 30px - 240px )}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > li > .change { width: 60px;}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > .title{font-size: 13px;}
.admin_mobile_body_div > .middle_area > .admin_system_box > .grade_box > .item > .change > .button_div { width: 50px;}

/** End - 관리자 설정 페이지
********************************************************************************************************/

/********************************************************************************************************
    관리자 카테고리 팝업
********************************************************************************************************/
.popup_body > .popup_category { padding-top: 10px;}
.popup_body > .popup_category > input { background-color: #fff; border: 1px solid #999; width: calc(100% - 40px); font-size: 15px; color: #000; margin-left: 20px; margin-top: 10px; height: 40px; border-radius: 5px; text-align: center; outline: none;}
.popup_body > .popup_category > .banner { width: calc(100% - 40px); font-size: 15px; color: #000; margin-left: 20px; margin-top: 10px; }
.popup_body > .popup_category > .banner > .image {width: 100%; position: relative;}
.popup_body > .popup_category > .banner > .image > img{width: 100%;}
.popup_body > .popup_category > .banner > .banner_top { width: 100%; position: relative; height: 30px;}
.popup_body > .popup_category > .banner > .banner_top > .title{ position: absolute; top:0; background-color: #000000; color: #fff; padding: 5px; font-size: 13px;}
.popup_body > .popup_category > .banner > .banner_top > .close{position: absolute; right: 2px; top: 2px; padding: 0px; background-color: #fff; border-radius: 20px; cursor: pointer;}
.popup_body > .popup_category > .banner > .button { width: 100%; background-color: #555; height: 40px; line-height: 40px; font-size: 13px; text-align: center; color: #fff; font-weight: 600; cursor: pointer; }
.popup_body > .popup_category > .banner > .button:hover{ background-color: #444;}
.popup_body > .popup_category > .banner > .button:active{ background-color: #111;}
/** End - 관리자 카테고리 팝업
********************************************************************************************************/



/********************************************************************************************************
    관리자 메인 페이지
********************************************************************************************************/
.admin_main > .visitant { width: 100%; max-width: 500px; margin-left: 50%; transform: translate(-50%, 0); margin-top: 20px;}
.admin_main > .visitant > .top { height: 40px; line-height: 40px;}
.admin_main > .visitant > .top > .title{ float: left; font-family: "DefaultFont"; font-size: 15px; font-weight: 600;}
.admin_main > .visitant > .top > .tab_item{ float: right; padding-left: 5px; color: #aaa; font-size: 14px; padding-right: 5px; cursor: pointer;}
.admin_main > .visitant > .top > .tab_item:hover{font-weight: 600;}
.admin_main > .visitant > .top > .tab_item_select {color: #f00; font-weight: 600;}
.admin_main > .visitant > .graph { width: 100%; height: 200px; background-color: #fafafa; border: 1px solid #eee; position: relative;}
.admin_main > .visitant > .graph > div {width: 100%;}
.admin_main > .visitant > .graph > div > div {float: left;}
.admin_main > .visitant > .graph > .graph_value_box_bg { height: 180px; position: absolute; }
.admin_main > .visitant > .graph > .graph_value_box_bg > div { width: 100%; }
.admin_main > .visitant > .graph > .graph_value_box_bg > div > div { width: 100%; height: 1px; background-color: #eee; margin-top: 20px;}
.admin_main > .visitant > .graph > .graph_value_box_bg > .button {position:absolute; width:30px; height:200px; cursor:pointer}
.admin_main > .visitant > .graph > .graph_value_box_bg > .button:hover{background-color: #fafafa;}
.admin_main > .visitant > .graph > .graph_value_box_bg > .button > svg {width:20px; height:20px; margin: 5px; margin-top: 90px; fill:#000;}
.admin_main > .visitant > .graph > .graph_value_box_bg > .button > svg.disable {fill:#ccc;}
.admin_main > .visitant > .graph > .graph_value_box_bg > .button.left {left:-30px}
.admin_main > .visitant > .graph > .graph_value_box_bg > .button.right {right:-30px;}

.admin_main > .visitant > .graph > .graph_value_box { height: 180px; position: absolute;  border-bottom: 1px solid #aaa;}
.admin_main > .visitant > .graph > .graph_value_box > div > div { text-align: center;}
.admin_main > .visitant > .graph > .graph_value_box > div > div > .dot{ width: 5px; height: 5px; border-radius: 10px; background-color: #f00; margin-left: 50%; transform: translate(-50%, 0);}
.admin_main > .visitant > .graph > .graph_value_box > div > div > .value { height: 15px; color: #000; margin-bottom: 5px; font-size: 10px; font-weight: 600;}
.admin_main > .visitant > .graph > .graph_label_box { height: 20px; margin-top: 180px;}
.admin_main > .visitant > .graph > .graph_label_box > div {font-size: 12px; line-height: 20px;}
/** End - 관리자 메인 페이지
********************************************************************************************************/